import React, { useState } from 'react';
import HistoryFilter from './Filter';
import { Button, FunnelOutlined, Tag } from 'tera-dls';
import TableTera from '_common/dof/TableTera';

const HistoryDetail = () => {
  const [params, setParams] = useState<any>();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const columnsB = [
    {
      title: 'Email',
      dataIndex: 'email',
      width: 200,
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'description',
      width: 200,
      render: (val) => <span className="text-blue-500">{val}</span>,
    },
    {
      title: 'Ngày gửi',
      dataIndex: 'status',
      width: 200,
      render: (val) =>
        val === 'success' ? (
          <Tag color="green03">Thành công</Tag>
        ) : (
          <Tag color="gray03">Thất bại</Tag>
        ),
    },
  ];
  return (
    <>
      <div className="flex flex-col gap-[16px]">
        <p className="text-[13px]">
          Lịch sử tất cả email mà chúng tôi đã gửi cho bạn.
        </p>
        <div className="flex justify-end flex-col bg-white shadow rounded-md py-2 gap-2">
          <div className="flex justify-end pr-2">
            <Button
              type="alternative"
              className="rounded-xsm py-1 px-1 bg-blue-50"
              icon={
                <FunnelOutlined className=" text-blue-500 rounded-[4px] shrink-0" />
              }
              onClick={() => setOpenFilter(true)}
            />
          </div>
          <TableTera
            columns={columnsB}
            className="center-table"
            wrapperClassName="shadow-none"
            pagination={{}}
            data={[
              {
                email: '@email',
                description: 'Client has logged in (116.110.40.255)',
                status: 'success',
              },
            ]}
          />
        </div>
      </div>
      {openFilter && (
        <HistoryFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          initialValues={{ time: params?.time }}
          onFilter={(val) => setParams((prev = {}) => ({ ...prev, ...val }))}
        />
      )}
    </>
  );
};

export default HistoryDetail;
