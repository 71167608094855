import { IRouteProps } from '_common/interface/router';
import ListPage from '.';
import { SERVICE_PACKAGE_COUNTRY_URL } from './url';

export const ServicePackageCountryRouter: IRouteProps[] = [
  {
    key: SERVICE_PACKAGE_COUNTRY_URL.list.key,
    path: SERVICE_PACKAGE_COUNTRY_URL.list.shortenUrl,
    component: <ListPage />,
  },
];
