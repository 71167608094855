export const CASH_RECEIPT_URL = {
  list: {
    key: '',
    path: '/invoice/receipt/list',
    shortenUrl: 'receipt/list',
  },
  create: {
    key: '',
    path: '/invoice/receipt/create',
    shortenUrl: 'receipt/create',
  },
  update: {
    key: '',
    path: '/invoice/receipt/update',
    shortenUrl: 'receipt/update/:id',
  },
};
