import { IRouteProps } from '_common/interface/router';
import ListPage from '.';
import CreateUpdatePage from './pages/CreateUpdate';
import DetailPage from './pages/Detail';
import { SERVICE_HOSTING_URL } from './url';

export const ServiceHostingRouter: IRouteProps[] = [
  {
    key: SERVICE_HOSTING_URL.list.key,
    path: SERVICE_HOSTING_URL.list.shortenUrl,
    component: <ListPage />,
  },
  {
    key: SERVICE_HOSTING_URL.create.key,
    path: SERVICE_HOSTING_URL.create.shortenUrl,
    component: <CreateUpdatePage />,
  },
  {
    key: SERVICE_HOSTING_URL.detail.key,
    path: SERVICE_HOSTING_URL.detail.shortenUrl,
    component: <DetailPage />,
  },
];
