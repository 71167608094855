import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  PaginationProps,
  PlusOutlined,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import LocationAPI from './apis';
import Search from './components/Search';
import Table from './components/Table';
import ModalCreateUpdate from './modals/ModalCreateUpdate';
import { SERVICE_PACKAGE_LOCATION_URL } from './url';

const ListPage = (): JSX.Element => {
  const [isOpenModalCreateUpdate, setIsOpenModalCreateUpdate] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateFilter = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${SERVICE_PACKAGE_LOCATION_URL.list.path}${paramString}`);
  };

  const handleSearch = (values: any) => {
    handleUpdateFilter({ keyword: values?.keyword, page: 1 });
  };

  const {
    data: dataList,
    refetch: refetchDataList,
    isLoading: isLoadingDataList,
  } = useQuery(
    ['get-service-package-location-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return LocationAPI.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateFilter({ page: data?.last_page });
        }
      },
    },
  );

  useEffect(() => {
    refetchDataList();
  }, []);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(dataList?.limit || 10);
    handleUpdateFilter({ page: isDiffPageSize ? 1 : page, limit: pageSize });
  };

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách Vị trí"
        actionRight={
          <div className="flex gap-2">
            <Button
              prefix={<PlusOutlined />}
              className="bg-blue-500"
              onClick={() => setIsOpenModalCreateUpdate(true)}
            >
              Thêm mới
            </Button>
          </div>
        }
        actionLeftRender={<Search onSearch={handleSearch} />}
      >
        <Table
          rowKey={'id'}
          data={dataList?.data || []}
          loading={isLoadingDataList}
          pagination={{
            onChange: handleChangePage,
            total: dataList?.total || 0,
            current: dataList?.current_page,
            pageSize: dataList?.per_page || 10,
            to: dataList?.to,
            from: dataList?.from || 10,
          }}
        />
      </HeaderViewList>
      {isOpenModalCreateUpdate && (
        <ModalCreateUpdate
          open={isOpenModalCreateUpdate}
          close={() => setIsOpenModalCreateUpdate(false)}
        />
      )}
    </div>
  );
};

export default ListPage;
