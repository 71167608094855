import HorizontalBarChart from '_common/component/Chart/BarChart/HorizontalBarChart';
import { useState } from 'react';
import {
  ArrowsPointingInOutlined,
  ArrowsPointingOuOutlined,
  ChartBarProps,
  Spin,
  Tooltip,
} from 'tera-dls';
import ModalChart from './Modal';

export type SaleOfCustomerTypeChartType = 'small' | 'large';
interface IProps {
  type?: SaleOfCustomerTypeChartType;
  onClose?: () => void;
  chartOptions?: ChartBarProps['options'];
  chartClassName?: string;
  defaultLengthShowTick?: number;
  labelMaxLength?: number;
  defaultLengthScroll?: number;
  params?: any;
  value?: any;
  loading?: boolean;
}

const Content = (props: IProps) => {
  const {
    type = 'small',
    chartClassName,
    params,
    onClose,
    value,
    loading,
  } = props;
  const [openModal, setOpenModal] = useState<boolean>();
  const isSmallType = type === 'small';

  return (
    <>
      <div
        className={`col-span-2 xl:col-span-1 rounded-[5px] p-[15px] border-[1px] bg-white flex flex-col h-full gap-5 overflow-auto ${
          isSmallType ? '!h-[500px]' : '!h-full'
        }`}
      >
        <div className="flex flex-col gap-[5px] shrink-0">
          <div className="flex items-center">
            <div className="uppercase font-medium flex-1">
              Thống kê theo dịch vụ
            </div>
            <div className="flex gap-2.5">
              {isSmallType ? (
                <Tooltip title="Mở rộng">
                  <span>
                    <ArrowsPointingOuOutlined
                      className="size-4 cursor-pointer"
                      onClick={() => setOpenModal(true)}
                    />
                  </span>
                </Tooltip>
              ) : (
                <Tooltip title="Thu gọn">
                  <span>
                    <ArrowsPointingInOutlined
                      className="size-4 cursor-pointer"
                      onClick={() => onClose()}
                    />
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <Spin spinning={loading}>
          <HorizontalBarChart
            type={type}
            data={value}
            chartClassName={chartClassName}
            maxY={3000}
          />
        </Spin>
      </div>
      {openModal && (
        <ModalChart
          params={params}
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
          value={value}
          loading={loading}
        />
      )}
    </>
  );
};

export default Content;
