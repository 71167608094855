import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import { IOpenModalState } from '_common/interface';
import PaymentMethodAPI from 'pages/Finance/PaymentManagement/PaymentMethod/apis';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Col,
  PaginationProps,
  PlusOutlined,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import Filter from './Filter';
import ModalCreateUpdate from './ModalCreateUpdate';
import Search from './Search';
import Table from './Table';

interface IBankCardProps {
  dataDetail: any;
}

const BankTable = ({ dataDetail }: IBankCardProps): JSX.Element => {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const [isOpemModalCreateUpdate, setIsOpenModalCreateUpdate] =
    useState<IOpenModalState>({
      isOpen: false,
    });

  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateFilter = (values: any) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...values,
    });
    navigate(`${location.pathname}${paramString}`);
  };

  const handleSearch = (values: any) => {
    handleUpdateFilter({ keyword: values?.keyword, page: 1 });
  };

  const handleFilter = (values: any) => {
    handleUpdateFilter({ ...values, page: 1 });
  };

  const {
    data: dataList,
    refetch: refetchDataList,
    isLoading: isLoadingDataList,
  } = useQuery(
    ['get-user-account-payment-method-list', queryParams],
    () => {
      return PaymentMethodAPI.getList({
        params: {
          ...queryParams,
          account_id: dataDetail?.id,
          page: queryParams?.page || 1,
          limit: queryParams?.limit || 10,
        },
      });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateFilter({ page: data?.last_page });
        }
      },
    },
  );

  useEffect(() => {
    refetchDataList();
  }, []);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(dataList?.limit || 10);
    handleUpdateFilter({ page: isDiffPageSize ? 1 : page, limit: pageSize });
  };

  return (
    <Col className="flex justify-end flex-col shadow rounded-md">
      <HeaderViewList
        actionRight={
          <Button
            prefix={<PlusOutlined />}
            className="bg-blue-500"
            onClick={() => setIsOpenModalCreateUpdate({ isOpen: true })}
          >
            Thêm mới
          </Button>
        }
        onClickFilter={() => setIsOpenFilter(true)}
        actionLeftRender={<Search onSearch={handleSearch} />}
        filterCount={{
          filter: queryParams,
          params: ['payment_method_type', 'payment_method_bank'],
        }}
      >
        <Table
          rowKey={'id'}
          data={dataList?.data || []}
          loading={isLoadingDataList}
          pagination={{
            onChange: handleChangePage,
            total: dataList?.total || 0,
            current: dataList?.current_page,
            pageSize: dataList?.per_page || 10,
            to: dataList?.to,
            from: dataList?.from || 10,
          }}
        />
      </HeaderViewList>
      {isOpenFilter && (
        <Filter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
      {isOpemModalCreateUpdate.isOpen && (
        <ModalCreateUpdate
          open={isOpemModalCreateUpdate.isOpen}
          close={() => setIsOpenModalCreateUpdate({ isOpen: false })}
          dataDetail={dataDetail}
        />
      )}
    </Col>
  );
};

export default BankTable;
