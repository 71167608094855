export const TABS_STATUS_ITEM = ['all', 'true', 'false'];
export const TABS_STATUS_TEXT = {
  all: 'Tất cả',
  true: 'Đang hoạt động',
  false: 'Đã hủy',
};
export const TABLE_STATUS_COLOR = {
  true: 'green03',
  false: 'red03',
};
export const TABLE_STATUS_TEXT = {
  true: 'Đang hoạt động',
  false: 'Đã hủy',
};
