export const FINANCE_TRANSACTION_HISTORY_URL = {
  list: {
    key: '',
    path: '/finance/transaction-history/list',
    shortenUrl: 'transaction-history/list',
  },
  detail: {
    key: '',
    path: '/finance/transaction-history/detail',
    shortenUrl: 'transaction-history/detail/:id',
  },
};
