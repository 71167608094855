import TeraFilter from '_common/component/Filter';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { TABLE_STATUS_TEXT } from '../contants';

interface FilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function Filter({ open, onClose, onFilter, initialValue }: FilterProps) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      status: null,
    },
  });

  const handleSubmitForm = (values: any) => {
    onFilter(values);
    onClose();
  };

  const handleReset = () => {
    onFilter({
      status: null,
    });
    onClose();
  };

  useEffect(() => {
    const values = _.pick(initialValue, ['status']);

    form.reset({
      ...values,
    });
  }, [initialValue]);

  const StatusOptions = useMemo(
    () =>
      Object.entries(TABLE_STATUS_TEXT).map(([key, value]) => ({
        value: key,
        label: value,
      })),
    [],
  );

  return (
    <TeraFilter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera
        form={form}
        onSubmit={form.handleSubmit(handleSubmitForm)}
        className="flex flex-col gap-2"
      >
        <FormTeraItem label="Trạng thái" name="active" className="mb-0">
          <Select options={StatusOptions} />
        </FormTeraItem>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </TeraFilter>
  );
}

export default Filter;
