import TeraFilter from '_common/component/Filter';
import {
  PAYMENT_METHOD_TEXT,
  PAYMENT_METHOD_TYPE,
} from '_common/constants/invoiceManagement';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectBank from '_common/dof/Select/SelectBank';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

interface FilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function Filter({ open, onClose, onFilter, initialValue }: FilterProps) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      payment_method_type: null,
      payment_method_bank: null,
    },
  });

  const handleSubmitForm = (values: any) => {
    onFilter(values);
    onClose();
  };

  const handleReset = () => {
    onFilter({
      payment_method_type: null,
      payment_method_bank: null,
    });
    onClose();
  };

  useEffect(() => {
    const values = _.pick(initialValue, [
      'payment_method_type',
      'payment_method_bank',
    ]);

    form.reset(values);
  }, [initialValue]);

  const paymentMethodTypeOptions = useMemo(() => {
    delete PAYMENT_METHOD_TEXT[PAYMENT_METHOD_TYPE.WALLET];

    return Object.entries(PAYMENT_METHOD_TEXT).map(([key, value]) => ({
      label: value,
      value: key,
    }));
  }, []);

  return (
    <TeraFilter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera
        form={form}
        onSubmit={form.handleSubmit(handleSubmitForm)}
        className="flex flex-col gap-2"
      >
        <FormTeraItem label="Phương thức thanh toán" name="payment_method_type">
          <Select options={paymentMethodTypeOptions} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Ngân hàng/Ví điện tử" name="payment_method_bank">
          <SelectBank
            paramsApi={{
              type: form.watch('payment_method_type'),
              include_id: form.watch('payment_method_bank'),
            }}
            disabled={_.isNil(form.watch('payment_method_type'))}
          />
        </FormTeraItem>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </TeraFilter>
  );
}

export default Filter;
