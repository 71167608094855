import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Tabs, getQueryParams, updateQueryParams } from 'tera-dls';
import Estimate from './Estimate';
import InvoiceTable from './InvoiceTable';

interface IInvoiceProps {
  dataDetail: any;
}

const Invoice = ({ dataDetail }: IInvoiceProps): JSX.Element => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data: any) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleChangeTab = (key: string) => {
    handleUpdateQueryParams({ subTab: key });
  };

  const tabItems = [
    {
      key: 'invoice',
      label: 'Hóa đơn',
    },
    {
      key: 'estimates',
      label: 'Estimates',
    },
  ];

  const tabData = {
    ['invoice']: <InvoiceTable dataDetail={dataDetail} />,
    ['estimates']: <Estimate />,
  };

  return (
    <Col className="flex flex-col gap-4">
      <Tabs
        onChange={handleChangeTab}
        items={tabItems}
        activeKey={queryParams?.subTab || tabItems[0].key}
        className="mb-0 pt-0 bg-white rounded-t-md"
      />
      {tabData[queryParams?.subTab || tabItems[0].key]}
    </Col>
  );
};

export default Invoice;
