import { useMutation, useQueryClient } from '@tanstack/react-query';
import CardDetail from '_common/component/CardDetail';
import Text from '_common/component/Text';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import FormTera from '_common/dof/FormTera';
import _ from 'lodash';
import moment from 'moment';
import UserAccountAPI from 'pages/UserManagement/Account/apis';
import { useForm } from 'react-hook-form';
import { Button, Col, Spin, notification } from 'tera-dls';
import FormCreateUpdate from './FormCreateUpdate';

interface IAccountInformation {
  dataDetail: any;
}

const AccountInformation = ({ dataDetail }: IAccountInformation) => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      username: dataDetail?.user?.username ?? '',
      email: dataDetail?.email ?? '',
      country: dataDetail?.country ?? '',
      country_code: '+84',
      last_middle_name: dataDetail?.last_middle_name ?? '',
      birthday: dataDetail?.birthday ? moment(dataDetail?.birthday) : null,
      province: dataDetail?.province ?? '',
      first_name: dataDetail?.first_name ?? '',
      phone: dataDetail?.phone ?? '',
      district: dataDetail?.district ?? '',
      gender: dataDetail?.gender ?? '',
      address: dataDetail?.address ?? '',
      ward: dataDetail?.ward ?? '',
      citizen_id: dataDetail?.citizen_id ?? '',
      customer_id: dataDetail?.customer_id ?? '',
      status: dataDetail?.status ?? '',
    },
  });
  const queryClient = useQueryClient();

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    (params) => UserAccountAPI.update({ id: dataDetail?.id, params }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          notification.success({
            message: res?.msg,
          });
          form.reset({});
          queryClient.invalidateQueries(['get-profile']);
          queryClient.invalidateQueries(['get-user-account-detail']);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleSubmitForm = (values: any) => {
    if (isLoadingUpdate) return;

    const data = {
      ...values,
      birthday: values?.birthday
        ? moment(values?.birthday).format(DATE_BACKEND_FORMAT)
        : null,
    };

    mutateUpdate(data);
  };

  return (
    <Spin spinning={isLoadingUpdate}>
      <Col className="w-full flex flex-col items-start gap-4">
        <CardDetail className="w-full flex flex-col gap-4">
          <Text className="text-gray-500">
            Vui lòng cập nhật đúng thông tin của bạn, để tránh các vấn đề lỗi
            thông tin khi đăng ký tên miền.
          </Text>
          <FormTera
            form={form}
            onSubmit={handleSubmitForm}
            className="flex-1 flex flex-col gap-4"
          >
            <FormCreateUpdate isUpdate />
          </FormTera>
        </CardDetail>
        <Button
          onClick={form.handleSubmit(handleSubmitForm)}
          className="font-light text-[12px] bg-blue-500"
        >
          Lưu
        </Button>
      </Col>
    </Spin>
  );
};

export default AccountInformation;
