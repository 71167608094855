import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const TransactionAPIEndpoint = `${endpoint}/admin/finance/transaction`;

const TransactionAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${TransactionAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${TransactionAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  approval: async ({ id }) =>
    await api
      .put(`${TransactionAPIEndpoint}/approval/${id}`)
      .then((res) => res.data),
  failure: async ({ id }) =>
    await api
      .put(`${TransactionAPIEndpoint}/failure/${id}`)
      .then((res) => res.data),
  error: async ({ id }) =>
    await api
      .put(`${TransactionAPIEndpoint}/error/${id}`)
      .then((res) => res.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${TransactionAPIEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
};

export default TransactionAPI;
