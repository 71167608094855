import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import CountryAPI from 'pages/ServicePackage/Country/apis';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import customTwMerge from 'tailwind-merge.config';
import { Modal, Spin, getQueryParams, updateQueryParams } from 'tera-dls';
import { SERVICE_CLOUD_VPS_URL } from '../url';

interface IModalDomainProcessesDetailProps {
  open: boolean;
  close: () => void;
}

const ModalAddCloudVPS = ({
  open,
  close,
}: IModalDomainProcessesDetailProps): JSX.Element => {
  const [activeArea, setActiveArea] = useState<any>(null);
  const [activeCountry, setActiveCountry] = useState<any>(null);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const {
    data: dataAreaList,
    refetch: refetchAreaList,
    isLoading: isLoadingArea,
  } = useQuery(
    ['get-service-package-area-list'],
    () => {
      return CountryAPI.getAreaList({ params: {} });
    },
    {
      staleTime: 300000,
      cacheTime: 300000,
      onSuccess: (data) => setActiveArea(data[0]),
    },
  );

  useEffect(() => {
    refetchAreaList();
  }, []);

  const {
    data: dataCountryList,
    refetch: refetchCountryList,
    isLoading: isLoadingCountry,
  } = useQuery(
    ['get-service-package-country-list', activeArea],
    () => {
      const params = {
        areaCode: activeArea?.code,
        active: 1,
        limit: 1000,
      };
      return CountryAPI.getList({ params });
    },
    {
      enabled: !_.isNil(activeArea),
      staleTime: 300000,
      cacheTime: 300000,
      onSuccess: (data) => setActiveCountry(data.data[0]),
    },
  );

  useEffect(() => {
    if (activeArea) refetchCountryList();
  }, [activeArea]);

  const handleSubmit = () => {
    const paramString = updateQueryParams({
      ...queryParams,
      area: activeArea.code,
      country: activeCountry.countryCode,
    });
    close();
    navigate(`${SERVICE_CLOUD_VPS_URL.create.path}${paramString}`);
  };

  return (
    <Modal
      title="Thêm VPS"
      open={open}
      centered={true}
      okText="Đồng ý"
      cancelText="Hủy"
      onOk={handleSubmit}
      onCancel={close}
      className="sm:w-[90%] xl:w-[80%] h-[90%]"
      contentClassName="h-full"
      bodyClassName="!p-0 flex-1"
    >
      <div className="h-full flex">
        <div className="w-[330px] bg-[#F3F4F6]">
          <Spin spinning={isLoadingArea}>
            <div className="flex flex-col">
              {dataAreaList?.map((area: any) => (
                <div
                  key={area.code}
                  className={customTwMerge(
                    'h-[63px] bg-[#F3F4F6] hover:bg-white flex items-center cursor-pointer',
                    _.isEqual(activeArea?.code, area.code) &&
                      'bg-white before:w-[4px] before:h-full before:bg-[#3F83F8] before:block',
                  )}
                  onClick={() => setActiveArea(area)}
                >
                  <div className="w-full p-4 flex flex-col">
                    <span className="font-bold">{area.name}</span>
                    <span className="pl-2">{area.description}</span>
                  </div>
                </div>
              ))}
            </div>
          </Spin>
        </div>
        <div className="p-4 flex-1">
          <Spin spinning={isLoadingCountry}>
            <div className="text-[16px] font-bold pb-4 border-b-[1px] flex gap-1">
              <span>KHỞI TẠO KHU VỰC</span>
              <span className="text-[#0e9f6e]">{activeArea?.name}</span>
            </div>
            <div className="grid grid-cols-4 gap-4 py-2">
              {dataCountryList?.data?.map((country: any) => (
                <div
                  key={country?.countryCode}
                  className={customTwMerge(
                    'p-1 flex flex-col gap-1 cursor-pointer',
                    _.isEqual(
                      activeCountry?.countryCode,
                      country.countryCode,
                    ) && 'bg-blue-100',
                  )}
                  onClick={() => setActiveCountry(country)}
                >
                  <div className="flex items-center gap-1">
                    <img
                      src={country.avatar_url}
                      className="w-[30px] h-[24px]"
                    />
                    <span className="text-[16px] text-blue-600">
                      {country.countryName}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </Spin>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddCloudVPS;
