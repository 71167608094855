export enum SERVICE_PACKAGE_STATUS {
  DEACTIVATE,
  ACTIVE,
}
export const TABS_STATUS_ITEM = ['all', '1', '0'];
export const TABS_STATUS_TEXT = {
  all: 'Tất cả',
  0: 'Đã hủy',
  1: 'Đang hoạt động',
};
export const TABLE_STATUS_COLOR = {
  0: 'red03',
  1: 'green03',
};
export const TABLE_STATUS_TEXT = {
  0: 'Đã hủy',
  1: 'Đang hoạt động',
};
