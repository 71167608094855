import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const ServiceSubtypeAPIEndpoint = `${endpoint}/portal/service/subtype`;

const ServiceSubtypeAPI = {
  getSubtypeList: async ({ params }) =>
    await api
      .get(`${ServiceSubtypeAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
};

export default ServiceSubtypeAPI;
