const PasswordIcon = (props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.1925 10.895H18.7783C17.8745 10.895 17.3724 9.69002 18.0753 9.08751L20.4854 6.67744C21.1883 5.97451 21.1883 4.86991 20.4854 4.16698C19.7824 3.46406 18.6778 3.46406 17.9749 4.16698L15.5648 6.57705C14.8619 7.17956 13.7574 6.7779 13.7574 5.77371V2.35947C13.7574 1.4557 12.954 0.652344 11.9498 0.652344C11.046 0.652344 10.2427 1.4557 10.2427 2.35947V5.77371C10.2427 6.7779 9.03767 7.17956 8.43516 6.57705L6.0251 4.16698C5.32217 3.46406 4.21757 3.46406 3.51464 4.16698C2.81171 4.86991 2.81171 5.97451 3.51464 6.67744L5.9247 9.08751C6.52721 9.69002 6.12561 10.895 5.12143 10.895H1.80758C0.803395 10.895 0 11.598 0 12.6021C0 13.6063 0.803395 14.4097 1.80758 14.4097H5.12143C6.12561 14.4097 6.52721 15.5143 5.9247 16.2172L3.51464 18.6272C3.21339 18.9285 3.01255 19.3302 3.01255 19.8323C3.01255 20.3344 3.21339 20.736 3.51464 21.1377C3.8159 21.439 4.31806 21.6398 4.71973 21.6398C5.22183 21.6398 5.72384 21.439 6.0251 21.1377L8.43516 18.7276C9.03767 18.0247 10.2427 18.5268 10.2427 19.4306V22.8448C10.2427 23.849 11.046 24.6523 11.9498 24.6523C12.954 24.6523 13.7574 23.849 13.7574 22.8448V19.4306C13.7574 18.5268 14.8619 18.0247 15.5648 18.7276L17.9749 21.1377C18.2762 21.439 18.7783 21.6398 19.18 21.6398C19.6821 21.6398 20.0837 21.439 20.4854 21.1377C20.7866 20.736 20.9874 20.3344 20.9874 19.8323C20.9874 19.3302 20.7866 18.9285 20.4854 18.6272L18.0753 16.2172C17.3724 15.5143 17.8745 14.4097 18.7783 14.4097H22.1925C23.1967 14.4097 24 13.6063 24 12.6021C24 11.598 23.1967 10.895 22.1925 10.895Z"
      fill="#D1D5DB"
    />
  </svg>
);
export default PasswordIcon;
