import {
  ArrowSmallLeftSolid,
  ArrowTrendingDownOutlined,
  ArrowTrendingUpOutlined,
  Breadcrumb,
  Col,
  CurrencyDollarOutlined,
  Row,
  UserGroupOutlined,
  formatCurrency,
  formatNumber,
} from 'tera-dls';
import GridBox from '_common/component/GridBox';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { formatDate } from '_common/utils';
import { useNavigate } from 'react-router-dom';
import { mockData } from '../../../ConfigAffiliates/constant';
import { CONFIG_AFFILIATES_URL } from '../../../ConfigAffiliates/url';
import LineChartCustom from '../../../ConfigAffiliates/containers/LineChartCustom';

const DetailPage = () => {
  const navigate = useNavigate();

  const columns: any = [
    {
      title: 'Mã giao dịch',
      dataIndex: 'transaction_code',
      render: (data) => (
        <span className="text-blue-600 line-clamp-1">{data}</span>
      ),
      width: 150,
    },
    {
      title: 'Loại giao dịch',
      dataIndex: 'transaction_type',
      width: 150,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      render: (amount) => formatCurrency(amount),
      width: 150,
    },

    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      render: (data) => formatDate(data, DATE_TIME_FORMAT),
      width: 150,
    },
  ];

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header-v2__breadcrumb cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb
              separator={'>'}
              items={[
                {
                  title: (
                    <a
                      onClick={() =>
                        navigate(CONFIG_AFFILIATES_URL.referrer.path)
                      }
                    >
                      <span className="!text-blue-400 hover:!text-blue-600">
                        Danh sách người giới thiệu
                      </span>
                    </a>
                  ),
                },
                {
                  title: 'Chi tiết người giới thiệu',
                },
              ]}
            />
          </div>
          <div className="page-header-v2__function"></div>
        </div>
      </div>
      <div className="page-content-v2 gap-y-2.5">
        <div className="p-2.5 w-full flex flex-col gap-4">
          <Row className="grid-cols-3 h-[350px] max-h-[350px] gap-4">
            <Col className="col-span-1 rounded-[16px] relative over-view-left box-shadow-custom flex items-center bg-right">
              <div className="flex flex-col gap-14 pl-[50px]">
                <h2 className="font-medium text-[24px]">Tổng quan</h2>
                <div className="flex items-center gap-2.5">
                  <div className="flex flex-col gap-5">
                    <div className="w-[50px] h-[50px] rounded bg-[#ddedf8]">
                      <UserGroupOutlined className="w-full h-full p-2.5 text-blue-800" />
                    </div>
                    <div className="w-[50px] h-[50px] rounded bg-[#ddedf8]">
                      <CurrencyDollarOutlined className="w-full h-full p-2.5 text-blue-800" />
                    </div>
                  </div>
                  <div className="flex flex-row gap-10">
                    <div className="flex flex-col gap-8">
                      <div className="text-blue-800 flex justify-center flex-col gap-2">
                        <p className="text-[16px]">Người giới thiệu</p>
                        <div className="flex gap-2.5">
                          <span className="text-[24px] font-bold">478</span>
                          <span className="flex gap-1.5">
                            <ArrowTrendingUpOutlined className="text-green-600 w-[15px] h-[15px]" />
                            10%
                          </span>
                        </div>
                      </div>
                      <div className="text-blue-800 flex justify-center flex-col gap-2">
                        <p className="text-[16px]">Tổng doanh thu (VND)</p>
                        <div className="flex gap-2.5">
                          <span className="text-[24px] font-bold">
                            {formatNumber(298000)}
                          </span>
                          <span className="flex gap-1.5">
                            <ArrowTrendingUpOutlined className="text-green-600 w-[15px] h-[15px]" />
                            10%
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-8">
                      <div className="text-blue-800 flex justify-center flex-col gap-2">
                        <p className="text-[16px]">Tổng</p>
                        <div className="flex gap-2.5">
                          <span className="text-[24px] font-bold">
                            {formatNumber(186000000)}
                          </span>
                          <span className="flex gap-1.5">
                            <ArrowTrendingDownOutlined className="text-red-600 w-[15px] h-[15px]" />
                            10%
                          </span>
                        </div>
                      </div>
                      <div className="text-blue-800 flex justify-center flex-col gap-2">
                        <p className="text-[16px]">Tổng</p>
                        <div className="flex gap-2.5">
                          <span className="text-[24px] font-bold">
                            {formatNumber(186000000)}
                          </span>
                          <span className="flex gap-1.5">
                            <ArrowTrendingUpOutlined className="text-green-600 w-[15px] h-[15px]" />
                            10%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="col-span-2 flex justify-center rounded-[16px] over-view-right box-shadow-custom bg-white">
              <GridBox classNameProp="py-[50px] px-5" />
            </Col>
          </Row>
          <Row className="grid-cols-2 gap-4">
            <Col className="box-shadow-custom bg-white rounded-[16px] max-h-[500px]">
              <LineChartCustom />
            </Col>
            <Col className="box-shadow-custom bg-white rounded-[16px] max-h-[500px]">
              <TableTera rowKey={'id'} columns={columns} data={[]} />
            </Col>
          </Row>
          <Row className="grid-cols-2 gap-4">
            <Col className="box-shadow-custom bg-white rounded-[16px] px-8 py-4 h-fit">
              <h3 className="text-2xl font-medium pb-8">Doanh thu gần đây</h3>
              <ul className="flex flex-col gap-2">
                {mockData?.doanhthu.map((item) => {
                  return (
                    <li className="flex justify-between pb-[50px] border-b-[#E5E7EB] border-b-[1px]">
                      <span className="text-[#3F83F8] font-medium">
                        {item?.time}
                      </span>
                      <span>{formatCurrency(item?.total)}</span>
                    </li>
                  );
                })}
              </ul>
            </Col>
            <Col className="box-shadow-custom bg-white rounded-[16px] px-8 py-4 h-fit">
              <h3 className="text-2xl font-medium pb-8">Top người dùng</h3>
              <ul className="flex flex-col gap-2">
                {mockData?.top.map((item) => {
                  return (
                    <li className="flex justify-between pb-[50px] border-b-[#E5E7EB] border-b-[1px]">
                      <span>{item?.name}</span>
                      <span className="text-[#3F83F8] font-medium">
                        {formatCurrency(item?.total)}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default DetailPage;
