import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const StatisticEndpoint = `${endpoint}/admin/finance/statistic`;

const StatisticApi = {
  getChart: async ({ params }) =>
    await api
      .get(`${StatisticEndpoint}/chart`, params)
      .then((res) => res.data?.data),
  getList: async ({ params }) =>
    await api
      .get(`${StatisticEndpoint}/list`, params)
      .then((res) => res.data?.data),
};

export default StatisticApi;
