import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  Col,
  Spin,
  notification,
} from 'tera-dls';
import AccountKYCAPI from '../../apis';
import { ACCOUNT_KYC_STATUS } from '../../contants';
import { ACCOUNT_KYC_URL } from '../../url';
import DetailContent from './DetailContent';

const DetailPage = (): JSX.Element => {
  const { id } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách KYC
        </span>
      ),
      onClick: () => navigate(ACCOUNT_KYC_URL.list.path),
    },
    {
      title: 'Chi tiết KYC',
    },
  ];

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-account-kyc-detail', id],
    () => AccountKYCAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetchDetail();
  }, []);

  const { mutate: mutateApproval, isLoading: isLoadingApproval } = useMutation(
    (params: any) => AccountKYCAPI.active({ id: params.id }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          queryClient.invalidateQueries(['get-account-kyc-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleApproval = () =>
    confirm.warning({
      title: `Xác nhận duyệt KYC`,
      content: (
        <div className="flex flex-col">
          <p>Bạn có chắc muốn duyệt KYC</p>
          <p>
            <b>{dataDetail?.account.user.full_name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateApproval({ id: dataDetail.id }),
    });

  const { mutate: mutateRefused, isLoading: isLoadingRefused } = useMutation(
    (params: any) => AccountKYCAPI.deactivate({ id: params.id }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          queryClient.invalidateQueries(['get-account-kyc-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleRefused = () =>
    confirm.warning({
      title: `Xác nhận từ chối KYC`,
      content: (
        <div className="flex flex-col">
          <p>Bạn có chắc muốn từ chối KYC</p>
          <p>
            <b>{dataDetail?.account.user.full_name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateRefused({ id: dataDetail.id }),
    });

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (id: any) => AccountKYCAPI.delete({ id }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          notification.success({
            message: res?.msg,
          });
          navigate(ACCOUNT_KYC_URL.list.path);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleDelete = () =>
    confirm.warning({
      title: 'Xác nhận xóa KYC',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa KYC</p>
          <p>
            <b>{dataDetail?.account.user.full_name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateDelete(dataDetail?.id),
    });

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
          <div className="page-header-v2__function">
            {!_.isEqual(dataDetail?.status, ACCOUNT_KYC_STATUS.APPROVAL) && (
              <Button className="bg-blue-500" onClick={handleApproval}>
                Duyệt
              </Button>
            )}
            {!_.isEqual(dataDetail?.status, ACCOUNT_KYC_STATUS.APPROVAL) &&
              !_.isEqual(dataDetail?.status, ACCOUNT_KYC_STATUS.REFUSED) && (
                <Button
                  className="bg-red-500 hover:bg-red-700"
                  onClick={handleRefused}
                >
                  Từ chối
                </Button>
              )}
            <Button
              className="bg-red-500 hover:bg-red-700"
              onClick={handleDelete}
            >
              Xóa
            </Button>
          </div>
        </div>
      </div>
      <Spin
        spinning={
          (id && isLoadingDetail) ||
          isLoadingApproval ||
          isLoadingRefused ||
          isLoadingDelete
        }
      >
        <Col className="px-2.5">
          {isErrorDetail || _.isEmpty(dataDetail) ? (
            <NoData />
          ) : (
            <DetailContent dataDetail={dataDetail} />
          )}
        </Col>
      </Spin>
    </div>
  );
};

export default DetailPage;
