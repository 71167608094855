export const ImportExportType = {
  productItem: 'product_item',
  brandItem: 'brand_item',
  unitItem: 'unit_item',
  categoryItem: 'category_item',
  supplierItem: 'supplier_item',
  customerItem: 'customer_item',
  sellItem: 'sell_item',
  purchaseItem: 'purchase_item',
  employeeItem: 'employee_item',
  emailMarketingItem: 'email_marketing_item',
  priceQuotationItem: 'price_quotation_item',
  consultingTicketItem: 'consulting_ticket_item',
  appointmentItem: 'appointment_item',
  taskItem: 'task_item',
  callItem: 'call_item',
  leadItem: 'lead_item',
  opportunityItem: 'opportunity_item',
  campaignItem: 'campaign_item',
  sellReturnItem: 'sell_return_item',
  purchaseReturnItem: 'purchase_return_item',
  receiptItem: 'receipt_item',
  expenseVoucherItem: 'expense_voucher_item',
  debtCustomer: 'debt_customer',
  debtSupplier: 'debt_supplier',
  orderDebtCustomer: 'order_debt_customer',
  orderDebtSupplier: 'order_debt_supplier',
};
