import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import _ from 'lodash';
import { useEffect } from 'react';
import { formatDate, Modal, Spin, Tag } from 'tera-dls';
import OSAPI from '../apis';
import { TABLE_STATUS_COLOR, TABLE_STATUS_TEXT } from '../contants';

interface IModalModalCreateUpdateProps {
  open: boolean;
  close: () => void;
  id?: string | number;
}

const ModalDetail = ({
  open,
  close,
  id,
}: IModalModalCreateUpdateProps): JSX.Element => {
  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-package-operating-system-detail', id],
    () => OSAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  const details = [
    { label: 'Mã hệ điều hành', value: dataDetail?.osId },
    { label: 'Tên hệ điều hành', value: dataDetail?.osName },
    { label: 'Loại hệ điều hành', value: dataDetail?.osType },
    {
      label: 'Trạng thái',
      value: (
        <Tag
          color={TABLE_STATUS_COLOR[dataDetail?.status]}
          className="font-[500]"
        >
          {TABLE_STATUS_TEXT[dataDetail?.status]}
        </Tag>
      ),
    },
    {
      label: 'Ngày tạo',
      value: formatDate(dataDetail?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Ngày cập nhật',
      value: formatDate(dataDetail?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  return (
    <Modal
      title="Chi tiết hệ điều hành"
      open={open}
      centered={true}
      destroyOnClose
      cancelButtonProps={{ className: 'hidden' }}
      okText="Đóng"
      onOk={close}
      contentClassName="md:w-[500px] h-full"
      bodyClassName="!py-4"
    >
      <Spin spinning={id && isLoadingDetail}>
        {id && isErrorDetail ? (
          <NoData />
        ) : (
          <div className="flex flex-col gap-2">
            {details.map((item, index) => (
              <div className="flex" key={index}>
                <span className="flex-1">{item.label}</span>
                <span className="flex-1">{item.value}</span>
              </div>
            ))}
          </div>
        )}
      </Spin>
    </Modal>
  );
};

export default ModalDetail;
