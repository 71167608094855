import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const AffiliatesAPIEndpoint = `${endpoint}/admin/affiliates`;

const AffiliatesAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${AffiliatesAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${AffiliatesAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${AffiliatesAPIEndpoint}/update/${id}`, params)
      .then((res) => res.data),
};

export default AffiliatesAPI;
