import { useQuery } from '@tanstack/react-query';
import HorizontalBarChart from '_common/component/Chart/BarChart/HorizontalBarChart';
import {
  DATE_BACKEND_FORMAT,
  FORMAT_DATE_BY_VARIABLE,
} from '_common/constants/common';
import moment from 'moment';
import {
  CASH_RECEIPT_TYPE,
  EXPENSE_VOUCHER_TYPE,
} from 'pages/Finance/InvoiceManagement/_common/constants';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ChartBarProps, Spin } from 'tera-dls';
import { StatisticCashExpenseByTimeParamContext } from '..';
import StatisticApi from '../../../api';
import Header from '../../Header';
import ModalChart from './Modal';
import SettingModal from './Setting';

export type OpportunityValueStatisticByStage = 'small' | 'large';

interface IProps {
  type?: OpportunityValueStatisticByStage;
  onClose?: () => void;
  chartOptions?: ChartBarProps['options'];
  chartClassName?: string;
  defaultLengthShowTick?: number;
  defaultLengthScroll?: number;
  labelMaxLength?: number;
}

const Content = (props: IProps) => {
  const { type = 'small', onClose, chartClassName = '' } = props;
  const [openModal, setOpenModal] = useState<boolean>();
  const [openSetting, setOpenSetting] = useState<boolean>(false);
  const { contextParams } = useContext(StatisticCashExpenseByTimeParamContext);

  const currentWeek = [
    moment().startOf('week').format(DATE_BACKEND_FORMAT),
    moment().endOf('week').format(DATE_BACKEND_FORMAT),
  ];

  const [params, setParams] = useState<any>({
    pay_method_type: ['cash', 'transfer'],
    receipt_type: Object.keys(CASH_RECEIPT_TYPE),
    expense_type: Object.keys(EXPENSE_VOUCHER_TYPE),
    date: currentWeek,
    time: 'currentWeek',
    show_by: 'day',
  });

  const {
    data: response,
    refetch,
    isRefetching,
  } = useQuery(
    ['get-statistic-time', contextParams],
    () =>
      StatisticApi.getChart({
        params: {
          chart_type: 'byDay',
          investor_id: contextParams?.investor_id,
          receipt_transaction_type:
            contextParams?.receipt_transaction_type?.join(','),
          payment_transaction_type:
            contextParams?.payment_transaction_type?.join(','),
          date_from: contextParams?.date[0],
          date_to: contextParams?.date[1],
          payment_method: contextParams?.payment_method?.join(','),
          show_by: contextParams?.show_by,
        },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, [params]);

  const dataSource = useMemo(() => {
    const data = response?.group_by;
    const labels = data?.map((item) => item?.date) || [];
    const dataCash = data?.map((item) => item?.receipt) || [];
    const dataExpense = data?.map((item) => item?.payment) || [];

    return {
      labels: labels?.map((date) => {
        if (date.includes(' - ')) {
          const data = date.split(' - ');
          const dateStart = moment(data[0]).format(
            FORMAT_DATE_BY_VARIABLE[contextParams?.show_by],
          );
          const dateEnd = moment(data[1]).format(
            FORMAT_DATE_BY_VARIABLE[contextParams?.show_by],
          );
          return `${dateStart} - ${dateEnd}`;
        }
        return moment(date).format(
          FORMAT_DATE_BY_VARIABLE[contextParams?.show_by],
        );
      }),

      datasets: [
        {
          label: 'Thu',
          data: dataCash,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: '#3F83F8',
          barThickness: 50,
        },
        {
          label: 'Chi',
          data: dataExpense,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: '#F9C74F',
          barThickness: 50,
        },
      ],
    };
  }, [response, contextParams]);

  const maxValue = useMemo(() => {
    const data = response?.group_by;
    const dataCash = data?.map((item) => item?.receipt) || [];
    const dataExpense = data?.map((item) => item?.payment) || [];
    const max = Math.max(...dataCash, ...dataExpense);
    return Math.round(max * 1.05);
  }, [response]);

  const isSmallType = type === 'small';

  return (
    <>
      <div className="rounded-[5px] border-[1px] bg-white h-full flex flex-col overflow-hidden">
        <Header
          title={'Thu chi theo thời gian'}
          onClickRefresh={() => refetch()}
          onClickConfig={() => setOpenSetting(true)}
          onZoomOut={() => setOpenModal(true)}
          onZoomIn={() => onClose()}
          isSmallType={isSmallType}
        />
        <Spin spinning={isRefetching}>
          <div className="p-[16px] h-full ">
            <HorizontalBarChart
              defaultLabelMaxLength={30}
              type={type}
              data={dataSource}
              chartClassName={chartClassName}
              containerClassName="pl-2 pr-5"
              maxY={maxValue}
            />
          </div>
        </Spin>
      </div>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
      {openSetting && (
        <SettingModal
          open={openSetting}
          onClose={() => setOpenSetting(false)}
          onSubmit={setParams}
          defaultValues={params}
        />
      )}
    </>
  );
};

export default Content;
