import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TextNavigate from '_common/component/TextNavigate';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import { IOpenModalState } from '_common/interface';
import { USER_ACCOUNT_URL } from 'pages/UserManagement/Account/url';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import {
  ColumnsType,
  DropdownItem,
  formatCurrency,
  formatDate,
  formatNumber,
} from 'tera-dls';
import ModalEditPercentage from '../modals/ModalEditPercentage';
import { AFFILIATES_AGENCY_URL } from '../url';

const Table = (props: ITeraTableProps): JSX.Element => {
  const [isOpenModalEditPercentage, setIsOpenModalEditPercentage] =
    useState<IOpenModalState>({
      isOpen: false,
      id: null,
    });
  const navigate = useNavigate();

  const itemActions = (record: any): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () =>
          navigate(`${AFFILIATES_AGENCY_URL.detail.path}/${record.id}`),
      },
      {
        key: '2',
        label: 'Sửa',
        onClick: () =>
          setIsOpenModalEditPercentage({ isOpen: true, id: record.id }),
      },
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 150,
    },
    {
      title: 'Tên người giới thiệu',
      dataIndex: 'username',
      width: 150,
      render: (_: any, record: any) => (
        <HoverQuickView
          avatarUrl={record.account?.user.avatar}
          name={record.account?.user.full_name}
          code={record.account?.id}
          email={record.account?.email}
          phone={record.account?.phone}
        >
          <TextNavigate
            to={`${USER_ACCOUNT_URL.detail.path}/${record.account?.id}`}
          >
            {record.account?.user.full_name}
          </TextNavigate>
        </HoverQuickView>
      ),
    },
    {
      title: 'Tỷ lệ chuyển đổi',
      dataIndex: 'conversion_rate',
      width: 150,
      render: (conversion_rate) => `${formatNumber(conversion_rate)} %`,
    },
    {
      title: 'Tổng tiền hoa hồng',
      dataIndex: 'total_commission',
      width: 150,
      render: (total_commission) => formatCurrency(total_commission),
    },
    {
      title: 'Phần trăm hoa hồng',
      dataIndex: 'commission_percentage',
      width: 150,
      render: (commission_percentage) =>
        `${formatNumber(commission_percentage)} %`,
    },
    {
      title: 'Ngày đăng ký',
      dataIndex: 'created_at',
      width: 150,
      render: (created_at) => formatDate(created_at),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record: any) => {
        return (
          <ActionDropdown dropdownItems={itemActions(record)} trigger="click" />
        );
      },
    },
  ];

  return (
    <Fragment>
      <TableTera
        rowKey="id"
        columns={columns}
        loading={props?.loading}
        className="center-table"
        {...props}
      />
      {isOpenModalEditPercentage.isOpen && (
        <ModalEditPercentage
          open={isOpenModalEditPercentage.isOpen}
          close={() => setIsOpenModalEditPercentage({ isOpen: false })}
          id={isOpenModalEditPercentage.id}
        />
      )}
    </Fragment>
  );
};

export default Table;
