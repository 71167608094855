import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { INVOICE_STATUS } from '_common/constants/finance';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  Spin,
  notification,
} from 'tera-dls';
import InvoiceAPI from '../../apis';
import { INVOICE_URL } from '../../url';
import InvoiceContent from './components/InvoiceContent';

const DetailPage = () => {
  const { id } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(['get-invoice-detail', id], () => InvoiceAPI.getDetail({ id }), {
    enabled: !_.isNil(id),
    staleTime: 300000,
    cacheTime: 300000,
  });

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  const { mutate: mutateApproval, isLoading: isLoadingApproval } = useMutation(
    (params: any) => InvoiceAPI.approval({ id: params?.id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-invoice-detail']);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleConfirmPayment = () =>
    confirm.warning({
      title: 'Xác nhận thanh toán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn thanh toán hóa đơn</p>
          <p>
            <span className="font-bold">{dataDetail.invoice_no}</span> này
            không?
          </p>
        </div>
      ),
      onOk: () => mutateApproval({ id: dataDetail.id }),
    });

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowSmallLeftSolid className="size-6" />
            </div>
            <Breadcrumb
              separator={'/'}
              items={[
                {
                  title: (
                    <a className="cursor-pointer text-blue-400 italic text-sm">
                      Danh sách hóa đơn
                    </a>
                  ),
                  onClick: () => navigate(INVOICE_URL.list.path),
                },
                {
                  title: (
                    <a className="font-medium hover:text-gray-700 cursor-default tracking-[0.2px] text-sm">
                      Chi tiết hóa đơn
                    </a>
                  ),
                },
              ]}
            />
          </div>
          {!_.isEqual(dataDetail?.status, INVOICE_STATUS.PAID) && (
            <div className="page-header-v2__function shrink-0">
              <Button onClick={handleConfirmPayment}>
                Xác nhận thanh toán
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="page-content-v2">
        <Spin spinning={isLoadingDetail || isLoadingApproval}>
          {id && (isErrorDetail || _.isEmpty(dataDetail)) ? (
            <NoData />
          ) : (
            <InvoiceContent dataDetail={dataDetail} />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default DetailPage;
