export const SERVICE_SSL_URL = {
  list: {
    key: '',
    path: '/service/ssl/list',
    shortenUrl: 'ssl/list',
  },
  create: {
    key: '',
    path: '/service/ssl/create',
    shortenUrl: 'ssl/create',
  },
  paymentInformation: {
    key: '',
    path: '/service/ssl/payment-information',
    shortenUrl: 'ssl/payment-information',
  },
  payment: {
    key: '',
    path: '/service/ssl/payment',
    shortenUrl: 'ssl/payment',
  },
  detail: {
    key: '',
    path: '/service/ssl/detail',
    shortenUrl: 'ssl/detail/:id',
  },
};
