import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import WidgetOrder from './components/WidgetOrder';
import WidgetService from './components/WidgetService';
import Statistic from './containers/Statistic';

const Overview = () => {
  return (
    <div className="tera-page">
      <HeaderViewListV2 title="TỔNG QUAN" />
      <div className="flex justify-center">
        <div className="w-full">
          <div className="flex flex-col gap-[30px] w-full">
            <WidgetOrder />
            <WidgetService />
            <Statistic />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
