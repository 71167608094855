export const USER_ACCOUNT_URL = {
  list: {
    key: '',
    path: '/user/account/list',
    shortenUrl: 'account/list',
  },
  create: {
    key: '',
    path: '/user/account/create',
    shortenUrl: 'account/create',
  },
  update: {
    key: '',
    path: '/user/account/update',
    shortenUrl: 'account/update/:id',
  },
  detail: {
    key: '',
    path: '/user/account/detail',
    shortenUrl: 'account/detail/:id',
  },
  invoiceTemplate: {
    key: '',
    path: '/user/account/invoice-template',
    shortenUrl: 'account/invoice-template/:detailId/:id',
  },
};
