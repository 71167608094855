import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { messageValidate, messageWarning } from '_common/constants/message';
import InputNumber from '_common/dof/Control/InputNumber';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import AffiliatesAPI from 'pages/Affiliates/_common/apis';
import { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Spin, notification } from 'tera-dls';

interface IModalEditPercentageProps {
  open: boolean;
  close: () => void;
  id?: string | number;
}

function ModalEditPercentage({ open, close, id }: IModalEditPercentageProps) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      commission_percentage: '',
    },
  });
  const { isDirty } = form.formState;
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-affiliate-agency-detail', id],
    () => AffiliatesAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  useEffect(() => {
    if (!_.isEmpty(dataDetail))
      form.reset({
        ...form.getValues(),
        commission_percentage: dataDetail?.commission_percentage,
      });
  }, [dataDetail]);

  const { mutate: mutateCreateUpdate, isLoading: isLoadingCreateUpdate } =
    useMutation((params) => AffiliatesAPI.update({ id, params }), {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-affiliate-agency-list']);
          close();
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    });

  const handleSubmitForm = (values: any) => {
    if ((id && isLoadingDetail) || isLoadingCreateUpdate) return;
    mutateCreateUpdate(values);
  };

  return (
    <Modal
      title="Sửa phần trăm hoa hồng"
      open={open}
      centered={true}
      destroyOnClose
      okText="Đồng ý"
      cancelText="Hủy"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={() =>
        isDirty
          ? confirm.warning({
              title: 'Thoát bản ghi',
              content: (
                <>
                  <p>{messageWarning.WARNING_EXIT_1}</p>
                  <p>{messageWarning.WARNING_EXIT_2}</p>
                </>
              ),
              onOk: close,
            })
          : close()
      }
      className="md:w-[500px]"
      bodyClassName="!py-4"
      confirmLoading={(id && isLoadingDetail) || isLoadingCreateUpdate}
    >
      <Spin spinning={(id && isLoadingDetail) || isLoadingCreateUpdate}>
        {id && isErrorDetail ? (
          <NoData />
        ) : (
          <FormTera form={form} onSubmit={handleSubmitForm}>
            <FormTeraItem
              label="Phần trăm hoa hồng"
              name="commission_percentage"
              rules={[
                {
                  required: messageValidate?.emptyText,
                },
              ]}
            >
              <InputNumber
                suffix={<Fragment>%</Fragment>}
                min={0}
                max={100}
                allowStepHandler={false}
              />
            </FormTeraItem>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
}

export default ModalEditPercentage;
