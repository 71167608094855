import { useMutation, useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import { messageWarning } from '_common/constants/message';
import FormTera from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  Row,
  Spin,
  notification,
} from 'tera-dls';
import UserAccountAPI from '../../apis';
import { USER_ACCOUNT_URL } from '../../url';
import FormCreateUpdate from '../../../_common/components/FormCreateUpdate';
import { uuidv4 } from '_common/utils';

const CreateUpdatePage = (): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      username: '',
      email: '',
      country: '',
      country_code: '+84',
      last_middle_name: '',
      birthday: null,
      province: '',
      first_name: '',
      phone: '',
      district: '',
      gender: '',
      address: '',
      ward: '',
      citizen_id: '',
      customer_id: uuidv4(),
      password: '',
      confirm_password: '',
      status: 1,
    },
  });
  const { isDirty } = form.formState;
  const { id } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Người dùng
        </span>
      ),
      onClick: () =>
        isDirty
          ? confirm.warning({
              title: 'Thoát bản ghi',
              content: (
                <>
                  <p>{messageWarning.WARNING_EXIT_1}</p>
                  <p>{messageWarning.WARNING_EXIT_2}</p>
                </>
              ),
              onOk: () => navigate(USER_ACCOUNT_URL.list.path),
            })
          : navigate(USER_ACCOUNT_URL.list.path),
    },
    {
      title: `${id ? 'Sửa' : 'Thêm'} Người dùng`,
    },
  ];

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-user-account-detail', id],
    () => UserAccountAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  useEffect(() => {
    if (!_.isEmpty(dataDetail))
      form.reset({
        ...form.getValues(),
        username: dataDetail?.user?.username,
        email: dataDetail?.email,
        country: dataDetail?.country,
        country_code: dataDetail?.country_code,
        last_middle_name: dataDetail?.last_middle_name,
        birthday: dataDetail?.birthday ? moment(dataDetail?.birthday) : null,
        province: dataDetail?.province,
        first_name: dataDetail?.first_name,
        phone: dataDetail?.phone,
        district: dataDetail?.district,
        gender: dataDetail?.gender,
        address: dataDetail?.address,
        ward: dataDetail?.ward,
        citizen_id: dataDetail?.citizen_id,
        customer_id: dataDetail?.customer_id,
        status: dataDetail?.status,
      });
  }, [dataDetail]);

  const { mutate: mutateCreateUpdate, isLoading: isLoadingCreateUpdate } =
    useMutation(
      (params) =>
        id
          ? UserAccountAPI.update({ id, params })
          : UserAccountAPI.create({ params }),
      {
        onSuccess: (res) => {
          if (_.isEqual(res?.code, 200)) {
            notification.success({
              message: res?.msg,
            });
            navigate(-1);
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleSubmitForm = (values: any) => {
    if (isLoadingCreateUpdate) return;

    const data = {
      ...values,
      birthday: values?.birthday
        ? moment(values?.birthday).format(DATE_BACKEND_FORMAT)
        : null,
    };

    mutateCreateUpdate(data);
  };

  return (
    <Spin spinning={isLoadingCreateUpdate || (!_.isNil(id) && isLoadingDetail)}>
      <div className="tera-page-form min-h-[calc(100vh_-_45px)]">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header__breadcrumb-back cursor-pointer"
                onClick={items[items.length - 2].onClick}
              >
                <ArrowSmallLeftSolid className="h-6 w-6" />
              </div>
              <Breadcrumb separator={'/'} items={items} />
            </div>
            <div className="page-header-v2__function">
              <Button
                className="bg-blue-500"
                onClick={() => form.handleSubmit(handleSubmitForm)()}
              >
                Lưu
              </Button>
            </div>
          </div>
        </div>
        {id && (isErrorDetail || _.isEmpty(dataDetail)) ? (
          <NoData />
        ) : (
          <FormTera form={form} className="flex-1 p-2 flex flex-col gap-4">
            <Row className="bg-white p-4 rounded-[6px] drop-shadow ">
              <FormCreateUpdate isUpdate={!_.isNil(id)} />
            </Row>
          </FormTera>
        )}
      </div>
    </Spin>
  );
};

export default CreateUpdatePage;
