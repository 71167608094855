import Filter from '_common/component/Filter';
import {
  DATE_BACKEND_FORMAT,
  DATE_FORMAT,
  MAXIMUM_CURRENCY,
} from '_common/constants/common';
import DatePicker from '_common/dof/Control/DatePicker';
import RangeNumber from '_common/dof/Control/RangeNumber';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import moment from 'moment';
import {
  EXPENSE_VOUCHER_TYPE,
  PAYMENT_METHOD_TYPE,
} from 'pages/Finance/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue?: any;
}

function ExpenseVoucherFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: IProps) {
  const newForm = useForm({
    mode: 'onChange',
  });

  const handleSubmitForm = (values: any) => {
    const data = {
      ...values,
      date: values.date ? moment(values.date).format(DATE_BACKEND_FORMAT) : '',
      accounting_date: values.accounting_date
        ? moment(values.accounting_date).format(DATE_BACKEND_FORMAT)
        : '',
    };

    onFilter(data);
    onClose();
  };

  useEffect(() => {
    initialValue &&
      newForm.reset({
        ...initialValue,
        ...(initialValue.date && {
          date: moment(initialValue.date),
        }),
        ...(initialValue.accounting_date && {
          accounting_date: moment(initialValue.accounting_date),
        }),
      });
  }, [initialValue]);

  const handleReset = () => {
    const values: any = {
      transaction_type: null,
      methods: null,
      accounting_date: null,
      date: null,
      amount_from: null,
      amount_to: null,
    };
    onFilter(values);
    onClose();
  };

  const typeOptions = Object.entries(EXPENSE_VOUCHER_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const paymentMethod = Object.entries(PAYMENT_METHOD_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  // const from = newForm?.watch('from_total_amount');
  // const to = newForm?.watch('to_total_amount');

  // useEffect(() => {
  //   if (from) {
  //     newForm?.trigger('from_total_amount');
  //   }
  //   if (to) {
  //     newForm?.trigger('to_total_amount');
  //   }
  // }, [from, to, newForm]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      // containerClassName={'w-[400px]'}
      onFilter={() => newForm?.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={newForm} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Loại phiếu chi" name="transaction_type">
          <Select options={typeOptions} allowClear />
        </FormTeraItem>

        <FormTeraItem label="Phương thức thanh toán" name="methods">
          <Select options={paymentMethod} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Ngày phiếu chi" name="date">
          <DatePicker allowClear format={DATE_FORMAT} />
        </FormTeraItem>
        <FormTeraItem label="Ngày hạch toán" name="accounting_date">
          <DatePicker allowClear format={DATE_FORMAT} />
        </FormTeraItem>
        <p className="mb-2.5">Tổng giá trị</p>

        <RangeNumber
          startFormName="amount_from"
          endFormName="amount_to"
          endInputProps={{
            min: 0,
            max: MAXIMUM_CURRENCY,
          }}
          startInputProps={{
            min: 0,
            max: MAXIMUM_CURRENCY,
          }}
        />

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default ExpenseVoucherFilter;
