import { messageValidate } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import RadioGroup from '_common/dof/Control/RadioGroup';
import Select from '_common/dof/Control/Select';
import { FormTeraItem } from '_common/dof/FormTera';
import TableTera from '_common/dof/TableTera';
import { durations } from 'pages/ServiceManagement/EmailSecurity/constants';
import { Fragment, useMemo, useState } from 'react';
import {
  Button,
  ColumnsType,
  TabItemType,
  Tabs,
  Tag,
  formatCurrency,
} from 'tera-dls';

const tabItemsArr = ['Đăng ký mới', 'Chuyển về ZingServer', 'Đã mua tên miền'];

const FormDomainRegistration = (): JSX.Element => {
  const [tab, setTab] = useState<string>(tabItemsArr[0]);
  const [isCheckingDomain, setIsCheckingDomain] = useState<boolean>(false);

  const tabItems: Array<TabItemType> = useMemo(() => {
    const tabs = tabItemsArr.map((item) => {
      return {
        key: item,
        label: <div className="flex items-center gap-x-2">{item}</div>,
      };
    });
    return tabs;
  }, []);

  const TabContent1 = (): JSX.Element => {
    const columns1: ColumnsType<any> = [
      {
        title: 'Tên miền',
        dataIndex: '1',
        render: () => 'tenmien.id.vn',
      },
      {
        title: 'Trạng thái',
        dataIndex: '2',
        width: 400,
        render: () => (
          <Tag color="green03">SẴN SÀNG! ĐẶT HÀNG NGAY BAY GIỜ.</Tag>
        ),
      },
      {
        title: 'Thời hạn',
        dataIndex: '3',
        width: 300,
        render: () => (
          <FormTeraItem name="domain_settings.duration" className="mb-0">
            <Select options={durations} />
          </FormTeraItem>
        ),
      },
    ];

    const columns2: ColumnsType<any> = [
      {
        title: 'Tên miền',
        dataIndex: '1',
        render: () => 'tenmienn.com',
      },
      {
        title: 'Trạng thái',
        dataIndex: '2',
        width: 400,
        render: () => <Tag color="red03">ĐÃ ĐƯỢC ĐĂNG KÝ</Tag>,
      },
      {
        width: 300,
        fixed: 'right',
        render: () => 'WHOIS',
      },
    ];

    return (
      <div className="flex flex-col gap-4">
        <div className="w-full flex items-center gap-2">
          <div className="flex-1 bg-gray-100 border-[1px] rounded-[4px] flex items-center">
            <span className="p-2">www.</span>
            <FormTeraItem name="domain_settings.name" className="flex-1 mb-0">
              <Input className="border-none" />
            </FormTeraItem>
          </div>
          <FormTeraItem
            name="domain_settings.top_level"
            className="flex-1 mb-0"
          >
            <Select
              options={[
                { value: 1, label: '.id.vn' },
                { value: 2, label: '.com' },
                { value: 3, label: '.net' },
              ]}
            />
          </FormTeraItem>
          <Button htmlType="button" className="bg-blue-500">
            Kiểm tra
          </Button>
        </div>
        <TableTera
          columns={columns1}
          data={[
            { id: 1, description: 'Server C (tenmien.com)', price: 313000 },
          ]}
          className="center-table"
          summary={() => (
            <>
              <tr className="tera-table-row">
                <td className="tera-table-cell" colSpan={3}>
                  Lệ phí đăng ký tên miền
                  <span className="font-[600] py-2 block">
                    {formatCurrency(10000)}
                  </span>
                  Đã bao gồm trong giá bán - Không chịu thuế
                </td>
              </tr>
              <tr className="tera-table-row">
                <td className="tera-table-cell" colSpan={3}>
                  Phí duy trì tên miền
                  <span className="font-[600] py-2 block">
                    {formatCurrency(50000)}
                  </span>
                  Đã bao gồm trong giá bán - Không chịu thuế
                </td>
              </tr>
              <tr className="tera-table-row">
                <td className="tera-table-cell" colSpan={3}>
                  Mã miễn phí tên miền
                </td>
              </tr>
            </>
          )}
        />
        <TableTera
          columns={columns2}
          data={[{ id: 1 }]}
          className="center-table"
        />
      </div>
    );
  };

  const TabContent2 = (): JSX.Element => {
    const columns1: ColumnsType<any> = [
      {
        title: 'Tên miền',
        dataIndex: '1',
        render: () => (
          <FormTeraItem
            label="Epp Code:"
            name="domain_settings.epp_code"
            className="mb-0 flex items-center gap-2"
            labelClassName="mb-0"
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Input placeholder="Vui lòng nhập" />
          </FormTeraItem>
        ),
      },
      {
        title: 'Trạng thái',
        dataIndex: '2',
        width: 400,
        render: () => (
          <Tag color="green03">SẴN SÀNG! ĐẶT HÀNG NGAY BAY GIỜ.</Tag>
        ),
      },
      {
        title: 'Thời hạn',
        dataIndex: '3',
        width: 300,
        render: () => (
          <FormTeraItem name="domain_settings.duration" className="mb-0">
            <Select options={durations} />
          </FormTeraItem>
        ),
      },
    ];

    const columns2: ColumnsType<any> = [
      {
        title: 'Tên miền',
        dataIndex: '1',
        render: () => 'tenmienn.com',
      },
      {
        title: 'Trạng thái',
        dataIndex: '2',
        width: 400,
        render: () => <Tag color="red03">ĐÃ ĐƯỢC ĐĂNG KÝ</Tag>,
      },
      {
        width: 300,
        fixed: 'right',
        render: () => 'WHOIS',
      },
    ];

    return (
      <div className="flex flex-col gap-4">
        <div className="w-full flex items-center gap-2">
          <div className="flex-1 bg-gray-100 border-[1px] rounded-[4px] flex items-center">
            <span className="p-2">www.</span>
            <FormTeraItem name="domain_settings.name" className="flex-1 mb-0">
              <Input className="border-none" />
            </FormTeraItem>
          </div>
          <FormTeraItem
            name="domain_settings.top_level"
            className="flex-1 mb-0"
          >
            <Select
              options={[
                { value: 1, label: '.id.vn' },
                { value: 2, label: '.com' },
                { value: 3, label: '.net' },
              ]}
            />
          </FormTeraItem>
          <Button className="bg-blue-500">Kiểm tra</Button>
        </div>
        <TableTera
          columns={columns1}
          data={[
            { id: 1, description: 'Server C (tenmien.com)', price: 313000 },
          ]}
          className="center-table"
          summary={() => (
            <>
              <tr className="tera-table-row">
                <td className="tera-table-cell" colSpan={3}>
                  Lệ phí đăng ký tên miền
                  <span className="font-[600] py-2 block">
                    {formatCurrency(10000)}
                  </span>
                  Đã bao gồm trong giá bán - Không chịu thuế
                </td>
              </tr>
              <tr className="tera-table-row">
                <td className="tera-table-cell" colSpan={3}>
                  Phí duy trì tên miền
                  <span className="font-[600] py-2 block">
                    {formatCurrency(50000)}
                  </span>
                  Đã bao gồm trong giá bán - Không chịu thuế
                </td>
              </tr>
              <tr className="tera-table-row">
                <td className="tera-table-cell" colSpan={3}>
                  Mã miễn phí tên miền
                </td>
              </tr>
            </>
          )}
        />
        <TableTera
          columns={columns2}
          data={[{ id: 1 }]}
          className="center-table"
        />
      </div>
    );
  };

  const TabContent3 = (): JSX.Element => {
    const Content = (): JSX.Element => {
      return (
        <Fragment>
          <div className="w-full p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-4">
            <FormTeraItem
              name="domain_settings.bought_domain.option"
              className="mb-0"
            >
              <RadioGroup
                listOptions={[
                  { value: 1, label: 'Tôi sẽ sử dụng tên miền sẵn có' },
                ]}
              />
            </FormTeraItem>
            <div className="flex-1 bg-gray-100 border-[1px] rounded-[4px] flex">
              <span className="p-2 flex items-center">www.</span>
              <FormTeraItem
                name="domain_settings.bought_domain.name"
                className="flex-1 mb-0"
              >
                <Input className="border-none" />
              </FormTeraItem>
              <span className="w-[100px] bg-white p-2 border-l-[1px] flex justify-center items-center">
                .com
              </span>
            </div>
          </div>
          <div className="w-full p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-4">
            <FormTeraItem
              name="domain_settings.bought_domain.option"
              className="mb-0"
            >
              <RadioGroup
                listOptions={[
                  { value: 2, label: 'Sử dụng tên miền trong giỏ hàng' },
                ]}
              />
            </FormTeraItem>
            <FormTeraItem
              name="domain_settings.bought_domain.cart"
              className="mb-0"
            >
              <Select
                options={[
                  { value: 1, label: 'tenmien.com' },
                  { value: 2, label: 'tenmien.com' },
                  { value: 3, label: 'tenmien.com' },
                ]}
              />
            </FormTeraItem>
          </div>
          <div className="w-full p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-4">
            <FormTeraItem
              name="domain_settings.bought_domain.option"
              className="mb-0"
            >
              <RadioGroup
                listOptions={[
                  { value: 3, label: 'Sử dụng tên miền từ tài khoản của tôi' },
                ]}
              />
            </FormTeraItem>
            <FormTeraItem
              name="domain_settings.bought_domain.cart"
              className="mb-0"
            >
              <Select
                options={[
                  { value: 1, label: 'tenmien.com' },
                  { value: 2, label: 'tenmien.com' },
                  { value: 3, label: 'tenmien.com' },
                ]}
              />
            </FormTeraItem>
          </div>
          <Button
            htmlType="button"
            className="bg-blue-500"
            onClick={() => setIsCheckingDomain(true)}
          >
            Kiểm tra
          </Button>
        </Fragment>
      );
    };

    const CheckingDomain = (): JSX.Element => {
      return (
        <Fragment>
          <div className="w-full p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-4">
            tenmien.com
          </div>
          <Button
            htmlType="button"
            className="bg-blue-500"
            onClick={() => setIsCheckingDomain(false)}
          >
            Thay đổi
          </Button>
        </Fragment>
      );
    };

    return (
      <div className="flex flex-col items-start gap-4">
        {isCheckingDomain ? <CheckingDomain /> : <Content />}
      </div>
    );
  };

  return (
    <Fragment>
      <Tabs
        items={tabItems}
        onChange={(key: string) => setTab(key)}
        className="mb-0"
      />
      {tab === tabItemsArr[0] && <TabContent1 />}
      {tab === tabItemsArr[1] && <TabContent2 />}
      {tab === tabItemsArr[2] && <TabContent3 />}
    </Fragment>
  );
};

export default FormDomainRegistration;
