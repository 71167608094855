import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '_common/hooks/useDebounce';
import PaymentMethodAPI from 'pages/Finance/PaymentManagement/PaymentMethod/apis';
import { forwardRef, useState } from 'react';
import { AnyObject, OptionProps, SelectProps } from 'tera-dls';
import SelectEntity from '../SelectEntity';

interface SelectConfigLeaveProps extends SelectProps {
  paramsApi?: AnyObject;
}

export type OptionsSelectBank = OptionProps & {
  image: string;
};

const SelectBank = forwardRef<HTMLInputElement, SelectConfigLeaveProps>(
  ({ placeholder = 'Vui lòng chọn', paramsApi, ...props }, ref) => {
    const [search, setSearch] = useState<string>('');
    const searchDebounce = useDebounce(search, 300);

    const { data: dataList, isLoading } = useQuery(
      ['get-bank-list', search, paramsApi],
      () => {
        const params = {
          page: 1,
          limit: 15,
          keyword: searchDebounce,
          ...paramsApi,
        };
        return PaymentMethodAPI.getListBank({ params });
      },
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const options: OptionsSelectBank[] = dataList?.data.map((bank: any) => ({
      labelDisplay: (
        <div className="flex gap-2.5 items-center h-full">
          <img
            src={bank?.image_bank}
            className="w-[26px] h-[26px] rounded-[4px] p-[2px]"
          />
          <div className="flex flex-col justify-center text-xs line-clamp-1">
            <h3 className="font-semibold">{bank?.card_name}</h3>
            <p>{bank?.description}</p>
          </div>
        </div>
      ),
      label: bank?.card_name,
      value: bank?.id,
      image: bank?.image_bank,
    }));

    return (
      <SelectEntity
        ref={ref}
        allowClear
        showSearch
        labelInValue
        loading={isLoading}
        placeholder={placeholder}
        options={options}
        searchValue={search}
        onSearch={setSearch}
        {...props}
      />
    );
  },
);

export default SelectBank;
