import { useMutation, useQueryClient } from '@tanstack/react-query';
import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';
import { PRICE_PERIOD_TEXT } from '_common/constants/serviceManagement';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useForm } from 'react-hook-form';
import ServiceAPI from 'states/api/ServiceAPI';
import {
  Button,
  Tag,
  formatCurrency,
  formatDate,
  notification,
} from 'tera-dls';
import { TABLE_STATUS_COLOR, TABLE_STATUS_TEXT } from '../../../constants';

interface IOverviewProps {
  dataDetail: any;
}

const Overview = ({ dataDetail }: IOverviewProps): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: { note: dataDetail?.note ?? '' },
  });
  const queryClient = useQueryClient();

  const { mutate: mutateCreateUpdate, isLoading: isLoadingCreateUpdate } =
    useMutation(
      (params: any) => ServiceAPI.updateNote({ id: dataDetail?.id, params }),
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            queryClient.invalidateQueries(['get-service-cloud-vps-detail']);
            notification.success({
              message: res?.msg,
            });
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleSubmitForm = (values: any) => {
    if (isLoadingCreateUpdate) return;
    mutateCreateUpdate(values);
  };

  const informationDetails = [
    {
      icon: <ZingIcon.IconLocation color="#3f83f8" />,
      label: 'Vị trí:',
      value: dataDetail?.location.locationName,
    },
    {
      icon: <ZingIcon.IconSquareBox color="#3f83f8" />,
      label: 'Tên:',
      value: '',
    },
    {
      icon: <ZingIcon.IconEarth color="#3f83f8" />,
      label: 'Khu vực:',
      value: dataDetail?.countryName,
    },
    {
      icon: <ZingIcon.IconWindows color="#3f83f8" />,
      label: 'Hệ điều hành:',
      value: dataDetail?.os.osName,
    },
    {
      icon: <ZingIcon.IconCalendarCheck color="#3f83f8" />,
      label: 'Ngày tạo:',
      value: formatDate(dataDetail?.created_at),
    },
    {
      icon: <ZingIcon.IconCompass color="#3f83f8" />,
      label: 'Trạng thái:',
      value: (
        <Tag
          color={TABLE_STATUS_COLOR[dataDetail.status]}
          className="font-[500]"
        >
          {TABLE_STATUS_TEXT[dataDetail.status]}
        </Tag>
      ),
    },
    {
      icon: <ZingIcon.IconCalendarEmpty color="#3f83f8" />,
      label: 'Ngày hết hạn:',
      value: formatDate(dataDetail?.expires),
    },
    {
      icon: <ZingIcon.IconArrowCache color="#3f83f8" />,
      label: 'Số lần đổi IP:',
      value: (
        <span className="text-green-500">
          {dataDetail?.ip_change_count || 0}/15
        </span>
      ),
    },
    {
      icon: <ZingIcon.IconCalendarEmpty color="#3f83f8" />,
      label: 'Hạn thanh toán kế tiếp:',
      value: formatDate(dataDetail?.created_at),
    },
  ];

  const configDetails = [
    {
      icon: <ZingIcon.IconLocation color="#3f83f8" />,
      label: 'RAM:',
      value: dataDetail?.ram,
      unit: 'GB',
    },
    {
      icon: <ZingIcon.IconSquareBox color="#3f83f8" />,
      label: 'vCPU:',
      value: dataDetail?.cpu,
      unit: '',
    },
    {
      icon: <ZingIcon.IconEarth color="#3f83f8" />,
      label: 'SSD:',
      value: dataDetail?.ssd,
      unit: 'GB',
    },
    {
      icon: <ZingIcon.IconWindows color="#3f83f8" />,
      label: 'Mbps:',
      value: '',
      unit: '',
    },
    {
      icon: <ZingIcon.IconCalendarCheck color="#3f83f8" />,
      label: 'Wifi:',
      value: dataDetail?.bandwidth,
      unit: '',
    },
    {
      icon: <ZingIcon.IconCalendarEmpty color="#3f83f8" />,
      label: 'Đơn giá:',
      value: (
        <span className="text-red-500">
          {formatCurrency(dataDetail?.product[dataDetail?.period])}
        </span>
      ),
      unit: `/ ${PRICE_PERIOD_TEXT[dataDetail?.period]}`,
    },
  ];

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <IconText prefix={<ZingIcon.IconInformation color="#0e9f6e" />}>
          <span className="font-[500]">Thông tin chung:</span>
        </IconText>
        <div className="grid grid-cols-2">
          {informationDetails.map((detail, index) => (
            <IconText
              key={index}
              prefix={detail.icon}
              className="py-4 border-b-[1px]"
            >
              <span>{detail.label}</span>
              <span className="font-[500]">{detail.value}</span>
            </IconText>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <IconText prefix={<ZingIcon.IconVPS color="#0E9F6E" />}>
          <span className="font-[500]">Cấu hình:</span>
        </IconText>
        <div className="grid grid-cols-2">
          {configDetails.map((detail, index) => (
            <IconText
              key={index}
              prefix={detail.icon}
              className="py-4 border-b-[1px]"
            >
              <span>{detail.label}</span>
              <span className="font-[500]">{detail.value}</span>
              <span>{detail.unit}</span>
            </IconText>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <IconText prefix={<ZingIcon.IconNote color="#0e9f6e" />}>
          <span className="font-[500]">Ghi chú:</span>
        </IconText>
        <FormTera form={form} className="flex flex-col items-start gap-2">
          <FormTeraItem name="note" className="w-full mb-0">
            <TextArea placeholder="Vui lòng nhập" rows={5} />
          </FormTeraItem>
          <Button
            htmlType="submit"
            prefix={<ZingIcon.IconFloppyDisk />}
            onClick={form.handleSubmit(handleSubmitForm)}
          >
            Lưu ghi chú
          </Button>
        </FormTera>
      </div>
    </div>
  );
};

export default Overview;
