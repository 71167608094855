import { useMutation } from '@tanstack/react-query';
import { REGEX } from '_common/constants/common';
import { messageValidate, messageWarning } from '_common/constants/message';
import InputPassword from '_common/dof/Control/InputPassword';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useForm } from 'react-hook-form';
import { AuthApi } from 'states/api';
import {
  EyeOutlined,
  EyeSlashOutlined,
  Modal,
  notification,
  Spin,
} from 'tera-dls';

interface ModalChangePassword {
  open: boolean;
  userId: number;
  onClose: () => void;
  onSuccess?: () => void;
}

function ModalChangePassword({
  open,
  userId,
  onClose,
  onSuccess,
}: ModalChangePassword) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: { password: '', confirm_password: '' },
  });
  const confirm = useConfirm();
  const { isDirty } = form.formState;

  const { mutate: mutateChangePassword, isLoading: isLoadingMutate } =
    useMutation((params: any) => AuthApi.changePassword({ params }), {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          onSuccess && onSuccess();
          onClose && onClose();
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    });

  const handleSubmitForm = (values: any) => {
    if (isLoadingMutate) return;
    mutateChangePassword({
      member_id: userId,
      password: values?.password,
    });
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  return (
    <Modal
      title="Thay đổi mật khẩu"
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      bodyClassName="!py-4"
      confirmLoading={isLoadingMutate}
    >
      <Spin spinning={isLoadingMutate}>
        <FormTera form={form} className="flex flex-col gap-2">
          <FormTeraItem
            label="Mật khẩu mới"
            name="password"
            className="mb-0"
            rules={[
              {
                required: messageValidate.emptyText,
                pattern: {
                  value: new RegExp(REGEX.PASSWORD),
                  message: messageValidate.password,
                },
                minLength: {
                  value: 8,
                  message: messageValidate.min_length_password,
                },
                maxLength: {
                  value: 16,
                  message: messageValidate.max_length_password,
                },
              },
            ]}
          >
            <InputPassword
              iconRender={(visible) =>
                visible ? (
                  <EyeSlashOutlined className="size-5" />
                ) : (
                  <EyeOutlined className="size-5" />
                )
              }
            />
          </FormTeraItem>
          <FormTeraItem
            label="Nhập lại mật khẩu"
            name="confirm_password"
            className="mb-0"
            rules={[
              {
                required: messageValidate.emptyText,
                pattern: {
                  value: new RegExp(REGEX.PASSWORD),
                  message: messageValidate.password,
                },
                minLength: {
                  value: 8,
                  message: messageValidate.min_length_password,
                },
                maxLength: {
                  value: 16,
                  message: messageValidate.max_length_password,
                },
                validate: {
                  existed: (value, values) => {
                    const password = values?.password.trim();

                    if (value?.trim() !== password) {
                      return messageValidate.confirm_password;
                    }
                  },
                },
              },
            ]}
          >
            <InputPassword
              iconRender={(visible) =>
                visible ? (
                  <EyeSlashOutlined className="size-5" />
                ) : (
                  <EyeOutlined className="size-5" />
                )
              }
            />
          </FormTeraItem>
        </FormTera>
      </Spin>
    </Modal>
  );
}

export default ModalChangePassword;
