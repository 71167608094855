const UserIcon = (props) => (
  <svg
    width="24"
    height="27"
    viewBox="0 0 24 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.3638 7.04525C18.3638 10.6085 15.5342 13.4381 11.971 13.4381C8.51253 13.4381 5.57812 10.6085 5.57812 7.04525C5.57812 3.48201 8.51253 0.652344 11.971 0.652344C15.5342 0.652344 18.3638 3.48201 18.3638 7.04525Z"
      fill="#D1D5DB"
    />
    <path
      d="M11.9707 15.1133C7.04508 15.1133 2.64343 17.7333 0.442609 21.5061C-0.815005 23.9166 0.757089 26.9558 3.27232 26.9558H20.6693C23.2893 26.9558 24.8613 23.9166 23.4989 21.5061C21.4028 17.7333 17.0012 15.1133 11.9707 15.1133Z"
      fill="#D1D5DB"
    />
  </svg>
);
export default UserIcon;
