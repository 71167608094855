import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { messageValidate, messageWarning } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCountry from '_common/dof/Select/SelectCountry';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Spin, notification } from 'tera-dls';
import LocationAPI from '../apis';

interface IModalModalCreateUpdateProps {
  open: boolean;
  close: () => void;
  id?: string | number;
}

const ModalCreateUpdate = ({
  open,
  close,
  id,
}: IModalModalCreateUpdateProps): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      locationId: '',
      locationName: '',
      zoneCode: '',
      countryCode: '',
    },
  });
  const { isDirty } = form.formState;
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-package-location-detail', id],
    () => LocationAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  useEffect(() => {
    if (!_.isEmpty(dataDetail))
      form.reset({
        ...form.getValues(),
        locationId: dataDetail?.locationId,
        locationName: dataDetail?.locationName,
        zoneCode: dataDetail?.zoneCode,
        countryCode: dataDetail?.countryCode,
      });
  }, [dataDetail]);

  useEffect(() => {
    if (form.watch('countryCode'))
      form.setValue('zoneCode', form.watch('countryCode'));
  }, [form.watch('countryCode')]);

  const { mutate: mutateCreateUpdate, isLoading: isLoadingMutate } =
    useMutation(
      (params) =>
        id
          ? LocationAPI.update({ id, params })
          : LocationAPI.create({ params }),
      {
        onSuccess: (res) => {
          if (_.isEqual(res?.code, 200)) {
            notification.success({
              message: res?.msg,
            });
            queryClient.invalidateQueries([
              'get-service-package-location-list',
            ]);
            close();
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleSubmitForm = (values: any) => {
    if ((id && isLoadingDetail) || isLoadingMutate) return;
    mutateCreateUpdate(values);
  };

  return (
    <Modal
      title={`${id ? 'Sửa' : 'Thêm mới'} vị trí`}
      open={open}
      centered={true}
      destroyOnClose
      okText="Đồng ý"
      cancelText="Hủy"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={() =>
        isDirty
          ? confirm.warning({
              title: 'Thoát bản ghi',
              content: (
                <>
                  <p>{messageWarning.WARNING_EXIT_1}</p>
                  <p>{messageWarning.WARNING_EXIT_2}</p>
                </>
              ),
              onOk: close,
            })
          : close()
      }
      contentClassName="md:w-[500px] h-full"
      bodyClassName="!py-4"
      confirmLoading={(id && isLoadingDetail) || isLoadingMutate}
    >
      <Spin spinning={(id && isLoadingDetail) || isLoadingMutate}>
        {id && isErrorDetail ? (
          <NoData />
        ) : (
          <FormTera form={form} className="flex flex-col gap-2">
            <FormTeraItem
              label="Mã vị trí"
              name="locationId"
              className="mb-0"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem
              label="Tên vị trí"
              name="locationName"
              className="mb-0"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem
              label="Quốc gia"
              name="countryCode"
              className="mb-0"
              rules={[
                {
                  required: messageValidate.emptySelect,
                },
              ]}
            >
              <SelectCountry
                paramsApi={{ include_id: form.watch('countryCode') }}
                allowClear={false}
              />
            </FormTeraItem>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
};

export default ModalCreateUpdate;
