import React, { useState } from 'react';
import { Button, FunnelOutlined, Tag } from 'tera-dls';
import HistoryFilter from './Filter';
import moment from 'moment';
import TableTera from '_common/dof/TableTera';
import { DATE_FORMAT } from '_common/constants/common';

const Tip = () => {
  const [params, setParams] = useState<any>();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const columnsA = [
    {
      title: 'Ngày',
      dataIndex: 'date',
      width: 200,
      render: (val) => moment(val).format(DATE_FORMAT),
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      width: 200,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 200,
      render: (val) =>
        val === 'success' ? (
          <Tag color="green03">Thành công</Tag>
        ) : (
          <Tag color="gray03">Thất bại</Tag>
        ),
    },
  ];
  return (
    <>
      <div className="flex flex-col gap-[16px] ">
        <p className="text-[13px]">Phần nhật ký tài khoản và lịch sử</p>
        <div className="flex justify-end flex-col shadow bg-white rounded-md py-2 gap-2">
          <div className="flex justify-end pr-2">
            <Button
              type="alternative"
              className="rounded-xsm py-1 px-1 bg-blue-50"
              icon={
                <FunnelOutlined className=" text-blue-500 rounded-[4px] shrink-0" />
              }
              onClick={() => setOpenFilter(true)}
            />
          </div>
          <TableTera
            columns={columnsA}
            className="center-table"
            wrapperClassName="shadow-none"
            pagination={{}}
            data={[
              {
                date: moment().format(),
                description: 'Client has logged in (116.110.40.255)',
                status: 'success',
              },
            ]}
          />
        </div>
      </div>
      {openFilter && (
        <HistoryFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          initialValues={{ time: params?.time }}
          onFilter={(val) => setParams((prev = {}) => ({ ...prev, ...val }))}
        />
      )}
    </>
  );
};

export default Tip;
