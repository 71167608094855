export enum ACCOUNT_KYC_STATUS {
  NEW = 'New',
  APPROVAL = 'Approval',
  REFUSED = 'Refused',
}
export const TABS_STATUS_ITEM = ['all', 'Approval', 'New', 'Refused'];
export const TABS_STATUS_TEXT = {
  all: 'Tất cả',
  [ACCOUNT_KYC_STATUS.APPROVAL]: 'Đã duyệt',
  [ACCOUNT_KYC_STATUS.NEW]: 'Đang chờ',
  [ACCOUNT_KYC_STATUS.REFUSED]: 'Từ chối',
};
export const TABLE_STATUS_COLOR = {
  [ACCOUNT_KYC_STATUS.APPROVAL]: 'green03',
  [ACCOUNT_KYC_STATUS.NEW]: 'yellow03',
  [ACCOUNT_KYC_STATUS.REFUSED]: 'red03',
};
export const TABLE_STATUS_TEXT = {
  [ACCOUNT_KYC_STATUS.APPROVAL]: 'Đã duyệt',
  [ACCOUNT_KYC_STATUS.NEW]: 'Đang chờ',
  [ACCOUNT_KYC_STATUS.REFUSED]: 'Từ chối',
};
