import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import TableTera from '_common/dof/TableTera';
import moment from 'moment';
import React, { useState } from 'react';
import DoubleCheckedIcon from 'styles/images/Icons/DoubleCheckedIcon';
import {
  Button,
  FunnelOutlined,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import HistoryFilter from './containers/Filter';
import { useLocation, useNavigate } from 'react-router-dom';
import { DATE_TIME_FORMAT } from '_common/constants/common';

const NotificationDetail = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleFilter = (values: any) => {
    handleUpdateQueryParams(values);
  };

  const columnsC = [
    {
      title: 'Ngày',
      dataIndex: 'date',
      width: 200,
      render: (val) => moment(val).format(DATE_TIME_FORMAT),
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'description',
      width: 200,
    },
    {
      title: 'Liên quan đến',
      dataIndex: 'related_to',
      width: 200,
      render: (val) => <span className="text-blue-500">{val}</span>,
    },
  ];
  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2 title="THÔNG BÁO">
          <div className="flex flex-col gap-[16px] mt-5">
            <div className="flex justify-end flex-col shadow rounded-md bg-white py-2 gap-2">
              <div className="flex justify-between px-2">
                <Button className="bg-blue-500 font-light !py-[1px]">
                  <DoubleCheckedIcon className="size-4" />
                  Đánh đấu tất cả đã đọc
                </Button>
                <Button
                  type="alternative"
                  className="rounded-xsm py-1 px-1 bg-blue-50"
                  icon={
                    <FunnelOutlined className=" text-blue-500 rounded-[4px] shrink-0" />
                  }
                  onClick={() => setOpenFilter(true)}
                />
              </div>
              <TableTera
                columns={columnsC}
                className="center-table"
                wrapperClassName="shadow-none"
                pagination={{}}
                data={[
                  {
                    date: moment().format(),
                    description: 'Client has logged in (116.110.40.255)',
                    related_to: '#NT735743',
                  },
                ]}
              />
            </div>
          </div>
        </HeaderViewListV2>
      </div>
      {openFilter && (
        <HistoryFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          initialValues={queryParams}
          onFilter={handleFilter}
        />
      )}
    </>
  );
};

export default NotificationDetail;
