export const TABS_STATUS_ITEM = ['all', '1', '0'];
export const TABS_STATUS_TEXT = {
  all: 'Tất cả',
  0: 'Ngưng hoạt động',
  1: 'Đang hoạt động',
};
export const TABLE_STATUS_COLOR = {
  0: 'gray03',
  1: 'green03',
};
export const TABLE_STATUS_TEXT = {
  0: 'Ngưng hoạt động',
  1: 'Đang hoạt động',
};
