import { useQuery } from '@tanstack/react-query';
import VerticalBarChart from '_common/component/Chart/BarChart/VerticalBarChart';
import NoData from '_common/component/NoData';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import moment from 'moment';
import { CHART_TYPE, CHART_TYPE_COLOR } from 'pages/Finance/constants';
import { useContext, useEffect, useMemo, useState } from 'react';
import customTwMerge from 'tailwind-merge.config';
import { ChartBarProps, Spin } from 'tera-dls';
import { StatisticCashExpenseParamContext } from '..';
import StatisticApi from '../../../api';
import Header from '../../Header';
import ModalChart from './Modal';
import SettingModal from './Setting';

export type SaleOfCustomerTypeChartType = 'small' | 'large';
interface IProps {
  type?: SaleOfCustomerTypeChartType;
  onClose?: () => void;
  chartOptions?: ChartBarProps['options'];
  chartClassName?: string;
  defaultLengthShowTick?: number;
  labelMaxLength?: number;
  defaultLengthScroll?: number;
}

const Content = (props: IProps) => {
  const { type = 'small', onClose, chartClassName = '' } = props;
  const [openModal, setOpenModal] = useState<boolean>();
  const [openSetting, setOpenSetting] = useState<boolean>(false);

  const { contextParams } = useContext(StatisticCashExpenseParamContext);

  const currentWeek = [
    moment().startOf('week').format(DATE_BACKEND_FORMAT),
    moment().endOf('week').format(DATE_BACKEND_FORMAT),
  ];

  const [params, setParams] = useState<any>({
    pay_method_type: ['cash', 'transfer'],
    accounting_date: currentWeek,
    date: currentWeek,
    accounting_time: 'currentWeek',
    time: 'currentWeek',
  });

  useEffect(() => {
    contextParams && setParams(contextParams);
  }, [contextParams]);

  const {
    data: response,
    refetch,
    isRefetching,
  } = useQuery(
    ['get-statistic-by-transaction-type', contextParams],
    () =>
      StatisticApi.getChart({
        params: {
          chart_type: 'byTransactionType',
          investor_id: contextParams?.investor_id,
          receipt_transaction_type:
            contextParams?.receipt_transaction_type?.join(','),
          payment_transaction_type:
            contextParams?.payment_transaction_type?.join(','),
          date_from: contextParams?.date[0],
          date_to: contextParams?.date[1],
          payment_method: contextParams?.payment_method?.join(','),
          show_by: contextParams?.show_by,
        },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, [params]);

  const dataSource = useMemo(() => {
    const labels = [];
    const data = [];
    const backgroundColor = [];
    Object.entries(CHART_TYPE).forEach(([key, value]) => {
      labels.push(value.title);
      data.push(
        response?.group_by?.[value.parent]?.[value.key]?.total_amount ?? 0,
      );
      backgroundColor.push(CHART_TYPE_COLOR[key]);
    });

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor,
        },
      ],
    };
  }, [response]);

  const isSmallType = type === 'small';

  const checkData = (data) =>
    data?.datasets?.[0]?.data?.some((value) => value !== 0);

  const maxValue = useMemo(() => {
    const values = Object.values(CHART_TYPE).map(
      (value) =>
        Number(response?.group_by?.[value.parent]?.[value.key]?.total_amount) ||
        0,
    );
    const max = Math.round(Math.max(...values) * 1.05);
    return max;
  }, [response]);

  return (
    <>
      <div className="rounded-[4px] border-[1px] bg-white flex flex-col h-full overflow-hidden">
        <Header
          title={'Thống kê theo loại thu chi'}
          onClickRefresh={() => refetch()}
          onClickConfig={() => setOpenSetting(true)}
          onZoomOut={() => setOpenModal(true)}
          onZoomIn={() => onClose()}
          isSmallType={isSmallType}
        />
        <Spin spinning={isRefetching}>
          <div className="p-[16px] pb-0 h-full">
            {checkData(dataSource) ? (
              <VerticalBarChart
                containerClassName={
                  (customTwMerge('h-auto'), !isSmallType && 'h-full')
                }
                showLegend={false}
                data={dataSource}
                type={'large'}
                chartClassName={customTwMerge('', chartClassName)}
                defaultLabelMaxLength={30}
                maxX={maxValue}
              />
            ) : (
              <NoData />
            )}
          </div>
        </Spin>
      </div>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
      {openSetting && (
        <SettingModal
          open={openSetting}
          onClose={() => setOpenSetting(false)}
          onSubmit={setParams}
          defaultValues={params}
        />
      )}
    </>
  );
};

export default Content;
