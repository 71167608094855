import ModalConfirm from '_common/component/ModalConfirm';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import MenuComponent from '../Menu';
import InlineMenu from '../Menu/InlineMenu';
const closedKeys = ['detail', 'update', 'create', 'overview'];

function BasicLayout() {
  const [isExpand, setIsExpand] = useState<boolean>(true);
  const location = useLocation();

  useEffect(() => {
    const isClose = closedKeys.some((key) => location.pathname.includes(key));
    isClose ? setIsExpand(false) : setIsExpand(true);
  }, [location.pathname]);

  return (
    <>
      <div className="w-full">
        <InlineMenu isExpand={isExpand} onChangeSize={setIsExpand} />
        <div
          className={`flex flex-col flex-1 w-full h-full transition-all min-h-screen ${
            isExpand ? 'xmd:pl-[225px]' : 'xmd:pl-[50px]'
          }`}
        >
          <MenuComponent isExpand={isExpand} />

          <div className={`pt-[45px] bg-[#F3F3F9] h-full flex-1`}>
            <Outlet />
          </div>
        </div>
      </div>

      <ModalConfirm />
    </>
  );
}

export default observer(BasicLayout);
