import CardDetail from '_common/component/CardDetail';
import { DATE_FORMAT, GENDER } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import {
  ArrowDownOutlined,
  Button,
  Col,
  Progress,
  Row,
  formatCurrency,
  formatDate,
  formatNumber,
} from 'tera-dls';

interface IOverviewProps {
  dataDetail: any;
  isAdmin?: boolean;
}

const Overview = ({ dataDetail, isAdmin }: IOverviewProps) => {
  const column1: any = [
    {
      title: 'Tên dự án',
      dataIndex: 'name',
      width: 150,
    },
    {
      title: 'Tỉ lệ hoàn vốn hàng ngày',
      dataIndex: 'percentage',
      width: 180,
      render: (val) => <span>{val}%</span>,
    },
    {
      title: 'Số tiền đầu tư',
      dataIndex: 'amount',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Tiền lãi đã trả',
      dataIndex: '12222222223',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Tiền lãi chưa trả',
      dataIndex: '22222233333',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
    },
    {
      title: 'Kiểm tra',
      dataIndex: 'check',
      width: 150,
    },
    {
      title: 'Hợp đồng',
      dataIndex: 'contract',
      fixed: 'right',
      width: 130,
      render: () => (
        <Button className="flex gap-1 bg-blue-500 font-light text-[13px]">
          <ArrowDownOutlined className="size-4" />
          <span>Tải xuống</span>
        </Button>
      ),
    },
  ];

  const column2: any = [
    {
      title: 'Ngày nhận quyền lợi',
      dataIndex: 'time',
      width: 150,
      render: (val) => <div className="py-1.5">{val}</div>,
    },
    {
      title: 'Tiền bạc',
      dataIndex: 'amount',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Dự án',
      dataIndex: 'project',
      width: 150,
    },
  ];

  const column3: any = [
    {
      title: 'Loại thông tin',
      dataIndex: 'information',
      width: 150,
      render: (val) => <div className="py-1.5">{val}</div>,
    },
    {
      title: 'Dữ liệu',
      dataIndex: 'data',
      width: 150,
    },
    {
      title: 'Purpose',
      dataIndex: 'purpose',
      width: 150,
    },
  ];

  const detail = [
    {
      information: 'Danh xưng',
      data: GENDER[dataDetail?.gender],
      purpose: '',
    },
    {
      information: 'Họ và tên đệm',
      data: dataDetail?.last_middle_name,
      purpose: '',
    },
    {
      information: 'Tên',
      data: dataDetail?.first_name,
      purpose: '',
    },
    {
      information: 'Số CCCD/Passport',
      data: dataDetail?.citizen_id,
      purpose: '',
    },
    {
      information: 'Email',
      data: dataDetail?.email,
      purpose: '',
    },
    {
      information: 'Ngày sinh',
      data: formatDate(dataDetail?.birthday, DATE_FORMAT),
      purpose: '',
    },
    {
      information: 'Điện thoại',
      data: dataDetail?.phone,
      purpose: '',
    },
    {
      information: 'Quốc gia',
      data: dataDetail?.country,
      purpose: '',
    },
    {
      information: 'Tỉnh/Thành',
      data: dataDetail?.province,
      purpose: '',
    },
  ];

  return (
    <Col className="flex flex-col gap-4">
      <CardDetail title="Tài khoản của tôi">
        <Col className="flex items-center flex-col xmd:flex-row">
          <Col className="flex flex-col gap-2 items-center">
            <Col className="col-span-1 flex-col gap-4 flex xmd:hidden">
              <Col className="font-medium text-[20px] mt-2 leading-[24px] text-center">
                {dataDetail?.user?.full_name}
              </Col>
              <Row className="flex gap-2 items-center sm:hidden">
                <span className="shrink-0">Cấp độ bảo mật:</span>
                <span className="w-[200px]">
                  <Progress percent={70} size={10} />
                </span>
              </Row>
            </Col>
          </Col>
          <Col className="flex-1 flex items-center ">
            <Col className="grid grid-cols-2 sm:grid-cols-3 w-full ">
              <Col className="col-span-1 flex-col gap-[16px] hidden sm:flex">
                <Col className="font-medium hidden xmd:block text-[20px] leading-[24px]">
                  {dataDetail?.user?.full_name}
                </Col>
                {!isAdmin && (
                  <Row className="flex gap-2 items-center">
                    <span className="shrink-0">Cấp độ bảo mật:</span>
                    <span className="w-[200px]">
                      <Progress percent={70} size={10} />
                    </span>
                  </Row>
                )}
              </Col>
              {!isAdmin && (
                <Col className="flex gap-1.5 col-span-1 items-end justify-center">
                  Số dư khả dụng:
                  <span className="text-red-500">
                    {formatCurrency(dataDetail?.wallet?.available_balance)}
                  </span>
                </Col>
              )}
              {!isAdmin && (
                <Col className="flex gap-1.5 col-span-1 items-end justify-end">
                  Tổng tài khoản:
                  <span className="text-red-500">
                    {formatCurrency(dataDetail?.wallet?.original_balance)}
                  </span>
                </Col>
              )}
            </Col>
          </Col>
        </Col>
      </CardDetail>
      {!isAdmin && (
        <CardDetail className="flex flex-col">
          <Row className="grid grid-cols-2 md:grid-cols-5 gap-5">
            <Col className="col-span-1 flex flex-col gap-[16px] items-center">
              <span className="text-gray-500">Số lợi nhuận chờ nhận</span>
              <span className="font-medium text-gray-700">
                {formatNumber(0)}
              </span>
            </Col>
            <Col className="col-span-1 flex flex-col gap-[16px] items-center">
              <span className="text-gray-500">Lãi tích lũy</span>
              <span className="font-medium text-gray-700">
                {formatCurrency(0)}
              </span>
            </Col>
            <Col className="col-span-1 flex flex-col gap-[16px] items-center">
              <span className="text-gray-500">Số tiền gốc chờ nhận</span>
              <span className="font-medium text-gray-700">
                {formatCurrency(0)}
              </span>
            </Col>
            <Col className="col-span-1 flex flex-col gap-[16px] items-center">
              <span className="text-gray-500">Số tiền rút</span>
              <span className="font-medium text-gray-700">
                {formatCurrency(0)}
              </span>
            </Col>
            <Col className="col-span-1 flex flex-col gap-[16px] items-center">
              <span className="text-gray-500">Tổng số tiền rút</span>
              <span className="font-medium text-gray-700">
                {formatCurrency(0)}
              </span>
            </Col>
          </Row>
          <Col className="flex flex-col divide-y-[1px] divide-dotted">
            <CardDetail title="Đầu tư gần đây" className="!shadow-none">
              <TableTera
                rowKey={(_, index) => index}
                data={[]}
                columns={column1}
                className="center-table"
              />
            </CardDetail>
            <CardDetail
              title="Thu nhập gần đây"
              className="!shadow-none pt-5 pb-0"
            >
              <TableTera
                rowKey={(_, index) => index}
                data={[]}
                columns={column2}
                className="center-table"
              />
            </CardDetail>
          </Col>
        </CardDetail>
      )}
      <TableTera
        rowKey={(_, index) => index}
        data={detail}
        columns={column3}
        wrapperClassName="[box-shadow:0px_1px_7px_0px_#0000001A,0px_-1px_7px_0px_#0000001A] rounded-[10px]"
        className="center-table"
      />
      <Col className="flex flex-col items-start gap-2">
        <Col className="text-[#6B7280] text-[14px] leading-[16px]">
          Bạn có thể tải thông tin xuống bằng cách click nút phía dưới
        </Col>
        <Button
          prefix={<ArrowDownOutlined className="size-4" />}
          className="flex gap-1 bg-blue-500 font-light text-[13px]"
        >
          Tải xuống
        </Button>
      </Col>
    </Col>
  );
};

export default Overview;
