import FormSummary from '_common/component/Form/Summary';
import FormTera from '_common/dof/FormTera';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ArrowSmallLeftSolid, Breadcrumb } from 'tera-dls';
import { cloudServerTypes } from '../../constants';
import { SERVICE_CLOUD_SERVER_URL } from '../../url';
import FormAddLocation from './components/FormAddLocation';
import FormAddPrivate from './components/FormAddPrivate';

const CreateUpdatePage = (): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      product: null,
      cloud_server_type: 1,
      service_package: 1,
      cpu: '1',
      ram: '0',
      ip: 1,
      os: 1,
      panel: 1,
      ssd_240: 0,
      ssd_480: 0,
      ssd_960: 0,
      ssd_1500: 0,
      ssd_1920: 0,
      bandwidth: 1,
      domain: '',
    },
  });
  const navigate = useNavigate();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Cloud Server
        </span>
      ),
      onClick: () => navigate(SERVICE_CLOUD_SERVER_URL.list.path),
    },
    {
      title: 'Thêm cấu hình Cloud Server',
    },
  ];

  return (
    <div className="tera-page-form min-h-[calc(100vh_-_45px)]">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
      </div>
      <div className="px-2">
        <div className="bg-white p-4 rounded-[6px] drop-shadow mb-4 flex flex-col gap-4">
          <span>
            Nhanh chóng thiết lập kế hoạch ưa thích của bạn và bắt đầu công việc
            kinh doanh mới của bạn.
          </span>
          <div className="flex justify-center gap-6">
            {cloudServerTypes.map((type) => (
              <div
                key={type.id}
                className={`w-[300px] bg-white p-6 py-8 rounded-[10px] drop-shadow-xl flex flex-col items-center gap-4 cursor-pointer ${
                  form.watch('cloud_server_type') === type.id &&
                  'text-blue-800 !bg-[#EBF5FF]'
                }`}
                onClick={() => form.setValue('cloud_server_type', type.id)}
              >
                <div className="w-full h-[150px] bg-[#D9D9D9] rounded-[10px]" />
                <span className="w-full text-blue-800 font-[500]">
                  {type.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <FormTera form={form} className="flex-1 flex flex-col">
        {form.watch('cloud_server_type') === 1 ? (
          <FormAddLocation form={form} />
        ) : (
          <FormAddPrivate form={form} />
        )}
        {form.watch('product') && <FormSummary form={form} />}
      </FormTera>
    </div>
  );
};

export default CreateUpdatePage;
