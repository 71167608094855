import { useQuery } from '@tanstack/react-query';
import DefaultImage from '_common/component/DefaultImage';
import { useEffect, useMemo } from 'react';
import cost_order from 'styles/images/cost_order.png';
import number_order from 'styles/images/number_order.png';
import { Spin, formatNumber } from 'tera-dls';
import DashboardAPI from '../apis';
import { INVOICE_STATUS } from '_common/constants/invoiceManagement';

const WidgetOrder = (): JSX.Element => {
  const {
    data: dataOverviewOrder,
    refetch: refetchDataOverviewOrder,
    isLoading: isLoadingDataOverviewOrder,
  } = useQuery(
    ['get-dashboard-overview-order'],
    () => {
      return DashboardAPI.getOverviewOrder();
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    refetchDataOverviewOrder();
  }, []);

  const totalOrderUnpaid = useMemo(
    () =>
      dataOverviewOrder?.status_count.find(
        (service) => service.status === INVOICE_STATUS.UNPAID,
      )?.total || 0,
    [dataOverviewOrder?.status_count],
  );

  const totalOrderPaid = useMemo(
    () =>
      dataOverviewOrder?.status_count.find(
        (service) => service.status === INVOICE_STATUS.PAID,
      )?.total || 0,
    [dataOverviewOrder?.status_count],
  );

  const totalOrderProcessing = useMemo(
    () =>
      dataOverviewOrder?.status_count.find(
        (service) => service.status === INVOICE_STATUS.PROCESSING,
      )?.total || 0,
    [dataOverviewOrder?.status_count],
  );

  return (
    <Spin spinning={isLoadingDataOverviewOrder}>
      <div className="grid grid-cols-2 gap-[15px]">
        <div className="bg-white col-span-1 rounded-[5px] border-[1px] p-2.5 overflow-hidden">
          <div className="flex gap-2.5">
            <div className={'flex flex-col xmd:gap-[15px] gap-[25px] flex-1'}>
              <div className="line-clamp-1 text-gray-500 font-medium">
                Số lượng đơn hàng
              </div>
              <div className="flex flex-col">
                <span
                  className={
                    'text-base xmd:text-[28px] xmd:leading-7 flex gap-2'
                  }
                >
                  <p className="flex gap-1.5">
                    <span className="text-yellow-500 text-[13px]">
                      Chưa thanh toán
                    </span>
                    <span className="text-[22px] leading-[23px] text-[#1F2937]">
                      {formatNumber(totalOrderUnpaid)}
                    </span>
                  </p>
                  <p className="flex gap-1.5">
                    <span className="text-green-500 text-[13px]">
                      Đã thanh toán
                    </span>
                    <span className="text-[22px] leading-[23px] text-[#1F2937]">
                      {formatNumber(totalOrderPaid)}
                    </span>
                  </p>
                  <p className="flex gap-1.5">
                    <span className="text-blue-500  text-[13px]">
                      Đang xử lý
                    </span>
                    <span className="text-[22px] leading-[23px] text-[#1F2937]">
                      {formatNumber(totalOrderProcessing)}
                    </span>
                  </p>
                </span>
              </div>
            </div>
            <DefaultImage
              src={number_order}
              alt={''}
              className={'hidden w-[40px] h-[40px] object-cover xl:block'}
            />
          </div>
        </div>
        <div className=" bg-white col-span-1 rounded-[5px] border-[1px] p-2.5 overflow-hidden">
          <div className="flex gap-2.5">
            <div className={'flex flex-col xmd:gap-[15px] gap-[25px] flex-1'}>
              <div className="line-clamp-1 text-gray-500 font-medium">
                Doanh thu đơn hàng
              </div>
              <div className="flex flex-col">
                <span
                  className={
                    'text-base !text-[32px] leading-7 flex gap-2 text-[#0E9F6E] font-medium tracking-[1px]'
                  }
                >
                  {formatNumber(dataOverviewOrder?.order_revenue)}
                </span>
              </div>
            </div>
            <DefaultImage
              src={cost_order}
              alt={''}
              className={'hidden w-[60px] h-[40px] object-cover xl:block'}
            />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default WidgetOrder;
