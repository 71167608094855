export const ACCOUNT_KYC_URL = {
  list: {
    key: '',
    path: '/user/kyc',
    shortenUrl: 'kyc',
  },
  detail: {
    key: '',
    path: '/user/kyc/detail',
    shortenUrl: 'kyc/detail/:id',
  },
};
