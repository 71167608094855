export const TABS_STATUS_ITEM = ['all', '1', '2', '0'];
export const TABS_STATUS_TEXT = {
  all: 'Tất cả',
  0: 'Đã hủy',
  1: 'Hoạt động',
  2: 'Đang chờ',
};
export const DOMAIN_STATUS_COLOR = {
  0: 'red03',
  1: 'green03',
  2: 'yellow03',
};
export const DOMAIN_STATUS = {
  0: 'Đã hủy',
  1: 'Hoạt động',
  2: 'Đang chờ',
};
export const cloudServerTypes = [
  { id: 1, name: 'Chỗ đặt Cloud Server' },
  { id: 2, name: 'Cloud Server riêng' },
];
export const locationServicePackages = [
  {
    id: 1,
    name: 'Colo A',
  },
  {
    id: 2,
    name: 'Colo B',
  },
  {
    id: 3,
    name: 'Colo C',
  },
];
export const privateServicePackages = [
  {
    id: 1,
    name: 'Server A',
  },
  {
    id: 2,
    name: 'Server B',
  },
  {
    id: 3,
    name: 'Server C',
  },
];
export const cpuOptions = [
  { value: 1, label: '1 CPU E5-26xx v1' },
  { value: 2, label: '2 CPU E5-26xx v1' },
  { value: 3, label: '1 CPU E5-26xx v2' },
  { value: 4, label: '2 CPU E5-26xx v2 (600,000 đ 01 năm)' },
  { value: 5, label: '1 CPU E5-26xx v3 (1,200,000 đ 01 năm)' },
  { value: 6, label: '2 CPU E5-26xx v3' },
  { value: 7, label: '1 CPU E5-26xx v4 (360,000 đ 01 năm)' },
  { value: 8, label: '2 CPU E5-26xx v4 (720,000 đ 01 năm)' },
];
export const osOptions = [
  { value: 1, label: 'centos-7-x86_64-minimal' },
  { value: 2, label: 'centos-8-x86_64-minimal' },
  { value: 3, label: 'ubuntu-14.04-x86_64-minimal' },
  { value: 4, label: 'ubuntu-16-x86_64-minimal' },
  { value: 5, label: 'ubuntu-18.00-x86_64-minimal' },
  { value: 6, label: 'debian-10.0-x86_64-minimal' },
  { value: 7, label: 'Windows Server 2012' },
  { value: 8, label: 'Windows Server 2016' },
  { value: 9, label: 'Windows 10' },
  { value: 10, label: 'Windows 7' },
];
export const panelOptions = [
  { value: 1, label: 'Không' },
  { value: 2, label: 'cPanel' },
  { value: 3, label: 'DirectAdmin' },
  { value: 4, label: 'Plesk' },
  { value: 5, label: 'AAPanel' },
  { value: 6, label: 'VestaCP' },
  { value: 7, label: 'CyberPanel' },
];
