import {
  LIMIT_MAX_DATE_TIME,
  LIMIT_MIN_DATE_TIME,
} from '_common/constants/common';
import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import moment from 'moment';
import { ForwardedRef, forwardRef, memo } from 'react';
import { Controller } from 'react-hook-form';
import customTwMerge from 'tailwind-merge.config';
import { RangePickerProps, RangePicker as RangePickerTera } from 'tera-dls';

interface IRangePickerProps extends RangePickerProps {
  onCustomChange?: (data) => void;
}

const RangePicker = (
  { onCustomChange, ...restProps }: IRangePickerProps,
  ref: ForwardedRef<unknown>,
) => {
  const { form } = useTeraForm();
  const { item, config, rules } = useTeraFormItem();
  const { control } = form;

  return (
    <Controller
      control={control}
      defaultValue={null}
      {...item}
      rules={rules}
      render={({ field }) => (
        <RangePickerTera
          ref={ref}
          data-object_type={item?.object_type}
          data-object_id={item?.object_id}
          maxDate={moment(LIMIT_MAX_DATE_TIME)}
          minDate={moment(LIMIT_MIN_DATE_TIME)}
          {...field}
          {...restProps}
          onChange={(value, formatString) => {
            onCustomChange && onCustomChange(value);
            field.onChange && field.onChange(value);
            restProps.onChange && restProps.onChange(value, formatString);
          }}
          className={customTwMerge(
            'w-full',
            restProps?.className,
            config?.class_name,
          )}
        />
      )}
    />
  );
};

export default memo(forwardRef(RangePicker));
