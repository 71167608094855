const WindowIcon = (props) => (
  <svg
    width="146"
    height="166"
    viewBox="0 0 146 166"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_dd_13188_69143)">
      <path
        d="M20.2793 31.3834L73.0262 16L125.72 31.3834V62.9288C125.72 79.0957 120.629 94.8526 111.168 107.967C101.708 121.082 88.3575 130.889 73.0087 136C57.6552 130.89 44.3002 121.081 34.8363 107.964C25.3724 94.8463 20.2795 79.0853 20.2793 62.9141V31.3834Z"
        fill="#FAFAF9"
      />
    </g>
    <path
      d="M66.5094 58.1485C62.8014 59.4349 59.6037 61.8763 57.3859 65.1144C55.1681 68.3524 54.0471 72.2163 54.1875 76.1385C54.328 80.0607 55.7226 83.8345 58.1663 86.9056C60.6101 89.9767 63.9743 92.1831 67.7648 93.2009C70.8378 93.9939 74.0574 94.0287 77.1469 93.3024C79.9456 92.6737 82.5331 91.329 84.656 89.3999C86.8655 87.3309 88.4693 84.6987 89.2949 81.7864C90.1923 78.6194 90.352 75.2889 89.7617 72.0506H72.5051V79.2089H82.499C82.2992 80.3507 81.8712 81.4403 81.2405 82.4128C80.6098 83.3852 79.7894 84.2204 78.8285 84.8684C77.6081 85.6756 76.2324 86.2188 74.7897 86.463C73.3428 86.732 71.8587 86.732 70.4118 86.463C68.9453 86.1598 67.5581 85.5545 66.3383 84.6857C64.3788 83.2986 62.9075 81.3281 62.1344 79.0553C61.3481 76.7399 61.3481 74.2297 62.1344 71.9143C62.6847 70.2914 63.5945 68.8137 64.7959 67.5915C66.1707 66.1672 67.9113 65.1491 69.8266 64.649C71.742 64.1488 73.7581 64.1858 75.6537 64.756C77.1346 65.2106 78.4888 66.0048 79.6084 67.0754C80.7352 65.9544 81.8601 64.8304 82.9831 63.7036C83.563 63.0976 84.195 62.5207 84.7662 61.9002C83.0572 60.3099 81.0513 59.0724 78.8632 58.2587C74.8788 56.8119 70.519 56.773 66.5094 58.1485Z"
      fill="white"
    />
    <path
      d="M66.5288 58.1499C70.5381 56.7735 74.8978 56.8114 78.8826 58.2572C81.0711 59.0764 83.0761 60.3199 84.7827 61.9161C84.2028 62.5366 83.5911 63.1164 82.9996 63.7195C81.8747 64.8425 80.7507 65.9616 79.6277 67.0768C78.5082 66.0062 77.154 65.212 75.6731 64.7574C73.7781 64.1852 71.762 64.146 69.8462 64.6442C67.9303 65.1423 66.1887 66.1585 64.8124 67.5813C63.611 68.8035 62.7012 70.2812 62.1508 71.9042L56.1406 67.2508C58.2919 62.9847 62.0168 59.7214 66.5288 58.1499Z"
      fill="#E33629"
    />
    <path
      d="M54.5353 71.8481C54.8583 70.2471 55.3946 68.6967 56.1299 67.2383L62.1401 71.9032C61.3539 74.2186 61.3539 76.7288 62.1401 79.0442C60.1377 80.5905 58.1343 82.1445 56.1299 83.7062C54.2893 80.0424 53.7279 75.868 54.5353 71.8481Z"
      fill="#F8BD00"
    />
    <path
      d="M72.5228 72.0508H89.7793C90.3696 75.2891 90.2099 78.6196 89.3125 81.7866C88.4869 84.6989 86.8832 87.331 84.6737 89.4001C82.7341 87.8867 80.7857 86.3849 78.8461 84.8714C79.8077 84.2228 80.6285 83.3867 81.2592 82.4132C81.8899 81.4397 82.3176 80.349 82.5166 79.2062H72.5228C72.5199 76.823 72.5228 74.4369 72.5228 72.0508Z"
      fill="#587DBD"
    />
    <path
      d="M56.1035 83.7089C58.1079 82.1626 60.1113 80.6086 62.1137 79.0469C62.8884 81.3205 64.3619 83.2911 66.3235 84.6773C67.547 85.542 68.9373 86.1424 70.4057 86.4401C71.8526 86.7091 73.3367 86.7091 74.7836 86.4401C76.2263 86.1959 77.602 85.6527 78.8223 84.8454C80.7619 86.3589 82.7103 87.8607 84.6499 89.3741C82.5273 91.3043 79.9398 92.65 77.1407 93.2795C74.0513 94.0058 70.8317 93.9709 67.7586 93.178C65.3282 92.5291 63.058 91.3851 61.0903 89.8177C59.0076 88.1642 57.3066 86.0805 56.1035 83.7089Z"
      fill="#319F43"
    />
    <defs>
      <filter
        id="filter0_dd_13188_69143"
        x="0.279297"
        y="-14"
        width="145.441"
        height="180"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-10" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_13188_69143"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_13188_69143"
          result="effect2_dropShadow_13188_69143"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_13188_69143"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default WindowIcon;
