import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { messageValidate, messageWarning } from '_common/constants/message';
import Image from '_common/dof/Control/Image';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectArea from '_common/dof/Select/SelectArea';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Spin, notification } from 'tera-dls';
import CountryAPI from '../apis';

interface IModalModalCreateUpdateProps {
  open: boolean;
  close: () => void;
  id?: string | number;
}

const ModalCreateUpdate = ({
  open,
  close,
  id,
}: IModalModalCreateUpdateProps): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      avatar: '',
      countryCode: '',
      countryName: '',
      areaCode: '',
    },
  });
  const { isDirty } = form.formState;
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-package-country-detail', id],
    () => CountryAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  useEffect(() => {
    if (!_.isEmpty(dataDetail))
      form.reset({
        ...form.getValues(),
        avatar: dataDetail?.avatar_url,
        countryCode: dataDetail?.countryCode,
        countryName: dataDetail?.countryName,
        areaCode: dataDetail?.areaCode,
      });
  }, [dataDetail]);

  const { mutate: mutateCreateUpdate, isLoading: isLoadingMutate } =
    useMutation(
      (params) =>
        id ? CountryAPI.update({ id, params }) : CountryAPI.create({ params }),
      {
        onSuccess: (res) => {
          if (_.isEqual(res?.code, 200)) {
            notification.success({
              message: res?.msg,
            });
            queryClient.invalidateQueries(['get-service-package-country-list']);
            close();
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleSubmitForm = (values: any) => {
    if ((id && isLoadingDetail) || isLoadingMutate) return;
    mutateCreateUpdate(values);
  };

  return (
    <Modal
      title={`${id ? 'Sửa' : 'Thêm mới'} quốc gia`}
      open={open}
      centered={true}
      destroyOnClose
      okText="Đồng ý"
      cancelText="Hủy"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={() =>
        isDirty
          ? confirm.warning({
              title: 'Thoát bản ghi',
              content: (
                <>
                  <p>{messageWarning.WARNING_EXIT_1}</p>
                  <p>{messageWarning.WARNING_EXIT_2}</p>
                </>
              ),
              onOk: close,
            })
          : close()
      }
      className="md:w-[500px]"
      bodyClassName="!py-4"
      confirmLoading={(id && isLoadingDetail) || isLoadingMutate}
    >
      <Spin spinning={(id && isLoadingDetail) || isLoadingMutate}>
        {id && isErrorDetail ? (
          <NoData />
        ) : (
          <FormTera form={form} className="flex flex-col gap-2">
            <FormTeraItem
              label="Cờ quốc gia"
              name="avatar"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <Image folder="country" object_key="country" />
            </FormTeraItem>
            <FormTeraItem
              label="Mã quốc gia"
              name="countryCode"
              className="mb-0"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem
              label="Tên quốc gia"
              name="countryName"
              className="mb-0"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem
              label="Khu vực"
              name="areaCode"
              className="mb-0"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <SelectArea allowClear={false} />
            </FormTeraItem>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
};

export default ModalCreateUpdate;
