import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const OSAPIEndpoint = `${endpoint}/admin/operating-system`;

const OSAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${OSAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${OSAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api.post(`${OSAPIEndpoint}/create`, params).then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${OSAPIEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  active: async ({ id }) =>
    await api.put(`${OSAPIEndpoint}/active/${id}`).then((res) => res.data),
  deactivate: async ({ id }) =>
    await api.put(`${OSAPIEndpoint}/deactivate/${id}`).then((res) => res.data),
  delete: async ({ id }) =>
    await api.delete(`${OSAPIEndpoint}/delete/${id}`).then((res) => res.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${OSAPIEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
};

export default OSAPI;
