import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const AccountKYCAPIEndpoint = `${endpoint}/admin/account/kyc`;

const AccountKYCAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${AccountKYCAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${AccountKYCAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  active: async ({ id }) =>
    await api
      .put(`${AccountKYCAPIEndpoint}/active/${id}`)
      .then((res) => res.data),
  deactivate: async ({ id }) =>
    await api
      .put(`${AccountKYCAPIEndpoint}/deactivate/${id}`)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${AccountKYCAPIEndpoint}/delete/${id}`)
      .then((res) => res.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${AccountKYCAPIEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
};

export default AccountKYCAPI;
