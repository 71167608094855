import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const UserAccountAPIEndpoint = `${endpoint}/admin/account`;
const UserAccountPortalAPIEndpoint = `${endpoint}/portal/account`;

const UserAccountAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${UserAccountAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${UserAccountAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${UserAccountAPIEndpoint}/create`, params)
      .then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${UserAccountAPIEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  active: async ({ id }) =>
    await api
      .put(`${UserAccountAPIEndpoint}/active/${id}`)
      .then((res) => res.data),
  deactivate: async ({ id }) =>
    await api
      .put(`${UserAccountAPIEndpoint}/deactivate/${id}`)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${UserAccountAPIEndpoint}/delete/${id}`)
      .then((res) => res.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${UserAccountAPIEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),

  updateSetting: async ({ id, params }) =>
    await api
      .put(
        `${UserAccountPortalAPIEndpoint}/update/${id}/update-setting`,
        params,
      )
      .then((res) => res.data),
};

export default UserAccountAPI;
