import CardDetail from '_common/component/CardDetail';
import HoverQuickView from '_common/component/HoverQuickView';
import Text from '_common/component/Text';
import TextNavigate from '_common/component/TextNavigate';
import { DATE_TIME_FORMAT, GENDER } from '_common/constants/common';
import { USER_ACCOUNT_URL } from 'pages/UserManagement/Account/url';
import { Col, Input, Row, Tag, formatDate } from 'tera-dls';
import { TABLE_STATUS_COLOR, TABLE_STATUS_TEXT } from '../../contants';

interface IDetailContentProps {
  dataDetail: any;
}

const DetailContent = ({ dataDetail }: IDetailContentProps): JSX.Element => {
  const detail = [
    {
      label: 'Người dùng',
      value: (
        <HoverQuickView
          avatarUrl={dataDetail?.account.user.avatar}
          name={dataDetail?.account.user.full_name}
          code={dataDetail?.account.id}
          email={dataDetail?.account.email}
          phone={dataDetail?.account.phone}
        >
          <TextNavigate
            to={`${USER_ACCOUNT_URL.detail.path}/${dataDetail?.account.id}`}
          >
            {dataDetail?.account.user.full_name}
          </TextNavigate>
        </HoverQuickView>
      ),
    },
    {
      label: 'Ngày tạo',
      value: formatDate(dataDetail?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Trạng thái',
      value: (
        <Tag
          color={TABLE_STATUS_COLOR[dataDetail?.status]}
          className="font-[500]"
        >
          {TABLE_STATUS_TEXT[dataDetail?.status]}
        </Tag>
      ),
    },
  ];

  return (
    <CardDetail className="p-4 flex flex-col gap-4">
      <div className="pb-4 border-b-[2px] border-dashed">
        <span className="text-blue-500">Thông tin chung</span>
        <div className="p-4 flex flex-col gap-1">
          {detail.map((detail, index) => (
            <div key={index} className="py-3 border-b-[1px] flex items-center">
              <span className="flex-1 text-gray-500">{detail.label}</span>
              <span className="flex-1">{detail.value}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="pb-4 mt-2 flex flex-col gap-4">
        <span className="text-blue-500">Thông tin KYC</span>
        <Row className="grid grid-cols-2 gap-8">
          <Col className="flex flex-col gap-8">
            <CardDetail className="p-4 flex flex-col gap-4">
              <Text className="text-base font-medium">
                1. Ảnh CMT/CCCD mặt trước
              </Text>
              <Row className="w-full flex justify-center">
                <Row className="w-[500px] h-[250px] flex justify-center">
                  <img
                    src={dataDetail?.front_card_url}
                    alt="Citizen ID Card Image Front"
                    className="object-contain"
                  />
                </Row>
              </Row>
            </CardDetail>
            <CardDetail className="p-4 flex flex-col gap-4">
              <Text className="text-base font-medium">
                2. Ảnh CMT/CCCD mặt sau
              </Text>
              <Row className="w-full flex justify-center">
                <Row className="w-[500px] h-[250px] flex justify-center">
                  <img
                    src={dataDetail?.back_card_url}
                    alt="Citizen ID Card Image Back"
                    className="object-contain"
                  />
                </Row>
              </Row>
            </CardDetail>
            <CardDetail className="p-4 flex flex-col gap-4">
              <Text className="text-base font-medium">3. Ảnh khuôn mặt</Text>
              <Row className="w-full flex justify-center">
                <Row className="w-[500px] h-[250px] flex justify-center">
                  <img
                    src={dataDetail?.face_image_url}
                    alt="Face Image"
                    className="object-contain"
                  />
                </Row>
              </Row>
            </CardDetail>
          </Col>
          <Col className="flex flex-col gap-8">
            <CardDetail className="p-4 flex flex-col gap-4">
              <Text className="text-base font-medium">Thông tin mặt trước</Text>
              <table cellPadding={6}>
                <tbody>
                  <tr className="align-text-top">
                    <td width={115}>
                      Số CCCD <span className="text-red-500">*</span>
                    </td>
                    <td>
                      <Input defaultValue={dataDetail?.id_number} disabled />
                    </td>
                    <td width={115}>
                      Họ và tên <span className="text-red-500">*</span>
                    </td>
                    <td>
                      <Input defaultValue={dataDetail?.full_name} disabled />
                    </td>
                  </tr>
                  <tr className="align-text-top">
                    <td width={115}>
                      Ngày sinh <span className="text-red-500">*</span>
                    </td>
                    <td>
                      <Input
                        defaultValue={formatDate(dataDetail?.birthday)}
                        disabled
                      />
                    </td>
                    <td width={115}>
                      Giới tính <span className="text-red-500">*</span>
                    </td>
                    <td>
                      <Input
                        defaultValue={GENDER[dataDetail?.gender]}
                        disabled
                      />
                    </td>
                  </tr>
                  <tr className="align-text-top">
                    <td width={115}>
                      Nơi thường trú <span className="text-red-500">*</span>
                    </td>
                    <td colSpan={3}>
                      <Input defaultValue={dataDetail?.residence} disabled />
                    </td>
                  </tr>
                  <tr className="align-text-top">
                    <td width={115}>
                      Mã phường <span className="text-red-500">*</span>
                    </td>
                    <td>
                      <Input defaultValue={dataDetail?.ward_code} disabled />
                    </td>
                    <td width={115}>
                      Mã quận <span className="text-red-500">*</span>
                    </td>
                    <td>
                      <Input
                        defaultValue={dataDetail?.district_code}
                        disabled
                      />
                    </td>
                  </tr>
                  <tr className="align-text-top">
                    <td width={115}>
                      Mã tỉnh <span className="text-red-500">*</span>
                    </td>
                    <td>
                      <Input
                        defaultValue={dataDetail?.province_code}
                        disabled
                      />
                    </td>
                    <td width={115}>
                      <Text>
                        Ngày hết hạn <span className="text-red-500">*</span>
                      </Text>
                    </td>
                    <td>
                      <Input
                        defaultValue={formatDate(dataDetail?.date_of_expiry)}
                        disabled
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardDetail>
            <CardDetail className="p-4 flex flex-col gap-4">
              <Text className="text-base font-medium">Thông tin mặt sau</Text>
              <table cellPadding={6}>
                <tbody>
                  <tr className="align-text-top">
                    <td width={115}>
                      Cấp bởi <span className="text-red-500">*</span>
                    </td>
                    <td>
                      <Input defaultValue={dataDetail?.provided_by} disabled />
                    </td>
                  </tr>
                  <tr>
                    <td width={115}>
                      Ngày cấp <span className="text-red-500">*</span>
                    </td>
                    <td>
                      <Input
                        defaultValue={formatDate(dataDetail?.provided_at)}
                        disabled
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardDetail>
          </Col>
        </Row>
      </div>
    </CardDetail>
  );
};

export default DetailContent;
