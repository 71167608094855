export enum INVOICE_STATUS {
  UNPAID = 'Unpaid',
  PROCESSING = 'Processing',
  PAID = 'Paid',
  FAILED = 'Failed',
}
export enum TRANSACTION_STATUS {
  ALL = 'all',
  PROCESSING = 'Processing',
  PAID = 'Paid',
  FAILED = 'Failed',
  ERROR = 'Error',
}
export enum TRANSACTION_TYPE {
  PAY = 'Pay',
  DEPOSIT = 'Deposit',
  WITHDRAWAL = 'Withdrawal',
}
