import { IRouteProps } from '_common/interface/router';
import NotificationDetail from '.';
import { NOTIFICATION_DETAIL_URL } from './url';

export const NotificationDetailRouter: IRouteProps[] = [
  {
    key: NOTIFICATION_DETAIL_URL.list.key,
    path: NOTIFICATION_DETAIL_URL.list.shortenUrl,
    component: <NotificationDetail />,
  },
];
