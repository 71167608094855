const EyeHidden = (props) => (
  <svg
    width="24"
    height="21"
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.8145 6.54545L15.2727 9.99273V9.81818C15.2727 8.9502 14.9279 8.11777 14.3142 7.50401C13.7004 6.89026 12.868 6.54545 12 6.54545H11.8145ZM7.12364 7.41818L8.81455 9.10909C8.76 9.33818 8.72727 9.56727 8.72727 9.81818C8.72727 10.6862 9.07208 11.5186 9.68583 12.1323C10.2996 12.7461 11.132 13.0909 12 13.0909C12.24 13.0909 12.48 13.0582 12.7091 13.0036L14.4 14.6945C13.6691 15.0545 12.8618 15.2727 12 15.2727C10.5534 15.2727 9.16598 14.6981 8.14305 13.6751C7.12013 12.6522 6.54545 11.2648 6.54545 9.81818C6.54545 8.95636 6.76364 8.14909 7.12364 7.41818ZM1.09091 1.38545L3.57818 3.87273L4.06909 4.36364C2.26909 5.78182 0.850909 7.63636 0 9.81818C1.88727 14.6073 6.54545 18 12 18C13.6909 18 15.3055 17.6727 16.7782 17.0836L17.2473 17.5418L20.4327 20.7273L21.8182 19.3418L2.47636 0M12 4.36364C13.4466 4.36364 14.834 4.93831 15.8569 5.96124C16.8799 6.98416 17.4545 8.37155 17.4545 9.81818C17.4545 10.5164 17.3127 11.1927 17.0618 11.8036L20.2582 15C21.8945 13.6364 23.2036 11.8473 24 9.81818C22.1127 5.02909 17.4545 1.63636 12 1.63636C10.4727 1.63636 9.01091 1.90909 7.63636 2.4L10.0036 4.74545C10.6255 4.50545 11.2909 4.36364 12 4.36364Z"
      fill="#D1D5DB"
    />
  </svg>
);
export default EyeHidden;
