const Eye = (props) => (
  <svg
    width="24"
    height="17"
    viewBox="0 0 24 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 5.37784C11.132 5.37784 10.2996 5.72264 9.68583 6.3364C9.07208 6.95016 8.72727 7.78259 8.72727 8.65057C8.72727 9.51855 9.07208 10.351 9.68583 10.9647C10.2996 11.5785 11.132 11.9233 12 11.9233C12.868 11.9233 13.7004 11.5785 14.3142 10.9647C14.9279 10.351 15.2727 9.51855 15.2727 8.65057C15.2727 7.78259 14.9279 6.95016 14.3142 6.3364C13.7004 5.72264 12.868 5.37784 12 5.37784ZM12 14.1051C10.5534 14.1051 9.16598 13.5304 8.14305 12.5075C7.12013 11.4846 6.54545 10.0972 6.54545 8.65057C6.54545 7.20393 7.12013 5.81655 8.14305 4.79362C9.16598 3.7707 10.5534 3.19602 12 3.19602C13.4466 3.19602 14.834 3.7707 15.8569 4.79362C16.8799 5.81655 17.4545 7.20393 17.4545 8.65057C17.4545 10.0972 16.8799 11.4846 15.8569 12.5075C14.834 13.5304 13.4466 14.1051 12 14.1051ZM12 0.46875C6.54545 0.46875 1.88727 3.86148 0 8.65057C1.88727 13.4397 6.54545 16.8324 12 16.8324C17.4545 16.8324 22.1127 13.4397 24 8.65057C22.1127 3.86148 17.4545 0.46875 12 0.46875Z"
      fill="#D1D5DB"
    />
  </svg>
);
export default Eye;
