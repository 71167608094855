import { IRouteProps } from '_common/interface/router';
import ListPage from '.';
import DetailPage from './pages/Detail';
import { FINANCE_TRANSACTION_HISTORY_URL } from './url';

export const FinanceTransactionHistoryRouter: IRouteProps[] = [
  {
    key: FINANCE_TRANSACTION_HISTORY_URL.list.key,
    path: FINANCE_TRANSACTION_HISTORY_URL.list.shortenUrl,
    component: <ListPage />,
  },
  {
    key: FINANCE_TRANSACTION_HISTORY_URL.detail.key,
    path: FINANCE_TRANSACTION_HISTORY_URL.detail.shortenUrl,
    component: <DetailPage />,
  },
];
