import { useQuery } from '@tanstack/react-query';
import CardDetail from '_common/component/CardDetail';
import NoData from '_common/component/NoData';
import _ from 'lodash';
import AccountInformation from 'pages/UserManagement/_common/components/AccountInformation';
import ChangePassword from 'pages/UserManagement/_common/components/ChangePassword';
import History from 'pages/UserManagement/_common/components/History';
import Overview from 'pages/UserManagement/_common/components/Overview';
import Security from 'pages/UserManagement/_common/components/Security';
import SetupSecurity from 'pages/UserManagement/_common/components/SetupSecurity';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Col,
  Spin,
  Tabs,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import UserAccountAPI from '../../apis';
import { USER_ACCOUNT_URL } from '../../url';
import Bank from './components/Bank';
import Invoice from './components/Invoice';

const DetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-user-account-detail', id],
    () => UserAccountAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Người dùng
        </span>
      ),
      onClick: () => navigate(USER_ACCOUNT_URL.list.path),
    },
    {
      title: 'Chi tiết Người dùng',
    },
  ];

  const handleUpdateQueryParams = (data: any) => {
    delete queryParams.subTab;

    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleChangeTab = (key: string) => {
    handleUpdateQueryParams({ tab: key });
  };

  const tabItems = [
    {
      key: 'overview',
      label: 'Tổng quan',
    },
    {
      key: 'information',
      label: 'Thông tin tài khoản',
    },
    {
      key: 'change-password',
      label: 'Thay đổi mật khẩu',
    },
    {
      key: 'security',
      label: 'Bảo mật',
    },
    {
      key: 'setting-security',
      label: 'Thiết lập bảo mật',
    },
    {
      key: 'history',
      label: 'Lịch sử',
    },
    {
      key: 'invoice',
      label: 'Hóa đơn',
    },
    {
      key: 'bank',
      label: 'Thẻ ngân hàng',
    },
  ];

  const tabData = {
    ['overview']: <Overview dataDetail={dataDetail} />,
    ['information']: <AccountInformation dataDetail={dataDetail} />,
    ['change-password']: <ChangePassword dataDetail={dataDetail} />,
    ['security']: <Security dataDetail={dataDetail} />,
    ['setting-security']: <SetupSecurity dataDetail={dataDetail} />,
    ['history']: <History />,
    ['invoice']: <Invoice dataDetail={dataDetail} />,
    ['bank']: <Bank dataDetail={dataDetail} />,
  };

  return (
    <Col className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowSmallLeftSolid className="size-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
      </div>
      {isErrorDetail || _.isEmpty(dataDetail) ? (
        <NoData />
      ) : (
        <Spin spinning={isLoadingDetail}>
          <Col className="p-2">
            <CardDetail className="p-4 flex flex-col gap-4">
              <Tabs
                onChange={handleChangeTab}
                items={tabItems}
                activeKey={queryParams?.tab || tabItems[0].key}
                className="mb-0 pt-0 bg-white rounded-t-md"
              />
              {tabData[queryParams?.tab || tabItems[0].key]}
            </CardDetail>
          </Col>
        </Spin>
      )}
    </Col>
  );
};

export default DetailPage;
