import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  Button,
  PaginationProps,
  PlusOutlined,
  TabItemType,
  Tabs,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import CountryAPI from './apis';
import Search from './components/Search';
import Table from './components/Table';
import { TABS_STATUS_ITEM, TABS_STATUS_TEXT } from './contants';
import ModalCreateUpdate from './modals/ModalCreateUpdate';
import { SERVICE_PACKAGE_COUNTRY_URL } from './url';

const ListPage = (): JSX.Element => {
  const [isOpenModalCreateUpdate, setIsOpenModalCreateUpdate] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateFilter = (values: any) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...values,
    });
    navigate(`${SERVICE_PACKAGE_COUNTRY_URL.list.path}${paramString}`);
  };

  const handleChangeTab = (key: string) => {
    handleUpdateFilter({ active: key, page: 1 });
  };

  const handleSearch = (values: any) => {
    handleUpdateFilter({ keyword: values?.keyword, page: 1 });
  };

  const {
    data: dataList,
    refetch: refetchDataList,
    isLoading: isLoadingDataList,
  } = useQuery(
    ['get-service-package-country-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        active: queryParams?.active === 'all' ? undefined : queryParams?.active,
      };
      return CountryAPI.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateFilter({ page: data?.last_page });
        }
      },
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(dataList?.limit || 10);
    handleUpdateFilter({ page: isDiffPageSize ? 1 : page, limit: pageSize });
  };

  const { data: summaryList, refetch: refetchSummary } = useQuery(
    ['get-summary-service-cloud-vps-list', queryParams],
    () => {
      delete queryParams.active;

      return CountryAPI.getSummary({
        params: {
          ...queryParams,
          page: queryParams?.page || 1,
          limit: queryParams?.limit || 10,
        },
      });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const tabItems: Array<TabItemType> = useMemo(
    () =>
      TABS_STATUS_ITEM.map((item) => {
        const count =
          summaryList?.find((i) => String(i?.active) == item)?.total_count || 0;

        return {
          key: item,
          label: (
            <div className="flex items-center gap-x-2">
              {TABS_STATUS_TEXT[item]} <Badge count={count} showZero />
            </div>
          ),
        };
      }),
    [summaryList],
  );

  useEffect(() => {
    refetchDataList();
    refetchSummary();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách Quốc gia"
        actionRight={
          <div className="flex gap-2">
            <Button
              prefix={<PlusOutlined />}
              className="bg-blue-500"
              onClick={() => setIsOpenModalCreateUpdate(true)}
            >
              Thêm mới
            </Button>
          </div>
        }
        middleContent={
          <Tabs
            onChange={handleChangeTab}
            items={tabItems}
            activeKey={queryParams?.active || 'all'}
            className="mb-0"
          />
        }
        actionLeftRender={<Search onSearch={handleSearch} />}
      >
        <Table
          rowKey={'id'}
          data={dataList?.data || []}
          loading={isLoadingDataList}
          pagination={{
            onChange: handleChangePage,
            total: dataList?.total || 0,
            current: dataList?.current_page,
            pageSize: dataList?.per_page || 10,
            to: dataList?.to,
            from: dataList?.from || 10,
          }}
        />
      </HeaderViewList>
      {isOpenModalCreateUpdate && (
        <ModalCreateUpdate
          open={isOpenModalCreateUpdate}
          close={() => setIsOpenModalCreateUpdate(false)}
        />
      )}
    </div>
  );
};

export default ListPage;
