export const INVOICE_URL = {
  list: {
    key: '',
    path: '/invoice/invoice/list',
    shortenUrl: 'invoice/list',
  },
  detail: {
    key: '',
    path: '/invoice/invoice/detail',
    shortenUrl: 'invoice/detail/:id',
  },
};
