import { useMutation, useQuery } from '@tanstack/react-query';
import FormCustomer from '_common/component/Form/Customer';
import FormPromoCode from '_common/component/Form/PromoCode';
import NoData from '_common/component/NoData';
import { messageWarning } from '_common/constants/message';
import FormTera from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  Spin,
  notification,
} from 'tera-dls';
import DomainAPI from '../../apis';
import { DOMAIN_STATUS } from '../../constants';
import { SERVICE_DOMAIN_URL } from '../../url';
import FormCreateUpdate from './components/FormCreateUpdate';

const CreateUpdatePage = (): JSX.Element => {
  const { id } = useParams();
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      domainLookup: '',
      name: '',
      label: '',
      tld: '.vn',
      status: DOMAIN_STATUS.NEW,
      period: '1',
      periods: [],
      installation_fee: 0,
      domain_pricing: [],
      first_payment: 0,
      recurring_amount: 0,
      idprotection: 1,
      autorenew: 1,
      registrant: '',
      promo_code: '',
      promo_total: 0,
    },
  });
  const { isDirty } = form.formState;
  const confirm = useConfirm();
  const navigate = useNavigate();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Tên miền
        </span>
      ),
      onClick: () =>
        isDirty
          ? confirm.warning({
              title: 'Thoát bản ghi',
              content: (
                <>
                  <p>{messageWarning.WARNING_EXIT_1}</p>
                  <p>{messageWarning.WARNING_EXIT_2}</p>
                </>
              ),
              onOk: () => navigate(SERVICE_DOMAIN_URL.list.path),
            })
          : navigate(SERVICE_DOMAIN_URL.list.path),
    },
    {
      title: `${id ? 'Sửa' : 'Thêm'} Tên miền`,
    },
  ];

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-domain-detail', id],
    () => DomainAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  useEffect(() => {
    if (!_.isEmpty(dataDetail))
      form.reset({
        ...form.getValues(),
        name: dataDetail?.name,
        label: dataDetail?.label,
        status: dataDetail?.status,
        period: dataDetail?.period,
        idprotection: dataDetail?.idprotection,
        autorenew: dataDetail?.autorenew,
        registrant: dataDetail?.registrant.id,
      });
  }, [dataDetail]);

  useEffect(() => {
    const domainPricing = form
      .watch('periods')
      ?.find((period) => period.period == form.watch('period'));

    form.reset({
      ...form.getValues(),
      domain_pricing: domainPricing,
      first_payment: domainPricing?.register,
      recurring_amount: domainPricing?.register,
    });
  }, [form.watch('period')]);

  const { mutate: mutateCreateUpdate, isLoading: isLoadingCreateUpdate } =
    useMutation(
      (params: any) =>
        id ? DomainAPI.update({ id, params }) : DomainAPI.create({ params }),
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            notification.success({
              message: res?.msg,
            });
            navigate(-1);
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.data.errors[0],
          }),
      },
    );

  const handleSubmitForm = (values: any) => {
    if (isLoadingCreateUpdate) return;
    mutateCreateUpdate({
      name: values.name,
      label: values.label,
      autorenew: values.autorenew,
      period: values.period,
      idprotection: values.idprotection,
      recurring_amount: values.recurring_amount,
      first_payment: values.first_payment,
      promo_total: values.promo_total,
      registrant: values.registrant,
    });
  };

  return (
    <Spin spinning={isLoadingCreateUpdate || (!_.isNil(id) && isLoadingDetail)}>
      <div className="tera-page-form min-h-[calc(100vh_-_45px)]">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header__breadcrumb-back cursor-pointer"
                onClick={items[items.length - 2].onClick}
              >
                <ArrowSmallLeftSolid className="h-6 w-6" />
              </div>
              <Breadcrumb separator={'/'} items={items} />
            </div>
            <div className="page-header-v2__function">
              <Button
                className="bg-blue-500"
                onClick={() => form.handleSubmit(handleSubmitForm)()}
                disabled={_.isEmpty(form.watch('domainLookup'))}
              >
                Lưu
              </Button>
            </div>
          </div>
        </div>
        {id && (isErrorDetail || _.isEmpty(dataDetail)) ? (
          <NoData />
        ) : (
          <FormTera form={form} className="flex-1 p-2 flex flex-col gap-4">
            <FormCreateUpdate form={form} id={id} />
            <FormCustomer form={form} />
            <FormPromoCode />
          </FormTera>
        )}
      </div>
    </Spin>
  );
};

export default CreateUpdatePage;
