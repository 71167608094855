import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { messageValidate, messageWarning } from '_common/constants/message';
import InputNumber from '_common/dof/Control/InputNumber';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Spin, notification } from 'tera-dls';
import AffiliatesAPI from '../../_api';

interface ModalProps {
  open?: boolean;
  onClose?: () => void;
  onRefetch?: () => void;
  value: number;
}

function ModalEditPercentageForm({ open, onClose, value: id }: ModalProps) {
  const form = useForm();
  const {
    formState: { isDirty },
    setValue,
  } = form;
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { data: detail } = useQuery(
    ['get-publisher-detail', id],
    () => {
      const data = {
        id,
      };
      return AffiliatesAPI.getDetail(data);
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    if (!detail) return;
    setValue('commission_percentage', detail?.commission_percentage ?? 0);
  }, [detail]);

  const { mutate: mutateUpdate, isLoading } = useMutation(
    (variables: any) => AffiliatesAPI.update(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-publisher-list']);
          onClose();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'THOÁT BẢN GHI',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => onClose(),
      });
    } else onClose();
  };

  const handleSubmitForm = (values: any) => {
    mutateUpdate({ id, params: values });
  };

  return (
    <Modal
      closeIcon={false}
      open={open}
      okText="Đồng ý"
      cancelText="Hủy"
      title={'Sửa % hoa hồng'}
      width={500}
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
    >
      <Spin spinning={isLoading}>
        <FormTera form={form} onSubmit={handleSubmitForm}>
          <FormTeraItem
            label="Phần trăm hoa hồng"
            name="commission_percentage"
            rules={[
              {
                required: messageValidate?.emptyText,
              },
            ]}
          >
            <InputNumber
              suffix={<span>%</span>}
              min={0}
              max={100}
              allowStepHandler={false}
            />
          </FormTeraItem>
        </FormTera>
      </Spin>
    </Modal>
  );
}

export default ModalEditPercentageForm;
