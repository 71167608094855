import { IRouteProps } from '_common/interface/router';
import CashReceipt from '.';
import ExpenseVoucherForm from './containers/Form';
import { EXPENSE_VOUCHER_URL } from './url';

export const InvoicePaymentRouter: IRouteProps[] = [
  {
    key: EXPENSE_VOUCHER_URL.list.key,
    path: EXPENSE_VOUCHER_URL.list.shortenUrl,
    component: <CashReceipt />,
  },
  {
    key: EXPENSE_VOUCHER_URL.create.key,
    path: EXPENSE_VOUCHER_URL.create.shortenUrl,
    component: <ExpenseVoucherForm />,
  },
  {
    key: EXPENSE_VOUCHER_URL.update.key,
    path: EXPENSE_VOUCHER_URL.update.shortenUrl,
    component: <ExpenseVoucherForm />,
  },
];
