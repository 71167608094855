import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CountryAPIEndpoint = `${endpoint}/admin/country`;
const CountryPortalAPIEndpoint = `${endpoint}/portal/country`;

const CountryAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${CountryAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getAreaList: async ({ params }) =>
    await api
      .get(`${CountryPortalAPIEndpoint}/area/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${CountryAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${CountryAPIEndpoint}/create`, params)
      .then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${CountryAPIEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  active: async ({ id }) =>
    await api.put(`${CountryAPIEndpoint}/active/${id}`).then((res) => res.data),
  deactivate: async ({ id }) =>
    await api
      .put(`${CountryAPIEndpoint}/deactivate/${id}`)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${CountryAPIEndpoint}/delete/${id}`)
      .then((res) => res.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${CountryAPIEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
};

export default CountryAPI;
