import { useMutation, useQueryClient } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TextNavigate from '_common/component/TextNavigate';
import { TRANSACTION_STATUS } from '_common/constants/finance';
import {
  PAYMENT_METHOD_TEXT,
  PAYMENT_METHOD_TYPE,
  TRANSACTION_TYPE_TEXT,
} from '_common/constants/invoiceManagement';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { INVOICE_URL } from 'pages/Finance/InvoiceManagement/Invoice/url';
import { USER_ACCOUNT_URL } from 'pages/UserManagement/Account/url';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  Tag,
  formatCurrency,
  formatDate,
  notification,
} from 'tera-dls';
import TransactionAPI from '../apis';
import { TABLE_STATUS_COLOR, TABLE_STATUS_TEXT } from '../contants';
import { FINANCE_TRANSACTION_HISTORY_URL } from '../url';

const Table = (props: ITeraTableProps): JSX.Element => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdateStatus } =
    useMutation(
      (params: any) => {
        if (_.isEqual(params.status, TRANSACTION_STATUS.FAILED))
          return TransactionAPI.failure({ id: params?.id });
        if (_.isEqual(params.status, TRANSACTION_STATUS.ERROR))
          return TransactionAPI.error({ id: params?.id });

        return TransactionAPI.approval({ id: params?.id });
      },
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            notification.success({
              message: res?.msg,
            });
            queryClient.invalidateQueries(['get-finance-transaction-list']);
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleConfirmUpdateStatus = (
    record: any,
    status: TRANSACTION_STATUS,
  ) => {
    const title = {
      [TRANSACTION_STATUS.PAID]: 'Xác nhận hoàn thành',
      [TRANSACTION_STATUS.FAILED]: 'Xác nhận thất bại',
      [TRANSACTION_STATUS.ERROR]: 'Xác nhận báo lỗi',
    };
    const description = {
      [TRANSACTION_STATUS.PAID]: 'xác nhận hoàn thành',
      [TRANSACTION_STATUS.FAILED]: 'xác nhận thất bại',
      [TRANSACTION_STATUS.ERROR]: 'xác nhận báo lỗi',
    };

    confirm.warning({
      title: title[status],
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn {description[status]} giao dịch</p>
          <p>
            <b>{record.transaction_no}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateUpdateStatus({ id: record.id, status: status }),
    });
  };

  const itemActions = (record: any): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () =>
          navigate(
            `${FINANCE_TRANSACTION_HISTORY_URL.detail.path}/${record.id}`,
          ),
      },
      ...(!_.isEqual(record.status, TRANSACTION_STATUS.PAID)
        ? [
            {
              key: '2',
              label: 'Hoàn thành',
              onClick: () =>
                handleConfirmUpdateStatus(record, TRANSACTION_STATUS.PAID),
            },
            {
              key: '3',
              label: 'Thất bại',
              onClick: () =>
                handleConfirmUpdateStatus(record, TRANSACTION_STATUS.FAILED),
            },
            {
              key: '4',
              label: 'Báo lỗi',
              onClick: () =>
                handleConfirmUpdateStatus(record, TRANSACTION_STATUS.ERROR),
            },
          ]
        : []),
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã giao dịch',
      dataIndex: 'transaction_no',
      width: 150,
      render: (transaction_no) => <TextNavigate>{transaction_no}</TextNavigate>,
    },
    {
      title: 'Mã hóa đơn',
      dataIndex: 'invoice',
      width: 150,
      render: (_, record) => (
        <TextNavigate to={`${INVOICE_URL.detail.path}/${record.bill?.id}`}>
          {record.bill?.invoice_no}
        </TextNavigate>
      ),
    },
    {
      title: 'Người dùng',
      dataIndex: 'account',
      width: 150,
      render: (_, record) => (
        <HoverQuickView
          avatarUrl={record.account?.user.avatar}
          name={record.account?.user.full_name}
          code={record.account?.id}
          email={record.account?.email}
          phone={record.account?.phone}
        >
          <TextNavigate
            to={`${USER_ACCOUNT_URL.detail.path}/${record.account?.id}`}
          >
            {record.account?.user.full_name}
          </TextNavigate>
        </HoverQuickView>
      ),
    },
    {
      title: 'Loại giao dịch',
      dataIndex: 'transaction_type',
      width: 150,
      render: (transaction_type) => TRANSACTION_TYPE_TEXT[transaction_type],
    },
    {
      title: 'Số tiền',
      dataIndex: 'total',
      width: 150,
      render: (total) => formatCurrency(total),
    },
    {
      title: 'Phương thức thanh toán',
      dataIndex: 'payment_method',
      width: 200,
      render: (payment_method, record) =>
        record.payment_method_id === 0
          ? PAYMENT_METHOD_TEXT[PAYMENT_METHOD_TYPE.WALLET]
          : PAYMENT_METHOD_TEXT[payment_method?.type.type],
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) => (
        <Tag className="font-medium" color={TABLE_STATUS_COLOR[status]}>
          {TABLE_STATUS_TEXT[status]}
        </Tag>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 150,
      render: (created_at) => formatDate(created_at),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record: any) => {
        return (
          <ActionDropdown dropdownItems={itemActions(record)} trigger="click" />
        );
      },
    },
  ];

  return (
    <TableTera
      rowKey="id"
      columns={columns}
      loading={props?.loading || isLoadingUpdateStatus}
      className="center-table"
      {...props}
    />
  );
};

export default Table;
