import { useQuery } from '@tanstack/react-query';
import CardDetail from '_common/component/CardDetail';
import NoData from '_common/component/NoData';
import { messageValidate } from '_common/constants/message';
import { FormTeraItem } from '_common/dof/FormTera';
import SelectCustomer from '_common/dof/Select/SelectCustomer';
import _ from 'lodash';
import UserAccountAPI from 'pages/UserManagement/Account/apis';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Row, Spin } from 'tera-dls';

interface IFormCustomerProps {
  form: UseFormReturn<any>;
}

const FormCustomer = ({ form }: IFormCustomerProps): JSX.Element => {
  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-user-account-detail', form.watch('registrant')],
    () => UserAccountAPI.getDetail({ id: form.watch('registrant') }),
    {
      enabled: !_.isEmpty(form.watch('registrant')),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (form.watch('registrant')) refetchDetail();
  }, [form.watch('registrant')]);

  const details = [
    { label: 'Tên', value: dataDetail?.first_name },
    { label: 'Họ và tên đệm', value: dataDetail?.last_middle_name },
    { label: 'Danh xưng', value: dataDetail?.gender },
    { label: 'Số CCCD/Passport', value: dataDetail?.citizen_id },
    { label: 'Email', value: dataDetail?.email },
    { label: 'Điện thoại', value: dataDetail?.phone },
    { label: 'Quốc gia', value: dataDetail?.country },
    { label: 'Tỉnh/Thành', value: dataDetail?.province },
    { label: 'Quận/Huyện', value: dataDetail?.district },
    { label: 'Phường/Xã', value: dataDetail?.ward },
    { label: 'Địa chỉ', value: dataDetail?.address },
    { label: 'Email nhận hóa đơn', value: dataDetail?.email },
  ];

  return (
    <CardDetail
      title="Thông tin khách hàng"
      className="w-full p-4 flex flex-col gap-4"
      titleClassName="mb-0"
    >
      <FormTeraItem
        name="registrant"
        label="Người dùng"
        className="mb-0"
        rules={[
          {
            required: messageValidate.emptySelect,
          },
        ]}
      >
        <SelectCustomer
          paramsApi={{ include_id: form.watch('registrant') }}
          allowClear={false}
        />
      </FormTeraItem>
      <Spin spinning={form.watch('registrant') && isLoadingDetail}>
        {isErrorDetail ? (
          <NoData />
        ) : (
          <Row className="grid grid-cols-2 gap-0">
            {details.map((detail, index) => (
              <div key={index} className="py-4 border-b-[1px]">
                <span className="w-[150px] text-gray-500 inline-block">
                  {detail.label}
                </span>
                <span>{detail.value}</span>
              </div>
            ))}
          </Row>
        )}
      </Spin>
    </CardDetail>
  );
};

export default FormCustomer;
