import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Tabs, getQueryParams, updateQueryParams } from 'tera-dls';
import SSHKeys from './SSHKeys';
import Secure from './Secure';
import Verify from './Verify';

interface ISecurityProps {
  dataDetail: any;
}

const Security = ({ dataDetail }: ISecurityProps) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data: any) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleChangeTab = (key: string) => {
    handleUpdateQueryParams({ subTab: key });
  };

  const tabItems = [
    {
      key: 'security',
      label: 'Bảo mật',
    },
    {
      key: 'ssh',
      label: 'SSH Keys',
    },
    {
      key: 'verify',
      label: 'Xác thực 2 bước',
    },
  ];

  const tabData = {
    security: <Secure dataDetail={dataDetail} />,
    ssh: <SSHKeys dataDetail={dataDetail} />,
    verify: <Verify />,
  };

  return (
    <Col className="flex flex-col gap-4">
      <Tabs
        onChange={handleChangeTab}
        items={tabItems}
        activeKey={queryParams?.subTab || tabItems[0].key}
        className="mb-0 pt-0 bg-white rounded-t-md"
      />
      {tabData[queryParams?.subTab || tabItems[0].key]}
    </Col>
  );
};

export default Security;
