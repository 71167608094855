import TeraFilter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import RangePicker from '_common/dof/Control/RangePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface FilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function Filter({ open, onClose, onFilter, initialValue }: FilterProps) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      date_created: null,
    },
  });

  useEffect(() => {
    const values = _.pick(initialValue, [
      'date_created_from',
      'date_created_to',
    ]);

    form.reset({
      ...values,
      date_created: values.date_created_from &&
        values.date_created_to && [
          moment(values.date_created_from),
          moment(values.date_created_to),
        ],
    });
  }, [initialValue]);

  const handleSubmitForm = (values: any) => {
    onFilter({
      ...values,
      date_created_from:
        values.date_created?.[0] &&
        values.date_created[0].format(DATE_BACKEND_FORMAT),
      date_created_to:
        values.date_created?.[1] &&
        values.date_created[1].format(DATE_BACKEND_FORMAT),
    });
    onClose();
  };

  const handleReset = () => {
    onFilter({
      date_created: null,
      date_created_from: null,
      date_created_to: null,
    });
    onClose();
  };

  return (
    <TeraFilter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera
        form={form}
        onSubmit={form.handleSubmit(handleSubmitForm)}
        className="flex flex-col gap-2"
      >
        <FormTeraItem label="Ngày tạo" name="date_created" className="mb-0">
          <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
        </FormTeraItem>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </TeraFilter>
  );
}

export default Filter;
