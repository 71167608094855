import { IRouteProps } from '_common/interface/router';
import ListPage from '.';
import CreateUpdatePage from './pages/CreateUpdate';
import DetailPage from './pages/Detail';
import { SERVICE_SSL_URL } from './url';

export const ServiceSSLRouter: IRouteProps[] = [
  {
    key: SERVICE_SSL_URL.list.key,
    path: SERVICE_SSL_URL.list.shortenUrl,
    component: <ListPage />,
  },
  {
    key: SERVICE_SSL_URL.create.key,
    path: SERVICE_SSL_URL.create.shortenUrl,
    component: <CreateUpdatePage />,
  },
  {
    key: SERVICE_SSL_URL.detail.key,
    path: SERVICE_SSL_URL.detail.shortenUrl,
    component: <DetailPage />,
  },
];
