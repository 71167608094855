export const SERVICE_CLOUD_SERVER_URL = {
  list: {
    key: '',
    path: '/service/cloud-server/list',
    shortenUrl: 'cloud-server/list',
  },
  create: {
    key: '',
    path: '/service/cloud-server/create',
    shortenUrl: 'cloud-server/create',
  },
  paymentInformation: {
    key: '',
    path: '/service/cloud-server/payment-information',
    shortenUrl: 'cloud-server/payment-information',
  },
  payment: {
    key: '',
    path: '/service/cloud-server/payment',
    shortenUrl: 'cloud-server/payment',
  },
  detail: {
    key: '',
    path: '/service/cloud-server/detail',
    shortenUrl: 'cloud-server/detail/:id',
  },
};
