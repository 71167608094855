import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { RadioProps, Radio as RadioTera } from 'tera-dls';

interface IProps extends RadioProps {
  listOptions: any[];
}

const RadioGroup = (
  { listOptions, ...props }: IProps,
  ref: ForwardedRef<unknown>,
) => {
  const { form } = useTeraForm();
  const { item, config, rules } = useTeraFormItem();
  const { control } = form;

  const inputProps = useMemo(
    () => ({
      className: config?.class_name || props?.className,
    }),
    [config],
  );

  return (
    <Controller
      control={control}
      rules={rules}
      {...item}
      render={({ field }) => {
        return (
          <RadioTera.Group
            ref={ref}
            data-object_type={item?.object_type}
            data-object_id={item?.object_id}
            {...props}
            {...field}
            {...inputProps}
          >
            {listOptions?.map((option) => (
              <RadioTera key={option?.value} value={option?.value}>
                {option?.label}
              </RadioTera>
            ))}
          </RadioTera.Group>
        );
      }}
    />
  );
};

export default forwardRef(RadioGroup);
