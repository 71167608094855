import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import { IOpenModalState } from '_common/interface';
import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { ColumnsType, DropdownItem, notification } from 'tera-dls';
import ModalDetail from './ModalDetail';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PaymentMethodAPI from 'pages/Finance/PaymentManagement/PaymentMethod/apis';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';

const Table = (props: ITeraTableProps): JSX.Element => {
  const [isOpenModalDetail, setIsOpenModalDetail] = useState<IOpenModalState>({
    isOpen: false,
    id: null,
  });
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (id: any) => PaymentMethodAPI.delete({ id }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries([
            'get-user-account-payment-method-list',
          ]);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleDelete = (record: any) =>
    confirm.warning({
      title: 'Xác nhận xóa phương thức thanh toán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa phương thức thanh toán</p>
          <p>
            <b>{record?.type.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateDelete(record?.id),
    });

  const itemActions = (record: any): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () => setIsOpenModalDetail({ isOpen: true, id: record.id }),
      },
      {
        key: '2',
        label: 'Sửa',
      },
      {
        key: '3',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      },
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Tên thẻ/ngân hàng',
      dataIndex: '1',
      width: 150,
      render: (_, record) => record.type.name,
    },
    {
      title: 'Tên đầy đủ',
      dataIndex: '2',
      width: 150,
      render: (_, record) => record.type.description,
    },
    {
      title: 'Người thụ hưởng',
      dataIndex: 'account_name',
      width: 150,
    },
    {
      title: 'STK/Số điện thoại',
      dataIndex: 'account_number',
      width: 150,
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        return (
          <ActionDropdown dropdownItems={itemActions(record)} trigger="click" />
        );
      },
    },
  ];

  return (
    <Fragment>
      <TableTera
        rowKey="id"
        columns={columns}
        loading={props?.loading || isLoadingDelete}
        className="center-table"
        {...props}
      />
      {isOpenModalDetail.isOpen && (
        <ModalDetail
          open={isOpenModalDetail.isOpen}
          close={() => setIsOpenModalDetail({ isOpen: false })}
          id={isOpenModalDetail.id}
        />
      )}
    </Fragment>
  );
};

export default Table;
