import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import InvoiceAPI from 'pages/Finance/InvoiceManagement/Invoice/apis';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Col,
  PaginationProps,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import Filter from './Filter';
import Search from './Search';
import Table from './Table';

interface IInvoiceTableProps {
  dataDetail: any;
}

const InvoiceTable = ({ dataDetail }: IInvoiceTableProps) => {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateFilter = (values: any) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...values,
    });
    navigate(`${location.pathname}${paramString}`);
  };

  const handleSearch = (values: any) => {
    handleUpdateFilter({ keyword: values?.keyword, page: 1 });
  };

  const handleFilter = (values: any) => {
    handleUpdateFilter({ ...values, page: 1 });
  };

  const {
    data: dataList,
    refetch: refetchDataList,
    isLoading: isLoadingDataList,
  } = useQuery(
    ['get-user-account-invoice-list', queryParams],
    () => {
      return InvoiceAPI.getList({
        params: {
          ...queryParams,
          account_id: dataDetail?.id,
          page: queryParams?.page || 1,
          limit: queryParams?.limit || 10,
        },
      });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateFilter({ page: data?.last_page });
        }
      },
    },
  );

  useEffect(() => {
    refetchDataList();
  }, []);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(dataList?.limit || 10);
    handleUpdateFilter({ page: isDiffPageSize ? 1 : page, limit: pageSize });
  };

  return (
    <Col className="flex justify-end flex-col shadow rounded-md gap-2">
      <HeaderViewList
        // middleContent={
        //   <Row className="w-full flex !justify-end">
        //     <Button
        //       prefix={<CreditCardOutlined className="size-4" />}
        //       className="bg-blue-500"
        //       onClick={handleConfirmPaymentAll}
        //     >
        //       Thanh toán tất cả
        //     </Button>
        //   </Row>
        // }
        onClickFilter={() => setIsOpenFilter(true)}
        actionLeftRender={<Search onSearch={handleSearch} />}
        filterCount={{
          filter: queryParams,
          params: [
            'transaction_type',
            'payment_method_type',
            'status',
            'created_at',
            'dueDate',
            'paidDate',
            'paidDate_to',
          ],
        }}
      >
        <Table
          rowKey={'id'}
          data={dataList?.data || []}
          loading={isLoadingDataList}
          pagination={{
            onChange: handleChangePage,
            total: dataList?.total || 0,
            current: dataList?.current_page,
            pageSize: dataList?.per_page || 10,
            to: dataList?.to,
            from: dataList?.from || 10,
          }}
        />
      </HeaderViewList>
      {isOpenFilter && (
        <Filter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </Col>
  );
};

export default InvoiceTable;
