import { IRouteProps } from '_common/interface/router';
import ListPage from '.';
import CreateUpdatePage from './pages/CreateUpdate';
import DetailPage from './pages/Detail';
import { SERVICE_EMAIL_SECURITY_URL } from './url';

export const ServiceEmailSecurityRouter: IRouteProps[] = [
  {
    key: SERVICE_EMAIL_SECURITY_URL.list.key,
    path: SERVICE_EMAIL_SECURITY_URL.list.shortenUrl,
    component: <ListPage />,
  },
  {
    key: SERVICE_EMAIL_SECURITY_URL.create.key,
    path: SERVICE_EMAIL_SECURITY_URL.create.shortenUrl,
    component: <CreateUpdatePage />,
  },
  {
    key: SERVICE_EMAIL_SECURITY_URL.detail.key,
    path: SERVICE_EMAIL_SECURITY_URL.detail.shortenUrl,
    component: <DetailPage />,
  },
];
