import { useMutation } from '@tanstack/react-query';
import CardDetail from '_common/component/CardDetail';
import NumberToWord from '_common/component/NumberToWord';
import Text from '_common/component/Text';
import { TRANSACTION_TYPE_TEXT } from '_common/constants/invoiceManagement';
import useConfirm from '_common/hooks/useConfirm';
import InvoiceAPI from 'pages/Finance/InvoiceManagement/Invoice/apis';
import {
  TABLE_STATUS_COLOR,
  TABLE_STATUS_TEXT,
} from 'pages/Finance/InvoiceManagement/Invoice/constants';
import { INVOICE_URL } from 'pages/Finance/InvoiceManagement/Invoice/url';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import zingServer from 'styles/images/zing-server.png';
import customTwMerge from 'tailwind-merge.config';
import {
  Button,
  Col,
  PrinterOutlined,
  Row,
  Spin,
  Tag,
  XMarkOutlined,
  formatCurrency,
  formatDate,
  formatNumber,
  notification,
} from 'tera-dls';

interface IInvoiceContentProps {
  dataDetail: any;
}

const cellClassName = 'border border-gray-700 px-2 py-2';

const InvoiceContent = ({ dataDetail }: IInvoiceContentProps) => {
  const confirm = useConfirm();
  const navigate = useNavigate();

  const serviceName = useMemo(
    () =>
      dataDetail?.order?.service
        ? dataDetail?.order?.service?.product?.planCode
        : dataDetail?.order?.domain?.name
        ? `Tên miền: ${dataDetail?.order?.domain?.name}`
        : TRANSACTION_TYPE_TEXT[dataDetail?.transaction_type],
    [dataDetail?.order?.service],
  );

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (params: any) => InvoiceAPI.delete({ id: params?.id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          navigate(INVOICE_URL.list.path);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleDelete = () => {
    confirm.warning({
      title: 'Xác nhận xóa hóa đơn',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa hóa đơn</p>
          <p>
            <span className="font-bold">{dataDetail.invoice_no}</span> này
            không?
          </p>
        </div>
      ),
      onOk: () =>
        mutateDelete({
          id: dataDetail.id,
        }),
    });
  };

  return (
    <Spin spinning={isLoadingDelete}>
      <Row className="flex justify-between">
        <Col className="flex flex-col gap-2.5">
          <p className="text-xl font-semibold">
            Hoá đơn {dataDetail?.invoice_no}
          </p>
          <Row className="flex gap-5 items-center font-normal">
            <Tag
              className="font-medium"
              color={TABLE_STATUS_COLOR[dataDetail?.status]}
            >
              {TABLE_STATUS_TEXT[dataDetail?.status]}
            </Tag>
            <span className="text-gray-600">
              {formatDate(dataDetail?.created_at)}
            </span>
          </Row>
        </Col>
        <Row className="flex gap-[16px] items-center">
          <Button
            prefix={<PrinterOutlined className="size-4" />}
            className="flex gap-[8px] font-light bg-gray-500 hover:bg-gray-700"
          >
            In hóa đơn
          </Button>
          <Button
            prefix={
              <svg
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="size-4"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 2.03726C0 1.60883 0.170192 1.19795 0.473135 0.89501C0.776079 0.592067 1.18696 0.421875 1.61538 0.421875L10.4537 0.421875L14 3.96818V14.9603C14 15.3888 13.8298 15.7996 13.5269 16.1026C13.2239 16.4055 12.813 16.5757 12.3846 16.5757H1.61538C1.18696 16.5757 0.776079 16.4055 0.473135 16.1026C0.170192 15.7996 0 15.3888 0 14.9603V2.03726ZM2.69231 6.88341H1.07692V12.268H2.15385V10.1142H2.69231C3.12073 10.1142 3.53161 9.94399 3.83456 9.64105C4.1375 9.33811 4.30769 8.92723 4.30769 8.4988C4.30769 8.07037 4.1375 7.65949 3.83456 7.35655C3.53161 7.05361 3.12073 6.88341 2.69231 6.88341ZM7 6.88341H5.38462V12.268H7C7.42843 12.268 7.83931 12.0978 8.14225 11.7949C8.44519 11.492 8.61539 11.0811 8.61539 10.6526V8.4988C8.61539 8.07037 8.44519 7.65949 8.14225 7.35655C7.83931 7.05361 7.42843 6.88341 7 6.88341ZM9.69231 12.268V6.88341H12.9231V7.96034H10.7692V9.03726H11.8462V10.1142H10.7692V12.268H9.69231Z"
                  fill="white"
                />
              </svg>
            }
            className="flex gap-[8px] font-light bg-gray-500 hover:bg-gray-700"
          >
            Tải PDF
          </Button>
          <Button
            prefix={<XMarkOutlined className="size-5" />}
            className="flex gap-[8px] font-light bg-gray-500 hover:bg-gray-700"
            onClick={handleDelete}
          >
            Xóa hóa đơn
          </Button>
        </Row>
      </Row>
      <CardDetail className="w-full p-10">
        <Col className="flex flex-col gap-[24px]">
          <header className="flex  items-center justify-start divide-x-[1px]">
            <img src={zingServer} className="h-[70px] rounded  pr-[30px]" />
            <Col className="flex pl-[30px] flex-col gap-[6px] ">
              <h2 className="text-gray-700 font-semibold">
                CÔNG TY CỔ PHẦN TẬP ĐOÀN ZINGSERVER
              </h2>
              <Text>
                <span className="text-gray-500">Mã số thuế: </span>
                <span className="font-medium"> 0315679836</span>
              </Text>
              <Text>
                <span className="text-gray-500">Địa chỉ: </span>
                Số 23A Nguyễn Trãi, Thành phố Hà Giang.
              </Text>
              <Text>
                <span className="text-gray-500">Điện thoại: </span>
                0909 123 456
              </Text>
              <Text>
                <span className="text-gray-500">Số tài khoản: </span>
                45637453 Tại Ngân hàng TMCP Ngoại Thương Việt Nam
              </Text>
            </Col>
          </header>
          <h1 className="text-2xl font-bold text-center">
            HÓA ĐƠN {dataDetail?.invoice_no}
          </h1>
          <Col className="flex flex-col w-full">
            <Row className="grid grid-cols-2">
              <Col className="flex flex-col gap-[8px] col-span-1 p-[10px]">
                <Text className="flex flex-wrap gap-1">
                  <span className="font-bold">Họ tên người mua hàng:</span>
                  {dataDetail?.account?.user.full_name}
                </Text>
                <Text className="flex flex-wrap gap-1">
                  <span className="font-bold">Địa chỉ: </span>
                  {dataDetail?.account?.address}
                </Text>
                <Text className="flex flex-wrap gap-1">
                  <span className="font-bold">Hình thức thanh toán:</span>
                  {dataDetail?.payment_method &&
                    `${dataDetail?.payment_method?.type.name} - ${dataDetail?.payment_method?.type.description}`}
                </Text>
              </Col>
              <Col className="flex flex-col gap-[8px] col-span-1 p-[10px]">
                <Text className="flex flex-wrap gap-1">
                  <span className="font-bold">Loại giao dịch:</span>
                  {TRANSACTION_TYPE_TEXT[dataDetail?.transaction_type]}
                </Text>
                <Text className="flex flex-wrap gap-1">
                  <span className="font-bold">Hóa đơn ngày:</span>
                  {formatDate(dataDetail?.created_at)}
                </Text>
                <Text className="flex flex-wrap gap-1">
                  <span className="font-bold">Ngày đến hạn:</span>
                  {formatDate(dataDetail?.dueDate)}
                </Text>
                <Text className="flex flex-wrap gap-1">
                  <span className="font-bold">Ngày thanh toán:</span>
                  {formatDate(dataDetail?.paidDate)}
                </Text>
              </Col>
            </Row>
            <table className="w-full border-collapse border border-gray-600">
              <thead>
                <tr>
                  <th
                    className={customTwMerge(
                      'w-[10%] text-center',
                      cellClassName,
                    )}
                  >
                    STT
                  </th>
                  <th
                    className={customTwMerge(
                      'w-[20%] text-center',
                      cellClassName,
                    )}
                  >
                    Tên hàng hóa, dịch vụ
                  </th>
                  <th
                    className={customTwMerge(
                      'w-[10%] text-center',
                      cellClassName,
                    )}
                  >
                    Đơn vị tính
                  </th>
                  <th
                    className={customTwMerge(
                      'w-[10%] text-center',
                      cellClassName,
                    )}
                  >
                    Số lượng
                  </th>
                  <th
                    className={customTwMerge(
                      'w-[10%] text-center',
                      cellClassName,
                    )}
                  >
                    Đơn giá
                  </th>
                  <th
                    className={customTwMerge(
                      'w-[10%] text-center',
                      cellClassName,
                    )}
                  >
                    Thành tiền
                  </th>
                  <th
                    className={customTwMerge(
                      'w-[10%] text-center',
                      cellClassName,
                    )}
                  >
                    Thuế suất
                  </th>
                  <th
                    className={customTwMerge(
                      'w-[15%] text-center',
                      cellClassName,
                    )}
                  >
                    Tiền Thuế GTGT
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={customTwMerge('text-center', cellClassName)}>
                    1
                  </td>
                  <td className={customTwMerge(cellClassName)}>
                    {serviceName}
                  </td>
                  <td className={customTwMerge('text-center', cellClassName)}>
                    Gói
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    1
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    1
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    {formatCurrency(dataDetail?.total)}
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    {formatNumber(dataDetail?.tax_rate)} %
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    {formatCurrency(dataDetail?.tax)}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="w-full border-collapse border border-t-0 border-gray-600">
              <thead>
                <tr>
                  <th
                    className={customTwMerge(
                      'w-[30%] text-center !border-t-0',
                      cellClassName,
                    )}
                  >
                    Tổng hợp
                  </th>
                  <th
                    className={customTwMerge(
                      'w-[20%] text-center !border-t-0',
                      cellClassName,
                    )}
                  >
                    Thành tiền trước thuế
                  </th>
                  <th
                    className={customTwMerge(
                      'w-[20%] text-center !border-t-0',
                      cellClassName,
                    )}
                  >
                    Thuế GTGT (VAT)
                  </th>
                  <th
                    className={customTwMerge(
                      'w-[25%] text-center !border-t-0',
                      cellClassName,
                    )}
                  >
                    Tổng tiền thanh toán
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={customTwMerge('font-medium', cellClassName)}>
                    Tổng không chịu thuế
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    0 đ
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    0 đ
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    0 đ
                  </td>
                </tr>
                <tr>
                  <td className={customTwMerge('font-medium', cellClassName)}>
                    <Row className="flex gap-1">
                      <span>Tổng chịu thuế</span>
                      {`${
                        dataDetail?.tax2Rate
                          ? formatNumber(dataDetail?.tax2Rate)
                          : 0
                      } %`}
                    </Row>
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    {formatCurrency(
                      dataDetail?.tax2Rate ? dataDetail?.total : 0,
                    )}
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    {formatCurrency(
                      dataDetail?.tax2Rate ? dataDetail?.tax2 : 0,
                    )}
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    {formatCurrency(
                      dataDetail?.tax2Rate ? dataDetail?.final_total : 0,
                    )}
                  </td>
                </tr>
                <tr>
                  <td className={customTwMerge('font-medium', cellClassName)}>
                    <Row className="flex gap-1">
                      <span>Tổng chịu thuế</span>
                      {`${
                        dataDetail?.tax_rate
                          ? formatNumber(dataDetail?.tax_rate)
                          : 8
                      } %`}
                    </Row>
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    {formatCurrency(
                      dataDetail?.tax_rate ? dataDetail?.total : 0,
                    )}
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    {formatCurrency(dataDetail?.tax_rate ? dataDetail?.tax : 0)}
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    {formatCurrency(
                      dataDetail?.tax_rate ? dataDetail?.final_total : 0,
                    )}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colSpan={3}
                    className={customTwMerge(
                      'text-right font-bold',
                      cellClassName,
                    )}
                  >
                    Tạm tính
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    {formatCurrency(dataDetail?.total)}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    className={customTwMerge(
                      'text-right font-bold',
                      cellClassName,
                    )}
                  >
                    Tín dụng
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    0 đ
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    className={customTwMerge(
                      'text-right font-bold',
                      cellClassName,
                    )}
                  >
                    Thuế VAT
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    {formatCurrency(dataDetail?.tax)}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    className={customTwMerge(
                      'text-right font-bold',
                      cellClassName,
                    )}
                  >
                    Tổng
                  </td>
                  <td className={customTwMerge('text-right', cellClassName)}>
                    {formatCurrency(dataDetail?.final_total)}
                  </td>
                </tr>
              </tfoot>
            </table>
            <Row
              className={customTwMerge(
                'font-bold !border-t-0 flex gap-1',
                cellClassName,
              )}
            >
              <span>Số tiền viết bằng chữ:</span>
              <NumberToWord
                number={dataDetail?.final_total}
                isUpperCaseFirstLetter
              />
              .
            </Row>
          </Col>
        </Col>
      </CardDetail>
    </Spin>
  );
};

export default InvoiceContent;
