import { IRouteProps } from '_common/interface/router';
import ListPage from '.';
import CreateUpdatePage from './pages/CreateUpdate';
import DetailPage from './pages/Detail';
import { USER_ACCOUNT_URL } from './url';

export const UserAccountRouter: IRouteProps[] = [
  {
    key: USER_ACCOUNT_URL.list.key,
    path: USER_ACCOUNT_URL.list.shortenUrl,
    component: <ListPage />,
  },
  {
    key: USER_ACCOUNT_URL.detail.key,
    path: USER_ACCOUNT_URL.detail.shortenUrl,
    component: <DetailPage />,
  },
  {
    key: USER_ACCOUNT_URL.create.key,
    path: USER_ACCOUNT_URL.create.shortenUrl,
    component: <CreateUpdatePage />,
  },
  {
    key: USER_ACCOUNT_URL.update.key,
    path: USER_ACCOUNT_URL.update.shortenUrl,
    component: <CreateUpdatePage />,
  },
  {
    key: USER_ACCOUNT_URL.invoiceTemplate.key,
    path: USER_ACCOUNT_URL.invoiceTemplate.shortenUrl,
  },
];
