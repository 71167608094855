import TeraFilter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import {
  PAYMENT_METHOD_TEXT,
  TRANSACTION_TYPE_TEXT,
} from '_common/constants/invoiceManagement';
import RangePicker from '_common/dof/Control/RangePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCustomer from '_common/dof/Select/SelectCustomer';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TABLE_STATUS_TEXT } from '../contants';

interface FilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (values: any) => void;
  initialValue: any;
}

function Filter({ open, onClose, onFilter, initialValue }: FilterProps) {
  const form = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    const values = _.pick(initialValue, [
      'transaction_type',
      'payment_method_type',
      'status',
      'created_from',
      'created_to',
      'account_id',
    ]);

    form.reset({
      ...values,
      created_at: values.created_from &&
        values.created_to && [
          moment(values.created_from),
          moment(values.created_to),
        ],
    });
  }, [initialValue]);

  const handleSubmitForm = (values) => {
    onFilter({
      ...values,
      created_from:
        values.created_at?.[0] &&
        values.created_at[0].format(DATE_BACKEND_FORMAT),
      created_to:
        values.created_at?.[1] &&
        values.created_at[1].format(DATE_BACKEND_FORMAT),
    });
    onClose();
  };

  const handleReset = () => {
    onFilter({
      transaction_type: null,
      payment_method_type: null,
      status: null,
      created_at: null,
      created_from: null,
      created_to: null,
      account_id: null,
    });
    onClose();
  };

  const transactionOptions = Object.entries(TRANSACTION_TYPE_TEXT).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const paymentMethodTypeOptions = Object.entries(PAYMENT_METHOD_TEXT).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const statusOptions = Object.entries(TABLE_STATUS_TEXT).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  return (
    <TeraFilter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera
        form={form}
        onSubmit={form.handleSubmit(handleSubmitForm)}
        className="flex flex-col gap-2"
      >
        <FormTeraItem label="Loại giao dịch" name="transaction_type">
          <Select options={transactionOptions} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Phương thức thanh toán" name="payment_method_type">
          <Select options={paymentMethodTypeOptions} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Trạng thái" name="status">
          <Select options={statusOptions} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Ngày tạo" name="created_at" className="mb-0">
          <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
        </FormTeraItem>
        <FormTeraItem label="Người dùng" name="account_id">
          <SelectCustomer
            paramsApi={{ include_id: form.watch('account_id') }}
            allowClear={false}
          />
        </FormTeraItem>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </TeraFilter>
  );
}

export default Filter;
