import classNames from 'classnames';
import Menu1 from './Menu1';
import Menu2 from './Menu2';
import Menu12 from './Menu12';
import Menu13 from './Menu13';
import Menu16 from './Menu16';
import { TypeIcon } from './interface';

interface IconsProps {
  icon: TypeIcon | string;
  className?: string;
  [key: string]: any;
}

function Icons({ icon, className, ...props }: IconsProps) {
  const renderIcon = {
    menu1: <Menu1 {...props} />,
    menu2: <Menu2 {...props} />,
    menu12: <Menu12 {...props} />,
    menu13: <Menu13 {...props} />,
    menu16: <Menu16 {...props} />,
  };
  return (
    <div className={classNames('tera-menu__item', className)}>
      {renderIcon[icon]}
    </div>
  );
}

export default Icons;
