function Menu1(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4854 13.8233C15.0336 12.3644 16 10.2951 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 10.2951 0.966443 12.3644 2.51457 13.8233C3.94676 15.1729 5.87679 16 8 16C10.1232 16 12.0532 15.1729 13.4854 13.8233ZM3.196 12.7691C4.32379 11.3623 6.0567 10.4615 8 10.4615C9.9433 10.4615 11.6762 11.3623 12.804 12.7691C11.5777 14.0043 9.87816 14.7692 8 14.7692C6.12185 14.7692 4.42234 14.0043 3.196 12.7691ZM11.0769 5.53846C11.0769 7.2378 9.69934 8.61539 8 8.61539C6.30066 8.61539 4.92308 7.2378 4.92308 5.53846C4.92308 3.83912 6.30066 2.46154 8 2.46154C9.69934 2.46154 11.0769 3.83912 11.0769 5.53846Z"
        fill="white"
      />
    </svg>
  );
}

export default Menu1;
