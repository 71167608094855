import { useMutation, useQueryClient } from '@tanstack/react-query';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Button, Spin, Tag, notification } from 'tera-dls';
import DomainAPI from '../../apis';
import {
  DOMAIN_STATUS,
  DOMAIN_STATUS_COLOR,
  DOMAIN_STATUS_TEXT,
} from '../../constants';
import { SERVICE_DOMAIN_URL } from '../../url';

interface IDetailHeaderProps {
  dataDetail: any;
}

const DetailHeader = ({ dataDetail }: IDetailHeaderProps): JSX.Element => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdateStatus } =
    useMutation(
      (params: any) =>
        _.isEqual(params.type, DOMAIN_STATUS.ACTIVE)
          ? DomainAPI.active({ id: params.id })
          : DomainAPI.deactivate({ id: params.id }),
      {
        onSuccess: (res) => {
          if (_.isEqual(res?.code, 200)) {
            queryClient.invalidateQueries(['get-service-domain-detail']);
            notification.success({
              message: res?.msg,
            });
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleUpdateStatus = (action: DOMAIN_STATUS) =>
    confirm.warning({
      title: `Xác nhận ${
        _.isEqual(action, DOMAIN_STATUS.ACTIVE) ? '' : 'hủy'
      } kích hoạt tên miền`,
      content: (
        <div className="flex flex-col">
          <p>{`Bạn có chắc muốn ${
            _.isEqual(action, DOMAIN_STATUS.ACTIVE) ? '' : 'hủy'
          } kích hoạt tên miền`}</p>
          <p>
            <b>{dataDetail?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateUpdateStatus({ id: dataDetail?.id, type: action }),
    });

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (id: any) => DomainAPI.delete({ id }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          notification.success({
            message: res?.msg,
          });
          navigate(SERVICE_DOMAIN_URL.list.path);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleDelete = () =>
    confirm.warning({
      title: 'Xác nhận xóa tên miền',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa tên miền</p>
          <p>
            <b>{dataDetail?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateDelete(dataDetail?.id),
    });

  return (
    <Spin spinning={isLoadingUpdateStatus || isLoadingDelete}>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <div className="flex flex-col items-start gap-4">
            <h1 className="text-[20px] font-[600] flex gap-4">
              <span className="text-blue-500">{dataDetail?.name}</span>
              {dataDetail?.order[0].bill?.invoice_no}
            </h1>
            <Tag
              color={DOMAIN_STATUS_COLOR[dataDetail?.status]}
              className="font-[500] p-2 justify-center"
            >
              {DOMAIN_STATUS_TEXT[dataDetail?.status]}
            </Tag>
          </div>
          <div className="flex gap-4">
            {_.isEqual(dataDetail?.status, DOMAIN_STATUS.NEW) && (
              <Button
                className="bg-blue-500"
                onClick={() => handleUpdateStatus(DOMAIN_STATUS.ACTIVE)}
              >
                Kích hoạt
              </Button>
            )}
            {_.isEqual(dataDetail?.status, DOMAIN_STATUS.ACTIVE) && (
              <Button
                className="bg-red-500 hover:bg-red-700"
                onClick={() => handleUpdateStatus(DOMAIN_STATUS.SUSPENDED)}
              >
                Hủy kích hoạt
              </Button>
            )}
            {!_.isEqual(dataDetail?.status, DOMAIN_STATUS.SUSPENDED) &&
              _.isEqual(dataDetail?.is_request_cancel, 1) && (
                <Button
                  className="bg-blue-500"
                  onClick={() => handleUpdateStatus(DOMAIN_STATUS.SUSPENDED)}
                >
                  Đồng ý hủy dịch vụ
                </Button>
              )}
            <Button
              className="bg-red-500 hover:bg-red-700"
              onClick={handleDelete}
            >
              Xóa
            </Button>
          </div>
        </div>
        {_.isEqual(dataDetail?.status, DOMAIN_STATUS.NEW) && (
          <Tag
            color="yellow03"
            className="p-2 border-[1px] border-yellow-500 rounded-none"
          >
            Tên miền chưa được đăng ký. Khi nhận được thanh toán, hệ thống sẽ tự
            động đăng ký tên miền.
          </Tag>
        )}
      </div>
    </Spin>
  );
};

export default DetailHeader;
