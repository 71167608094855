import { Button, Modal } from 'tera-dls';
import ExpenseVoucherContent from './ExpenseVoucherContent';

interface IProps {
  open: boolean;
  onClose?: () => void;
  value: number;
}
const ExpenseVoucherDetail = (props: IProps) => {
  const { open, onClose, value } = props;
  // const [openSprintModal, setOenSprintModal] = useState<boolean>(false);

  return (
    <>
      <Modal
        title={'Chi tiết phiếu chi'}
        destroyOnClose
        closeIcon={false}
        width={800}
        cancelText="Hủy"
        okText="Đồng ý"
        onCancel={onClose}
        open={open}
        centered={true}
        footer={
          <div className="flex gap-5">
            {/* <SprintButton
              permission={{
                normal:
                  EXPENSE_VOUCHER_PERMISSION_KEY.EXPENSE_VOUCHER_PRINT_PDF,
              }}
              onClick={() => setOenSprintModal(true)}
              className="!py-1 !px-5 !bg-green-600"
            /> */}
            <Button className="px-7" onClick={onClose}>
              Đóng
            </Button>
          </div>
        }
      >
        <ExpenseVoucherContent id={value} />
      </Modal>
      {/* {openSprintModal && (
        <SprintModal
          id={value}
          onClose={() => setOenSprintModal(false)}
          open={openSprintModal}
          objectType="expense_voucher"
          permission={{
            normal: EXPENSE_VOUCHER_PERMISSION_KEY.EXPENSE_VOUCHER_PRINT_PDF,
          }}
        />
      )} */}
    </>
  );
};

export default ExpenseVoucherDetail;
