import ZingIcon from '_common/component/ZingIcon';
import AffiliateIcon from 'styles/images/Icons/AffiliateIcon';
import UserCircleIcon from 'styles/images/Icons/UserCircleIcon';
import { CreditCardOutlined } from 'tera-dls';

const menu = {
  groupMenu: [
    {
      code: 'admin',
      title: 'ZING VPS',
      path: '',
      icon: '',
      key: 'admin',
    },
  ],
  parentGroup: [
    {
      title: 'Người dùng',
      key: 'user',
      parentKey: 'admin',
    },
    {
      title: 'Quản lý dịch vụ',
      key: 'service',
      parentKey: 'admin',
    },
    {
      title: 'Quản lý hóa đơn',
      key: 'invoice',
      parentKey: 'admin',
    },
    {
      title: 'Phương thức thanh toán',
      key: 'finance',
      parentKey: 'admin',
    },
    {
      title: 'Gói dịch vụ',
      key: 'service-package',
      parentKey: 'admin',
    },
    {
      title: 'Affiliates',
      key: 'affiliates',
      parentKey: 'admin',
    },
  ],
  parentMenu: [
    {
      title: 'Tổng quan',
      path: 'overview',
      iconNode: <ZingIcon.IconMenuHouse className="size-4" />,
      key: 'overview',
      parentKey: 'admin',
    },
    {
      title: 'Người dùng',
      path: '/user/account/list',
      iconNode: <ZingIcon.IconMenuPerson className="size-4" />,
      key: 'user-account',
      parentKey: 'admin',
      parentGroupKey: 'user',
    },
    {
      title: 'KYC',
      path: '/user/kyc',
      iconNode: <ZingIcon.IconMenuBill className="size-4" />,
      key: 'user-kyc',
      parentKey: 'admin',
      parentGroupKey: 'user',
    },
    {
      title: 'Thông tin của tôi',
      path: '/user/my-information',
      iconNode: <UserCircleIcon className="size-4" />,
      key: 'user-my-information',
      parentKey: 'admin',
      parentGroupKey: 'user',
    },
    {
      title: 'Tên miền',
      path: '/service/domain/list',
      iconNode: <ZingIcon.IconMenuWorld className="size-4" />,
      key: 'service-domain',
      parentKey: 'admin',
      parentGroupKey: 'service',
    },
    {
      title: 'Email và bảo mật',
      path: '/service/email-security/list',
      iconNode: <ZingIcon.IconMenuMail className="size-4" />,
      key: 'service-email-security',
      parentKey: 'admin',
      parentGroupKey: 'service',
    },
    {
      title: 'Web Hosting',
      path: '/service/hosting/list',
      iconNode: <ZingIcon.IconMenuHosting className="size-4" />,
      key: 'service-hosting',
      parentKey: 'admin',
      parentGroupKey: 'service',
    },
    {
      title: 'Cloud Server',
      path: '/service/cloud-server/list',
      iconNode: <ZingIcon.IconMenuDatabase className="size-4" />,
      key: 'service-cloud-server',
      parentKey: 'admin',
      parentGroupKey: 'service',
    },
    {
      title: 'SSL',
      path: '/service/ssl/list',
      iconNode: <ZingIcon.IconMenuLock className="size-4" />,
      key: 'service-ssl',
      parentKey: 'admin',
      parentGroupKey: 'service',
    },
    {
      title: 'Cloud VPS',
      path: '/service/cloud-vps/list',
      iconNode: <ZingIcon.IconMenuCloud className="size-4" />,
      key: 'service-cloud-vps',
      parentKey: 'admin',
      parentGroupKey: 'service',
    },
    {
      title: 'Quản lý hóa đơn',
      path: '/invoice/invoice/list',
      iconNode: <ZingIcon.IconMenuBill className="size-4" />,
      key: 'invoice-invoice',
      parentKey: 'admin',
      parentGroupKey: 'invoice',
    },
    {
      title: 'Phiếu thu',
      path: '/invoice/receipt/list',
      iconNode: <ZingIcon.IconMenuReceipt className="size-4" />,
      key: 'invoice-receipt',
      parentKey: 'admin',
      parentGroupKey: 'invoice',
    },
    {
      title: 'Phiếu chi',
      path: '/invoice/payment/list',
      iconNode: <ZingIcon.IconMenuReceiptPayment className="size-4" />,
      key: 'invoice-payment',
      parentKey: 'admin',
      parentGroupKey: 'invoice',
    },
    {
      title: 'Thống kê thu chi',
      path: '/invoice/statistic/list',
      iconNode: <ZingIcon.IconMenuChartPie className="size-4" />,
      key: 'invoice-statistic',
      parentKey: 'admin',
      parentGroupKey: 'invoice',
    },
    {
      title: 'Phương thức thanh toán',
      path: '/finance/payment-method/list',
      iconNode: <CreditCardOutlined className="size-4" />,
      key: 'finance-payment-method',
      parentKey: 'admin',
      parentGroupKey: 'finance',
    },
    {
      title: 'Lịch sử giao dịch',
      path: '/finance/transaction-history/list',
      iconNode: <CreditCardOutlined className="size-4" />,
      key: 'finance-transaction-history',
      parentKey: 'admin',
      parentGroupKey: 'finance',
    },
    {
      title: 'Sản phẩm',
      path: '/service-package/service/list',
      iconNode: <ZingIcon.IconSquareBox className="size-4" />,
      key: 'service-package-service',
      parentKey: 'admin',
      parentGroupKey: 'service-package',
    },
    {
      title: 'Hệ điều hành',
      path: '/service-package/operating-system/list',
      iconNode: <ZingIcon.IconWindows className="size-4 text-white" />,
      key: 'service-package-operating-system',
      parentKey: 'admin',
      parentGroupKey: 'service-package',
    },
    {
      title: 'Quốc gia',
      path: '/service-package/country/list',
      iconNode: <ZingIcon.IconCountry className="size-4" />,
      key: 'service-package-country',
      parentKey: 'admin',
      parentGroupKey: 'service-package',
    },
    {
      title: 'Vị trí',
      path: '/service-package/location/list',
      iconNode: <ZingIcon.IconLocation className="size-4 text-white" />,
      key: 'service-package-location',
      parentKey: 'admin',
      parentGroupKey: 'service-package',
    },
    {
      title: 'Datacenter',
      path: '/service-package/datacenter/list',
      iconNode: <ZingIcon.IconCentral className="size-4" />,
      key: 'service-package-datacenter',
      parentKey: 'admin',
      parentGroupKey: 'service-package',
    },
    {
      title: 'Báo cáo chung',
      path: '/affiliates/general',
      iconNode: <AffiliateIcon className="size-4" />,
      key: 'affiliates-general',
      parentKey: 'admin',
      parentGroupKey: 'affiliates',
    },
    {
      title: 'DS người giới thiệu',
      path: '/affiliates/agency/list',
      iconNode: <AffiliateIcon className="size-4" />,
      key: 'affiliates-agency',
      parentKey: 'admin',
      parentGroupKey: 'affiliates',
    },
    {
      title: 'Đăng xuất',
      path: '/logout',
      icon: '',
      iconNode: <ZingIcon.IconMenuLogout className="size-4" />,
      key: 'logout',
      parentKey: 'admin',
      parentGroupKey: '',
    },
  ],
  subMenu: [],
};
export default menu;
