import { useMutation, useQueryClient } from '@tanstack/react-query';
import CardDetail from '_common/component/CardDetail';
import { REGEX } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import InputPassword from '_common/dof/Control/InputPassword';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { AuthApi } from 'states/api';
import {
  Button,
  Col,
  EyeOutlined,
  EyeSlashOutlined,
  notification,
  Spin,
} from 'tera-dls';

interface IChangePasswordProps {
  dataDetail: any;
}

const ChangePassword = ({ dataDetail }: IChangePasswordProps) => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: { password: '', confirm_password: '' },
  });
  const queryClient = useQueryClient();

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    (params) => AuthApi.changePassword({ params }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          notification.success({
            message: res?.msg,
          });
          form.reset({
            password: '',
            confirm_password: '',
          });
          queryClient.invalidateQueries(['get-user-account-detail']);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleSubmitForm = (values: any) => {
    if (isLoadingUpdate) return;

    mutateUpdate({
      ...values,
      member_id: dataDetail?.user_id,
    });
  };

  return (
    <Spin spinning={isLoadingUpdate}>
      <Col className="w-full flex flex-col items-start gap-4">
        <CardDetail className="w-full">
          <FormTera form={form} className="grid grid-cols-2 gap-2">
            <FormTeraItem
              label="Mật khẩu mới"
              name="password"
              rules={[
                {
                  required: messageValidate.emptyText,
                  pattern: {
                    value: new RegExp(REGEX.PASSWORD),
                    message: messageValidate.password,
                  },
                  minLength: {
                    value: 8,
                    message: messageValidate.min_length_password,
                  },
                  maxLength: {
                    value: 16,
                    message: messageValidate.max_length_password,
                  },
                },
              ]}
            >
              <InputPassword
                iconRender={(visible) =>
                  visible ? (
                    <EyeSlashOutlined className="size-5" />
                  ) : (
                    <EyeOutlined className="size-5" />
                  )
                }
              />
            </FormTeraItem>
            <FormTeraItem
              label="Nhập lại mật khẩu"
              name="confirm_password"
              rules={[
                {
                  required: messageValidate.emptyText,
                  pattern: {
                    value: new RegExp(REGEX.PASSWORD),
                    message: messageValidate.password,
                  },
                  minLength: {
                    value: 8,
                    message: messageValidate.min_length_password,
                  },
                  maxLength: {
                    value: 16,
                    message: messageValidate.max_length_password,
                  },
                  validate: {
                    existed: (value, values) => {
                      const password = values?.password.trim();

                      if (value?.trim() !== password) {
                        return messageValidate.confirm_password;
                      }
                    },
                  },
                },
              ]}
            >
              <InputPassword
                iconRender={(visible) =>
                  visible ? (
                    <EyeSlashOutlined className="size-5" />
                  ) : (
                    <EyeOutlined className="size-5" />
                  )
                }
              />
            </FormTeraItem>
          </FormTera>
        </CardDetail>
        <Button
          onClick={form.handleSubmit(handleSubmitForm)}
          className="px-12 font-light text-[12px] bg-blue-500"
        >
          Thay đổi mật khẩu
        </Button>
      </Col>
    </Spin>
  );
};

export default ChangePassword;
