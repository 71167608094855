import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import ImportExportApi from 'states/api/ImportExportApi';
import { downloadFile, notification } from 'tera-dls';
import { ItemCode } from '../interface';

interface IProps {
  itemCode: ItemCode | string;
  onSuccess?: () => void;
}

const useDownloadImportTemplate = ({ itemCode, onSuccess }: IProps) => {
  const { mutate: onDownload } = useMutation(
    () =>
      ImportExportApi.getTemplate({
        params: {
          item_code: itemCode,
        },
      }),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          const date = moment().format('DDMMYYYY_HHmmss');
          downloadFile(
            res?.data?.src,
            `template_${itemCode as string}_${date}`,
          );
          onSuccess && onSuccess();
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  return { onDownload };
};

export default useDownloadImportTemplate;
