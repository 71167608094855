import { useMutation, useQueryClient } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TextNavigate from '_common/component/TextNavigate';
import { INVOICE_STATUS } from '_common/constants/finance';
import {
  PAYMENT_METHOD_TEXT,
  PAYMENT_METHOD_TYPE,
  TRANSACTION_TYPE_TEXT,
} from '_common/constants/invoiceManagement';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { USER_ACCOUNT_URL } from 'pages/UserManagement/Account/url';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  Tag,
  formatCurrency,
  formatDate,
  notification,
} from 'tera-dls';
import InvoiceAPI from '../apis';
import { TABLE_STATUS_COLOR, TABLE_STATUS_TEXT } from '../constants';
import { INVOICE_URL } from '../url';

const Table = (props: ITeraTableProps): JSX.Element => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: mutateApproval, isLoading: isLoadingApproval } = useMutation(
    (params: any) => InvoiceAPI.approval({ id: params?.id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-finance-invoice-list']);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleConfirmPayment = (record: any) =>
    confirm.warning({
      title: 'Xác nhận thanh toán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xác nhận thanh toán hóa đơn</p>
          <p>
            <span className="font-bold">{record.invoice_no}</span> này không?
          </p>
        </div>
      ),
      onOk: () => mutateApproval({ id: record.id }),
    });

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (params: any) => InvoiceAPI.delete({ id: params?.id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-finance-invoice-list']);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleDelete = (record: any) => {
    confirm.warning({
      title: 'Xác nhận xóa hóa đơn',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa hóa đơn</p>
          <p>
            <b>{record.invoice_no}</b> này không?
          </p>
        </div>
      ),
      onOk: () =>
        mutateDelete({
          id: record.id,
        }),
    });
  };

  const itemActions = (record: any): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () => {
          navigate(`${INVOICE_URL.detail.path}/${record?.id}`);
        },
      },
      ...(!_.isEqual(record.status, INVOICE_STATUS.PAID)
        ? [
            {
              key: '2',
              label: 'Xác nhận thanh toán',
              onClick: () => handleConfirmPayment(record),
            },
          ]
        : []),
      {
        key: '3',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      },
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã hóa đơn',
      dataIndex: 'invoice_no',
      width: 150,
      render: (invoice_no, record) => (
        <TextNavigate to={`${INVOICE_URL.detail.path}/${record.id}`}>
          {invoice_no}
        </TextNavigate>
      ),
    },
    {
      title: 'Loại giao dịch',
      dataIndex: 'transaction_type',
      width: 150,
      render: (transaction_type) => TRANSACTION_TYPE_TEXT[transaction_type],
    },
    {
      title: 'Khách hàng',
      dataIndex: 'account',
      width: 150,
      render: (_, record) => (
        <HoverQuickView
          avatarUrl={record.account?.user.avatar}
          name={record.account?.user.full_name}
          code={record.account?.id}
          email={record.account?.email}
          phone={record.account?.phone}
        >
          <TextNavigate
            to={`${USER_ACCOUNT_URL.detail.path}/${record.account?.id}`}
          >
            {record.account?.user.full_name}
          </TextNavigate>
        </HoverQuickView>
      ),
    },
    {
      title: 'Tổng giá trị',
      dataIndex: 'final_total',
      width: 150,
      render: (final_total: number) => formatCurrency(final_total),
    },
    {
      title: 'Phương thức thanh toán',
      dataIndex: 'payment_method',
      width: 200,
      render: (payment_method, record) =>
        record.payment_method_id === 0
          ? PAYMENT_METHOD_TEXT[PAYMENT_METHOD_TYPE.WALLET]
          : PAYMENT_METHOD_TEXT[payment_method?.type.type],
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) => (
        <Tag className="font-medium" color={TABLE_STATUS_COLOR[status]}>
          {TABLE_STATUS_TEXT[status]}
        </Tag>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 150,
      render: (created_at) => formatDate(created_at),
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'dueDate',
      width: 150,
      render: (dueDate) => formatDate(dueDate),
    },
    {
      title: 'Ngày thanh toán',
      dataIndex: 'paidDate',
      width: 150,
      render: (paidDate) => formatDate(paidDate),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record: any) => {
        return (
          <ActionDropdown dropdownItems={itemActions(record)} trigger="click" />
        );
      },
    },
  ];

  return (
    <TableTera
      rowKey="id"
      columns={columns}
      loading={props?.loading || isLoadingApproval || isLoadingDelete}
      className="center-table"
      {...props}
    />
  );
};

export default Table;
