export const RecordPerPageOptions = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 200, label: '200' },
];
export const TimeZoneOptions = [
  { value: 1, label: '(GMT + 07:00) Hanoi, TPHCM' },
];
export const CreditOptions = [
  {
    value: 1,
    label:
      'Có, áp dụng tín dụng có sẵn cho các đơn đặt hàng mới và hóa đơn định kỳ ngay lập tức',
  },
  {
    value: 2,
    label: 'Không, không tự động áp dụng tín dụng có sẵn',
  },
];
export const MergeInvoiceOptions = [
  {
    value: '1',
    label: 'Có, tự động gọp các hoá đơn được tạo cùng ngày',
  },
  {
    value: '2',
    label: 'Có, tự động gọp các hoá đơn có cùng ngày đến hạn',
  },
  {
    value: '3',
    label: 'Không, tạo hoá đơn riêng cho từng dịch vụ',
  },
];
export const EmailInformationOptions = [
  {
    value: 'is_noti_email_login',
    label: 'Thông báo cho tôi khi ai đó đăng nhập tài khoản',
  },
  {
    value: 'is_noti_email_payment',
    label: 'Nhận thông báo email liên quan đến thanh toán',
  },
  {
    value: 'is_noti_email_support',
    label: 'Nhận thông báo email liên quan đến hỗ trợ',
  },
  {
    value: 'is_noti_email_service',
    label: 'Nhận thông báo email liên quan đến dịch vụ',
  },
  {
    value: 'is_noti_email_domain',
    label: 'Nhận thông báo email liên quan đến tên miền',
  },
];
export const NameserverOptions = [
  {
    value: '1',
    label: 'Sử dụng Nameserver mặc định của chúng tôi khi đăng ký tên miền mới',
  },
  {
    value: '2',
    label: 'Sử dụng Nameserver riêng người dùng',
  },
];
