import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PaginationProps, getQueryParams, updateQueryParams } from 'tera-dls';
import InvoiceAPI from './apis';
import Filter from './components/Filter';
import Search from './components/Search';
import Table from './components/Table';
import { INVOICE_URL } from './url';

const ListPage = () => {
  const [isOpenAsideFilter, setIsOpenAsideFilter] = useState<boolean>(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${INVOICE_URL.list.path}${paramString}`);
  };

  const handleSearch = ({ keyword }): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: any): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const {
    data: dataList,
    isLoading: isLoadingDataList,
    refetch: refetchDataList,
  } = useQuery(
    ['get-finance-invoice-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return InvoiceAPI.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  useEffect(() => {
    refetchDataList();
  }, []);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(dataList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  return (
    <>
      <div className="tera-page">
        <HeaderViewList
          title="DANH SÁCH HÓA ĐƠN"
          onClickFilter={() => setIsOpenAsideFilter(true)}
          actionLeftRender={<Search onSearch={handleSearch} />}
          filterCount={{
            filter: queryParams,
            params: [
              'transaction_type',
              'payment_method_type',
              'status',
              'created_at',
              'dueDate',
              'paidDate',
              'account_id',
            ],
          }}
        >
          <Table
            rowKey={'id'}
            data={dataList?.data || []}
            loading={isLoadingDataList}
            pagination={{
              onChange: handleChangePage,
              total: dataList?.total || 0,
              current: dataList?.current_page,
              pageSize: dataList?.per_page || 10,
              to: dataList?.to,
              from: dataList?.from || 10,
            }}
          />
        </HeaderViewList>
      </div>
      {isOpenAsideFilter && (
        <Filter
          open={isOpenAsideFilter}
          onClose={() => setIsOpenAsideFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </>
  );
};

export default ListPage;
