import { useMutation, useQuery } from '@tanstack/react-query';
import FormSummary from '_common/component/Form/Summary';
import NoData from '_common/component/NoData';
import { messageWarning } from '_common/constants/message';
import { PRICE_PERIOD_DAY, SERVICE } from '_common/constants/serviceManagement';
import FormTera from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ServiceAPI from 'states/api/ServiceAPI';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Spin,
  getQueryParams,
  notification,
} from 'tera-dls';
import { SERVICE_CLOUD_VPS_URL } from '../../url';
import FormCreateUpdate from './components/FormCreateUpdate';

const CreateUpdatePage = (): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      datacenter: '',
      product: '',
      locationId: '',
      osId: '',
      period: 'monthly',
      domain: null,
      isAutoRenew: 1,
      userName: 'root',
      registrant: '',
      promo_code: '',
      note: '',
    },
  });
  const { isDirty } = form.formState;
  const { id } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Cloud VPS
        </span>
      ),
      onClick: () =>
        isDirty
          ? confirm.warning({
              title: 'Thoát bản ghi',
              content: (
                <>
                  <p>{messageWarning.WARNING_EXIT_1}</p>
                  <p>{messageWarning.WARNING_EXIT_2}</p>
                </>
              ),
              onOk: () => navigate(SERVICE_CLOUD_VPS_URL.list.path),
            })
          : navigate(SERVICE_CLOUD_VPS_URL.list.path),
    },
    {
      title: `${id ? 'Sửa' : 'Thêm'} Cloud VPS`,
    },
  ];

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-cloud-vps-detail', id],
    () => ServiceAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  useEffect(() => {
    if (!_.isEmpty(dataDetail))
      form.reset({
        ...form.getValues(),
        datacenter: dataDetail?.datacenter,
        product: dataDetail?.product,
        locationId: dataDetail?.locationId,
        osId: dataDetail?.osId,
        period: dataDetail?.period,
        registrant: dataDetail?.registrant.id,
        note: dataDetail?.note,
      });
  }, [dataDetail]);

  const countrySelected = useMemo(
    () => (id ? dataDetail?.countryCode : queryParams.country),
    [queryParams.country, dataDetail?.countryCode],
  );

  const { mutate: mutateCreateUpdate, isLoading: isLoadingCreateUpdate } =
    useMutation(
      (params: any) =>
        id ? ServiceAPI.update({ id, params }) : ServiceAPI.create({ params }),
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            notification.success({
              message: res?.msg,
            });
            navigate(SERVICE_CLOUD_VPS_URL.list.path);
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleSubmitForm = (values: any) => {
    if (isLoadingCreateUpdate) return;
    mutateCreateUpdate({
      type: SERVICE.CLOUD_VPS,
      datacenterId: values.datacenter.datacenterId,
      planId: values.product.planId,
      locationId: values.locationId,
      osId: values.osId,
      period: values.period,
      period_days: PRICE_PERIOD_DAY[values.period],
      first_payment: values.product.prices[values.period],
      domain: values.domain,
      isAutoRenew: values.isAutoRenew,
      userName: values.userName,
      registrant: values.registrant,
      promo_code: values.promo_code,
      note: values.note,
    });
  };

  return (
    <Spin spinning={(!_.isNil(id) && isLoadingDetail) || isLoadingCreateUpdate}>
      <div className="tera-page-form min-h-[calc(100vh_-_45px)]">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header__breadcrumb-back cursor-pointer"
                onClick={items[items.length - 2].onClick}
              >
                <ArrowSmallLeftSolid className="h-6 w-6" />
              </div>
              <Breadcrumb separator={'/'} items={items} />
            </div>
          </div>
        </div>
        {id && (_.isEmpty(dataDetail) || isErrorDetail) ? (
          <NoData />
        ) : (
          <FormTera form={form} className="flex-1 flex flex-col">
            <FormCreateUpdate
              form={form}
              countryCode={countrySelected}
              dataDetail={dataDetail}
            />
            {form.watch('product') && (
              <FormSummary
                form={form}
                handleSubmitForm={form.handleSubmit(handleSubmitForm)}
              />
            )}
          </FormTera>
        )}
      </div>
    </Spin>
  );
};

export default CreateUpdatePage;
