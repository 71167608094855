import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const DatacenterAPIEndpoint = `${endpoint}/admin/datacenter`;
const DatacenterPortalAPIEndpoint = `${endpoint}/admin/datacenter`;

const DatacenterAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${DatacenterAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getProductList: async ({ params }) =>
    await api
      .get(`${DatacenterPortalAPIEndpoint}/products`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${DatacenterAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${DatacenterAPIEndpoint}/create`, params)
      .then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${DatacenterAPIEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${DatacenterAPIEndpoint}/delete/${id}`)
      .then((res) => res.data),
};

export default DatacenterAPI;
