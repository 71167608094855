import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const ServiceAPIEndpoint = `${endpoint}/admin/service`;
const ServiceAPIPortalEndpoint = `${endpoint}/portal/service`;

const ServiceAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${ServiceAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${ServiceAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${ServiceAPIEndpoint}/create`, params)
      .then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${ServiceAPIEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  reboot: async ({ id }) =>
    await api
      .post(`${ServiceAPIPortalEndpoint}/detail/${id}/reboot`)
      .then((res) => res.data),
  runDiag: async ({ id }) =>
    await api
      .post(`${ServiceAPIPortalEndpoint}/detail/${id}/diag`)
      .then((res) => res.data),
  changeIP: async ({ id, params }) =>
    await api
      .post(`${ServiceAPIPortalEndpoint}/detail/${id}/change-ip`, params)
      .then((res) => res.data),
  manualRenew: async ({ id, params }) =>
    await api
      .post(`${ServiceAPIPortalEndpoint}/detail/${id}/manualrenew`, params)
      .then((res) => res.data),
  updateNote: async ({ id, params }) =>
    await api
      .post(`${ServiceAPIPortalEndpoint}/detail/${id}/note`, params)
      .then((res) => res.data),
  active: async ({ id }) =>
    await api.put(`${ServiceAPIEndpoint}/active/${id}`).then((res) => res.data),
  deactivate: async ({ id }) =>
    await api
      .put(`${ServiceAPIEndpoint}/deactivate/${id}`)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${ServiceAPIEndpoint}/delete/${id}`)
      .then((res) => res.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${ServiceAPIEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
};

export default ServiceAPI;
