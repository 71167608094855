import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '_common/hooks/useDebounce';
import UserAccountAPI from 'pages/UserManagement/Account/apis';
import { forwardRef, useMemo, useState } from 'react';
import { OptionProps, Row } from 'tera-dls';
import { SelectProps } from 'tera-dls/lib/components/Select';
import { TParamsApiDof } from '../../interfaces';
import SelectEntity from '../SelectEntity';

export interface SelectDataTypeProps extends SelectProps {
  paramsApi?: TParamsApiDof;
  optionCustom?: OptionProps[];
  isCheckAll?: boolean;
}

const SelectCustomer = forwardRef<HTMLSelectElement, SelectDataTypeProps>(
  ({ placeholder = 'Vui lòng chọn', paramsApi, ...props }, ref) => {
    const [search, setSearch] = useState<string>('');
    const searchDebounce = useDebounce(search, 300);

    const { data: dataList, isLoading } = useQuery(
      ['get-user-account-list', searchDebounce, paramsApi],
      () => {
        const params = {
          page: 1,
          limit: 15,
          keyword: searchDebounce,
          status: 1,
          ...paramsApi,
        };
        return UserAccountAPI.getList({ params });
      },
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const options: OptionProps[] = useMemo(
      () =>
        dataList?.data
          ?.filter((i: any) => i?.id)
          .map((data: any) => ({
            value: data?.id,
            label: `${data?.last_middle_name ?? ''} ${data?.first_name ?? ''}`,
            labelDisplay: (
              <Row className="flex">
                <span className="text-green-500">[{data?.id}]</span>
                <span>
                  {`${data?.last_middle_name ?? ''} ${data?.first_name ?? ''}`}
                </span>
              </Row>
            ),
          })) || [],
      [dataList],
    );

    return (
      <SelectEntity
        ref={ref}
        allowClear
        showSearch
        labelInValue
        loading={isLoading}
        placeholder={placeholder}
        options={options}
        searchValue={search}
        onSearch={setSearch}
        {...props}
      />
    );
  },
);

export default SelectCustomer;
