import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import { IPagination } from '_common/interface';
import moment from 'moment';
import {
  CASH_RECEIPT_TYPE,
  EXPENSE_VOUCHER_TYPE,
  PAYMENT_METHOD_TYPE,
} from 'pages/Finance/constants';
import React, { useState } from 'react';
import Content from './Content';
export const StatisticCustomerParamContext = React.createContext(null);
interface StatisticCustomerProps {
  type?: string | boolean;
  paginationDefault?: IPagination;
  onClose?: () => void;
}

function StatisticCustomer({}: StatisticCustomerProps) {
  const current = [
    moment().startOf('month').format(DATE_BACKEND_FORMAT),
    moment().format(DATE_BACKEND_FORMAT),
  ];

  const [contextParams, setContextParams] = useState<any>({
    investor_id: null,
    payment_method: Object.keys(PAYMENT_METHOD_TYPE),
    receipt_transaction_type: Object.keys(CASH_RECEIPT_TYPE),
    payment_transaction_type: Object.keys(EXPENSE_VOUCHER_TYPE),
    date: current,
    accounting_date: current,
  });

  return (
    <StatisticCustomerParamContext.Provider
      value={{ contextParams, setContextParams }}
    >
      <Content />
    </StatisticCustomerParamContext.Provider>
  );
}

export default StatisticCustomer;
