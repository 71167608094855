import { useMutation, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { IOpenModalState } from '_common/interface';
import _ from 'lodash';
import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import {
  ColumnsType,
  DropdownItem,
  Tag,
  formatDate,
  notification,
} from 'tera-dls';
import OSAPI from '../apis';
import { OS_STATUS, TABLE_STATUS_COLOR, TABLE_STATUS_TEXT } from '../contants';
import ModalCreateUpdate from '../modals/ModalCreateUpdate';
import ModalDetail from '../modals/ModalDetail';

const Table = (props: ITeraTableProps): JSX.Element => {
  const [isOpenModalDetail, setIsOpenModalDetail] = useState<IOpenModalState>({
    isOpen: false,
    id: null,
  });
  const [isOpenModalCreateUpdate, setIsOpenModalCreateUpdate] =
    useState<IOpenModalState>({
      isOpen: false,
      id: null,
    });
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdateStatus } =
    useMutation(
      (params: any) =>
        _.isEqual(params.type, OS_STATUS.ACTIVE)
          ? OSAPI.deactivate({ id: params.id })
          : OSAPI.active({ id: params.id }),
      {
        onSuccess: (res) => {
          if (_.isEqual(res?.code, 200)) {
            notification.success({
              message: res?.msg,
            });
            queryClient.invalidateQueries([
              'get-service-package-operating-system-list',
            ]);
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleUpdateStatus = (record: any) =>
    confirm.warning({
      title: `Xác nhận ${
        _.isEqual(record.status, OS_STATUS.ACTIVE) ? 'hủy' : ''
      } kích hoạt hệ điều hành`,
      content: (
        <div className="flex flex-col">
          <p>
            {`Bạn có chắc muốn ${
              _.isEqual(record.status, OS_STATUS.ACTIVE) ? 'hủy' : ''
            } kích hoạt hệ điều hành`}
          </p>
          <p>
            <b>{record?.osName}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateUpdateStatus({ id: record.id, type: record.status }),
    });

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (id: any) => OSAPI.delete({ id }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries([
            'get-service-package-operating-system-list',
          ]);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleDelete = (record: any) =>
    confirm.warning({
      title: 'Xác nhận xóa hệ điều hành',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa hệ điều hành</p>
          <p>
            <b>{record?.osName}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateDelete(record?.id),
    });

  const itemActions = (record: any): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () => setIsOpenModalDetail({ isOpen: true, id: record.id }),
      },
      {
        key: '2',
        label: 'Sửa',
        onClick: () =>
          setIsOpenModalCreateUpdate({ isOpen: true, id: record.id }),
      },
      {
        key: '3',
        label: _.isEqual(record.status, OS_STATUS.ACTIVE)
          ? 'Hủy kích hoạt'
          : 'Kích hoạt',
        onClick: () => handleUpdateStatus(record),
      },
      {
        key: '4',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      },
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã hệ điều hành',
      dataIndex: 'osId',
      render: (osId) => <span className="text-blue-500">{osId}</span>,
    },
    {
      title: 'Tên hệ điều hành',
      dataIndex: 'osName',
    },
    {
      title: 'Loại hệ điều hành',
      dataIndex: 'osType',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={TABLE_STATUS_COLOR[status]} className="font-[500]">
          {TABLE_STATUS_TEXT[status]}
        </Tag>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      render: (created_at) => formatDate(created_at, DATE_TIME_FORMAT),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updated_at',
      render: (updated_at) => formatDate(updated_at, DATE_TIME_FORMAT),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record: any) => {
        return (
          <ActionDropdown dropdownItems={itemActions(record)} trigger="click" />
        );
      },
    },
  ];

  return (
    <Fragment>
      <TableTera
        rowKey="id"
        columns={columns}
        loading={props?.loading || isLoadingUpdateStatus || isLoadingDelete}
        className="center-table"
        {...props}
      />
      {isOpenModalCreateUpdate && (
        <ModalCreateUpdate
          open={isOpenModalCreateUpdate.isOpen}
          close={() => setIsOpenModalCreateUpdate({ isOpen: false, id: null })}
          id={isOpenModalCreateUpdate.id}
        />
      )}
      {isOpenModalDetail && (
        <ModalDetail
          open={isOpenModalDetail.isOpen}
          close={() => setIsOpenModalDetail({ isOpen: false, id: null })}
          id={isOpenModalDetail.id}
        />
      )}
    </Fragment>
  );
};

export default Table;
