export const SERVICE_EMAIL_SECURITY_URL = {
  list: {
    key: '',
    path: '/service/email-security/list',
    shortenUrl: 'email-security/list',
  },
  create: {
    key: '',
    path: '/service/email-security/create',
    shortenUrl: 'email-security/create',
  },
  paymentInformation: {
    key: '',
    path: '/service/email-security/payment-information',
    shortenUrl: 'email-security/payment-information',
  },
  payment: {
    key: '',
    path: '/service/email-security/payment',
    shortenUrl: 'email-security/payment',
  },
  detail: {
    key: '',
    path: '/service/email-security/detail',
    shortenUrl: 'email-security/detail/:id',
  },
};
