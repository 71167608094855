import { useQuery } from '@tanstack/react-query';
import { TParamsApiDof } from '_common/dof/interfaces';
import { useDebounce } from '_common/hooks/useDebounce';
import CountryAPI from 'pages/ServicePackage/Country/apis';
import { forwardRef, useMemo, useState } from 'react';
import { OptionProps } from 'tera-dls';
import { SelectProps } from 'tera-dls/lib/components/Select';
import SelectEntity from '../SelectEntity';

interface ISelectCountryProps extends SelectProps {
  paramsApi?: TParamsApiDof;
}

const SelectCountry = forwardRef<HTMLInputElement, ISelectCountryProps>(
  ({ placeholder = 'Vui lòng chọn', paramsApi, ...props }, ref) => {
    const [search, setSearch] = useState<string>('');
    const searchDebounce = useDebounce(search, 300);

    const { data: dataList, isLoading } = useQuery(
      ['get-service-package-country-list', searchDebounce, paramsApi],
      () => {
        const params = {
          page: 1,
          limit: 15,
          keyword: searchDebounce,
          active: 1,
          ...paramsApi,
        };
        return CountryAPI.getList({ params });
      },
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const options: OptionProps[] = useMemo(
      () =>
        dataList?.data
          ?.filter((i: any) => i?.id)
          .map((data: any) => ({
            label: data?.countryName,
            value: data?.countryCode,
          })) || [],
      [dataList],
    );

    return (
      <SelectEntity
        ref={ref}
        allowClear
        showSearch
        labelInValue
        loading={isLoading}
        placeholder={placeholder}
        options={options}
        searchValue={search}
        onSearch={setSearch}
        {...props}
      />
    );
  },
);

export default SelectCountry;
