import { IRouteProps } from '_common/interface/router';
import CashReceipt from '.';
import CashReceiptForm from './containers/Form';
import { CASH_RECEIPT_URL } from './url';

export const InvoiceReceiptRouter: IRouteProps[] = [
  {
    key: CASH_RECEIPT_URL.list.key,
    path: CASH_RECEIPT_URL.list.shortenUrl,
    component: <CashReceipt />,
  },
  {
    key: CASH_RECEIPT_URL.create.key,
    path: CASH_RECEIPT_URL.create.shortenUrl,
    component: <CashReceiptForm />,
  },
  {
    key: CASH_RECEIPT_URL.update.key,
    path: CASH_RECEIPT_URL.update.shortenUrl,
    component: <CashReceiptForm />,
  },
];
