import { IRouteProps } from '_common/interface/router';
import ListPage from '.';
import DetailPage from './pages/Detail';
import { ACCOUNT_KYC_URL } from './url';

export const AccountKYCRouter: IRouteProps[] = [
  {
    key: ACCOUNT_KYC_URL.list.key,
    path: ACCOUNT_KYC_URL.list.shortenUrl,
    component: <ListPage />,
  },
  {
    key: ACCOUNT_KYC_URL.detail.key,
    path: ACCOUNT_KYC_URL.detail.shortenUrl,
    component: <DetailPage />,
  },
];
