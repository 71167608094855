import TeraFilter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import {
  PAYMENT_METHOD_TEXT,
  TRANSACTION_TYPE_TEXT,
} from '_common/constants/invoiceManagement';
import RangePicker from '_common/dof/Control/RangePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCustomer from '_common/dof/Select/SelectCustomer';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TABLE_STATUS_TEXT } from '../constants';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function Filter({ open, onClose, onFilter, initialValue }: IProps) {
  const form = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    const values = _.pick(initialValue, [
      'transaction_type',
      'payment_method_type',
      'status',
      'created_from',
      'created_to',
      'dueDate_from',
      'dueDate_to',
      'paidDate_from',
      'paidDate_to',
      'account_id',
    ]);

    form.reset({
      ...values,
      created_at: values.created_from &&
        values.created_to && [
          moment(values.created_from),
          moment(values.created_to),
        ],
      dueDate: values.dueDate_from &&
        values.dueDate_to && [
          moment(values.dueDate_from),
          moment(values.dueDate_to),
        ],
      paidDate: values.paidDate_from &&
        values.paidDate_to && [
          moment(values.paidDate_from),
          moment(values.paidDate_to),
        ],
    });
  }, [initialValue]);

  const handleSubmitForm = (values: any) => {
    onFilter({
      ...values,
      created_from:
        values.created_at?.[0] &&
        values.created_at[0].format(DATE_BACKEND_FORMAT),
      created_to:
        values.created_at?.[1] &&
        values.created_at[1].format(DATE_BACKEND_FORMAT),
      dueDate_from:
        values.dueDate?.[0] && values.dueDate[0].format(DATE_BACKEND_FORMAT),
      dueDate_to:
        values.dueDate?.[1] && values.dueDate[1].format(DATE_BACKEND_FORMAT),
      paidDate_from:
        values.paidDate?.[0] && values.paidDate[0].format(DATE_BACKEND_FORMAT),
      paidDate_to:
        values.paidDate?.[1] && values.paidDate[1].format(DATE_BACKEND_FORMAT),
    });
    onClose();
  };

  const handleReset = () => {
    const values = {
      transaction_type: null,
      payment_method_type: null,
      status: null,
      created_at: null,
      created_from: null,
      created_to: null,
      dueDate: null,
      dueDate_from: null,
      dueDate_to: null,
      paidDate: null,
      paidDate_from: null,
      paidDate_to: null,
      account_id: null,
    };
    onFilter(values);
    onClose();
  };

  const transactionOptions = Object.entries(TRANSACTION_TYPE_TEXT).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const paymentMethodTypeOptions = Object.entries(PAYMENT_METHOD_TEXT).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const statusOptions = Object.entries(TABLE_STATUS_TEXT).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  return (
    <TeraFilter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Loại giao dịch" name="transaction_type">
          <Select options={transactionOptions} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Phương thức thanh toán" name="payment_method_type">
          <Select options={paymentMethodTypeOptions} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Trạng thái" name="status">
          <Select options={statusOptions} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Ngày tạo" name="created_at">
          <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
        </FormTeraItem>
        <FormTeraItem label="Ngày hết hạn" name="dueDate">
          <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
        </FormTeraItem>
        <FormTeraItem label="Ngày thanh toán" name="paidDate">
          <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
        </FormTeraItem>
        <FormTeraItem label="Người dùng" name="account_id">
          <SelectCustomer
            paramsApi={{ include_id: form.watch('account_id') }}
            allowClear={false}
          />
        </FormTeraItem>
        <span className="text-red-500 cursor-pointer" onClick={handleReset}>
          Hủy bộ lọc
        </span>
      </FormTera>
    </TeraFilter>
  );
}

export default Filter;
