export const FINANCE_PAYMENT_METHOD_URL = {
  list: {
    key: '',
    path: '/finance/payment-method/list',
    shortenUrl: 'payment-method/list',
  },
  create: {
    key: '',
    path: '/finance/payment-method/create',
    shortenUrl: 'payment-method/create',
  },
  update: {
    key: '',
    path: '/finance/payment-method/update',
    shortenUrl: 'payment-method/update/:id',
  },
};
