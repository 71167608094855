import { useMutation, useQueryClient } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TextNavigate from '_common/component/TextNavigate';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { USER_ACCOUNT_URL } from 'pages/UserManagement/Account/url';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  Tag,
  formatDate,
  notification,
} from 'tera-dls';
import AccountKYCAPI from '../apis';
import {
  ACCOUNT_KYC_STATUS,
  TABLE_STATUS_COLOR,
  TABLE_STATUS_TEXT,
} from '../contants';
import { ACCOUNT_KYC_URL } from '../url';

const Table = (props: ITeraTableProps): JSX.Element => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: mutateApproval, isLoading: isLoadingApproval } = useMutation(
    (params: any) => AccountKYCAPI.active({ id: params.id }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          queryClient.invalidateQueries(['get-account-kyc-list']);
          queryClient.invalidateQueries(['get-summary-account-kyc-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleApproval = (record: any) =>
    confirm.warning({
      title: `Xác nhận duyệt KYC`,
      content: (
        <div className="flex flex-col">
          <p>Bạn có chắc muốn duyệt KYC</p>
          <p>
            <b>{record?.account.user.full_name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateApproval({ id: record.id }),
    });

  const { mutate: mutateRefused, isLoading: isLoadingRefused } = useMutation(
    (params: any) => AccountKYCAPI.deactivate({ id: params.id }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          queryClient.invalidateQueries(['get-account-kyc-list']);
          queryClient.invalidateQueries(['get-summary-account-kyc-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleRefused = (record: any) =>
    confirm.warning({
      title: `Xác nhận từ chối KYC`,
      content: (
        <div className="flex flex-col">
          <p>Bạn có chắc muốn từ chối KYC</p>
          <p>
            <b>{record?.account.user.full_name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateRefused({ id: record.id }),
    });

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (id: any) => AccountKYCAPI.delete({ id }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          queryClient.invalidateQueries(['get-account-kyc-list']);
          queryClient.invalidateQueries(['get-summary-account-kyc-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleDelete = (record: any) =>
    confirm.warning({
      title: 'Xác nhận xóa KYC',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa KYC</p>
          <p>
            <b>{record?.account.user.full_name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateDelete(record?.id),
    });

  const itemActions = (record: any): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () => navigate(`${ACCOUNT_KYC_URL.detail.path}/${record.id}`),
      },
      ...(!_.isEqual(record.status, ACCOUNT_KYC_STATUS.APPROVAL)
        ? [
            {
              key: '2',
              label: 'Duyệt',
              onClick: () => handleApproval(record),
            },
          ]
        : []),
      ...(!_.isEqual(record.status, ACCOUNT_KYC_STATUS.APPROVAL) &&
      !_.isEqual(record.status, ACCOUNT_KYC_STATUS.REFUSED)
        ? [
            {
              key: '3',
              label: 'Từ chối',
              onClick: () => handleRefused(record),
            },
          ]
        : []),
      {
        key: '4',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      },
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Người dùng',
      dataIndex: 'account',
      width: 200,
      render: (account) => (
        <HoverQuickView
          avatarUrl={account?.user.avatar}
          name={account?.user.full_name}
          code={account?.id}
          email={account?.email}
          phone={account?.phone}
        >
          <TextNavigate to={`${USER_ACCOUNT_URL.detail.path}/${account?.id}`}>
            {account?.user.full_name}
          </TextNavigate>
        </HoverQuickView>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 175,
      render: (created_at) => formatDate(created_at, DATE_TIME_FORMAT),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) => (
        <Tag color={TABLE_STATUS_COLOR[status]} className="font-[500]">
          {TABLE_STATUS_TEXT[status]}
        </Tag>
      ),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record: any) => (
        <ActionDropdown dropdownItems={itemActions(record)} trigger="click" />
      ),
    },
  ];

  return (
    <TableTera
      rowKey="id"
      columns={columns}
      loading={
        props?.loading ||
        isLoadingApproval ||
        isLoadingRefused ||
        isLoadingDelete
      }
      className="center-table"
      {...props}
    />
  );
};

export default Table;
