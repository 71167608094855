import { IRouteProps } from '_common/interface/router';
import ListPage from '.';
import CreateUpdatePage from './pages/CreateUpdate';
import { FINANCE_PAYMENT_METHOD_URL } from './url';

export const FinancePaymentMethodRouter: IRouteProps[] = [
  {
    key: FINANCE_PAYMENT_METHOD_URL.list.key,
    path: FINANCE_PAYMENT_METHOD_URL.list.shortenUrl,
    component: <ListPage />,
  },
  {
    key: FINANCE_PAYMENT_METHOD_URL.create.key,
    path: FINANCE_PAYMENT_METHOD_URL.create.shortenUrl,
    component: <CreateUpdatePage />,
  },
  {
    key: FINANCE_PAYMENT_METHOD_URL.update.key,
    path: FINANCE_PAYMENT_METHOD_URL.update.shortenUrl,
    component: <CreateUpdatePage />,
  },
];
