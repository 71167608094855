import { FILTER_TIME } from '_common/component/DailyTime/constants';
import { messageWarning } from '_common/constants/message';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Row } from 'tera-dls';
import { StatisticCashExpenseByPaymentMethodParamContext } from '..';
import {
  convertDateToTime,
  convertTimeToDate,
} from '_common/component/DailyTime/utils';
import {
  CASH_RECEIPT_TYPE,
  EXPENSE_VOUCHER_TYPE,
} from 'pages/Finance/InvoiceManagement/_common/constants';
import { DATE_BACKEND_FORMAT, DATE_FORMAT } from '_common/constants/common';
import RangePicker from '_common/dof/Control/RangePicker';

interface IProps {
  open: boolean;
  onClose?: () => void;
  onSubmit: (value: any) => void;
  defaultValues?: any;
}

const SettingModal = (props: IProps) => {
  const { onClose, open, onSubmit, defaultValues } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();

  const { setContextParams } = useContext(
    StatisticCashExpenseByPaymentMethodParamContext,
  );

  useEffect(() => {
    if (defaultValues) {
      form.reset({
        ...defaultValues,
        ...(defaultValues.expense_date?.[0] && {
          expense_date: [
            moment(defaultValues.expense_date?.[0]),
            moment(defaultValues.expense_date?.[1]),
          ],
        }),
        ...(defaultValues.receipt_date?.[0] && {
          receipt_date: [
            moment(defaultValues.receipt_date?.[0]),
            moment(defaultValues.receipt_date?.[1]),
          ],
        }),
      });
    }
  }, [defaultValues]);

  const isDirty = form?.formState?.isDirty;

  const handleSubmitForm = (value: any): void => {
    const data = {
      ...value,
      ...(!!value.receipt_date?.[0] && {
        receipt_date: [
          value.receipt_date[0]?.format(DATE_BACKEND_FORMAT),
          value.receipt_date[1]?.format(DATE_BACKEND_FORMAT),
        ],
      }),
      ...(!!value.expense_date?.[0] && {
        expense_date: [
          value.expense_date[0]?.format(DATE_BACKEND_FORMAT),
          value.expense_date[1]?.format(DATE_BACKEND_FORMAT),
        ],
      }),
    };
    setContextParams(data);
    onSubmit(data);
    onClose();
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const optionsTime = Object.entries(FILTER_TIME).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const handleChangeTime = (values) => {
    if (!values) return;
    const data = convertDateToTime(values);
    form?.setValue('receipt_date', data);
    form?.setValue('expense_date', data);

    values !== 'custom' && form.trigger(['receipt_date', 'expense_date']);
  };

  const handleChangeDate =
    (type: 'cashReceipt' | 'expenseVoucher') => (values: any) => {
      if (!values) {
        form?.setValue('time', 'custom');
        return;
      }
      const isCashType = type === 'cashReceipt';

      const cashDate = isCashType ? values : form.getValues('receipt_date');

      const expenseDate = isCashType ? form.getValues('expense_date') : values;

      if (
        !cashDate?.[0]?.isSame(expenseDate?.[0], 'day') ||
        !cashDate?.[1]?.isSame(expenseDate?.[1], 'day')
      ) {
        form?.setValue('time', 'custom');
        return;
      }
      const data = convertTimeToDate(values);
      form?.setValue('time', data);
    };

  const expenseVoucherTypeOptions = Object.entries(EXPENSE_VOUCHER_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const cashReceiptTypeOptions = Object.entries(CASH_RECEIPT_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  return (
    <Modal
      title={'Tham số báo cáo'}
      destroyOnClose
      closeIcon={false}
      width={500}
      cancelText="Hủy"
      okText="Lưu"
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
    >
      <FormTera form={form} onSubmit={handleSubmitForm}>
        <Row className="grid grid-cols-1">
          {/* <FormTeraItem
            name="location_id"
            label="Chi nhánh"
            className="col-span-1"
          >
            <SelectLocationId
              placeholder="Vui lòng chọn"
              allowClear={false}
              disabled
            />
          </FormTeraItem> */}
          <FormTeraItem label="Thời gian" name={'time'}>
            <Select
              allowClear
              placeholder="Vui lòng chọn"
              showSearch
              className="bg-white rounded-[3px]"
              options={[
                { value: 'custom', label: 'Tùy chỉnh' },
                ...optionsTime,
              ]}
              onSelect={handleChangeTime}
            />
          </FormTeraItem>
          <div className="mb-2.5 bg-gray-50 border border-gray-200 col-span-1 grid grid-cols-1 gap-x-5 p-2.5 pb-0 rounded-[6px]">
            <FormTeraItem
              className="col-span-1"
              label="Loại phiếu thu"
              name="receipt_type"
            >
              <Select
                placeholder="Vui lòng chọn"
                options={cashReceiptTypeOptions}
                mode="multiple"
                allowClear
              />
            </FormTeraItem>
            <FormTeraItem
              label="Ngày phiếu thu"
              name={'receipt_date'}
              rules={[{ required: 'Vui lòng chọn' }]}
            >
              <RangePicker
                format={DATE_FORMAT}
                placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                className="bg-white rounded-[3px]"
                onCustomChange={handleChangeDate('cashReceipt')}
              />
            </FormTeraItem>
          </div>
          <div className="mb-2.5 bg-gray-50 border border-gray-200 col-span-1 grid grid-cols-1 gap-x-5 p-2.5 pb-0 rounded-[6px]">
            <FormTeraItem
              className="col-span-1"
              label="Loại phiếu chi"
              name="expense_type"
            >
              <Select
                placeholder="Vui lòng chọn"
                options={expenseVoucherTypeOptions}
                mode="multiple"
                allowClear
              />
            </FormTeraItem>
            <FormTeraItem
              label="Ngày phiếu chi"
              name={'expense_date'}
              rules={[{ required: 'Vui lòng chọn' }]}
            >
              <RangePicker
                format={DATE_FORMAT}
                placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                className="bg-white rounded-[3px]"
                onCustomChange={handleChangeDate('expenseVoucher')}
              />
            </FormTeraItem>
          </div>
        </Row>
      </FormTera>
    </Modal>
  );
};

export default SettingModal;
