import { useQuery } from '@tanstack/react-query';
import { SERVICE_TYPE_TEXT } from '_common/constants/serviceManagement';
import { observer } from 'mobx-react-lite';
import DashboardAPI from 'pages/Overview/apis';
import { useEffect, useMemo } from 'react';
import Content from './containers/Content';

const Statistic = () => {
  const {
    data: dataOverviewStatistic,
    refetch: refetchDataOverviewStatistic,
    isLoading: isLoadingDataOverviewStatistic,
  } = useQuery(
    ['get-dashboard-overview-statistic'],
    () => {
      return DashboardAPI.getOverviewStatistic();
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    refetchDataOverviewStatistic();
  }, []);

  const data = useMemo(() => {
    const labels =
      dataOverviewStatistic?.map(
        (statistic: any) => SERVICE_TYPE_TEXT[statistic.type],
      ) || [];
    const value =
      dataOverviewStatistic?.map((statistic: any) =>
        Number(statistic.total_amount),
      ) || [];

    return {
      labels,
      datasets: [
        {
          data: value,
          fill: false,
          borderColor: '#F98080',
          backgroundColor: '#F98080',
        },
      ],
    };
  }, [dataOverviewStatistic]);

  return (
    <Content
      chartClassName="!h-[400px]"
      value={data}
      loading={isLoadingDataOverviewStatistic}
    />
  );
};

export default observer(Statistic);
