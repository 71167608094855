export const AFFILIATES_AGENCY_URL = {
  list: {
    key: '',
    path: '/affiliates/agency/list',
    shortenUrl: 'agency/list',
  },
  detail: {
    key: '',
    path: '/affiliates/agency/detail',
    shortenUrl: 'agency/detail/:id',
  },
};
