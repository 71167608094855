import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const SecurityAPIEndpoint = `${endpoint}/admin/security`;

const SecurityAPI = {
  getSecurityIPList: async ({ params }) =>
    await api
      .get(`${SecurityAPIEndpoint}/list-security-ip`, params)
      .then((res) => res.data?.data),
  createSecurityIP: async ({ params }) =>
    await api
      .post(`${SecurityAPIEndpoint}/create-security-ip`, params)
      .then((res) => res.data),
  deleteSecurityIP: async ({ account_id, id }) =>
    await api
      .delete(`${SecurityAPIEndpoint}/delete-security-ip/${account_id}/${id}`)
      .then((res) => res.data),
  getSecuritySSHKeyList: async ({ params }) =>
    await api
      .get(`${SecurityAPIEndpoint}/list-security-ssh-key`, params)
      .then((res) => res.data?.data),
  createSecuritySSHKey: async ({ params }) =>
    await api
      .post(`${SecurityAPIEndpoint}/create-security-ssh-key`, params)
      .then((res) => res.data),
  deleteSecuritySSHKey: async ({ account_id, id }) =>
    await api
      .delete(
        `${SecurityAPIEndpoint}/delete-security-ssh-key/${account_id}/${id}`,
      )
      .then((res) => res.data),
};

export default SecurityAPI;
