import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  PaginationProps,
  PlusOutlined,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import PaymentMethodAPI from './apis';
import Filter from './components/Filter';
import Search from './components/Search';
import Table from './components/Table';
import { FINANCE_PAYMENT_METHOD_URL } from './url';

const ListPage = (): JSX.Element => {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateFilter = (values: any) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...values,
    });
    navigate(`${FINANCE_PAYMENT_METHOD_URL.list.path}${paramString}`);
  };

  const handleSearch = (values: any) => {
    handleUpdateFilter({ keyword: values?.keyword, page: 1 });
  };

  const handleFilter = (values: any) => {
    handleUpdateFilter({ ...values, page: 1 });
  };

  const {
    data: dataList,
    refetch: refetchDataList,
    isLoading: isLoadingDataList,
  } = useQuery(
    ['get-finance-payment-method-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
        account_id: 0,
      };
      return PaymentMethodAPI.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateFilter({ page: data?.last_page });
        }
      },
    },
  );

  useEffect(() => {
    refetchDataList();
  }, []);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(dataList?.limit || 10);
    handleUpdateFilter({ page: isDiffPageSize ? 1 : page, limit: pageSize });
  };

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách Phương thức thanh toán"
        actionRight={
          <div className="flex gap-2">
            <Button
              prefix={<PlusOutlined />}
              className="bg-blue-500"
              onClick={() => navigate(FINANCE_PAYMENT_METHOD_URL.create.path)}
            >
              Thêm mới
            </Button>
          </div>
        }
        onClickFilter={() => setIsOpenFilter(true)}
        actionLeftRender={<Search onSearch={handleSearch} />}
        filterCount={{
          filter: queryParams,
          params: ['payment_method_type', 'payment_method_bank'],
        }}
      >
        <Table
          rowKey={'id'}
          data={dataList?.data || []}
          loading={isLoadingDataList}
          pagination={{
            onChange: handleChangePage,
            total: dataList?.total || 0,
            current: dataList?.current_page,
            pageSize: dataList?.per_page || 10,
            to: dataList?.to,
            from: dataList?.from || 10,
          }}
        />
      </HeaderViewList>
      {isOpenFilter && (
        <Filter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
};

export default ListPage;
