import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import ModalChangePassword from '_common/component/ModalChangePassword';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  Button,
  PaginationProps,
  PlusOutlined,
  TabItemType,
  Tabs,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import UserAccountAPI from './apis';
import Search from './components/Search';
import Table from './components/Table';
import { TABS_STATUS_ITEM, TABS_STATUS_TEXT } from './contants';
import { USER_ACCOUNT_URL } from './url';

const ListPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const [modalResetPassword, setModalResetPassword] = useState<boolean>(false);

  const handleUpdateFilter = (values: any) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...values,
    });
    navigate(`${USER_ACCOUNT_URL.list.path}${paramString}`);
  };

  const handleChangeTab = (key: string) => {
    handleUpdateFilter({ status: key, page: 1 });
  };

  const handleSearch = (values: any) => {
    handleUpdateFilter({ keyword: values?.keyword, page: 1 });
  };

  const {
    data: dataList,
    refetch: refetchDataList,
    isLoading: isLoadingDataList,
  } = useQuery(
    ['get-user-account-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return UserAccountAPI.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    if (!_.isEmpty(dataList))
      if (Number(dataList?.last_page) < (Number(queryParams?.page) || 1))
        handleUpdateFilter({ page: dataList?.last_page });
  }, [dataList]);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(dataList?.limit || 10);
    handleUpdateFilter({ page: isDiffPageSize ? 1 : page, limit: pageSize });
  };

  const { data: summaryList, refetch: refetchSummary } = useQuery(
    ['get-summary-user-account-list', queryParams],
    () => {
      delete queryParams.status;

      return UserAccountAPI.getSummary({
        params: {
          ...queryParams,
          page: queryParams?.page || 1,
          limit: queryParams?.limit || 10,
        },
      });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const tabItems: Array<TabItemType> = useMemo(
    () =>
      TABS_STATUS_ITEM.map((item) => {
        const count =
          summaryList?.find((i) => i?.status == item)?.total_count || 0;

        return {
          key: item,
          label: (
            <div className="flex items-center gap-x-2">
              {TABS_STATUS_TEXT[item]} <Badge count={count} showZero />
            </div>
          ),
        };
      }),
    [summaryList],
  );

  useEffect(() => {
    refetchDataList();
    refetchSummary();
  }, []);

  return (
    <>
      <div className="tera-page">
        <HeaderViewList
          title="Danh sách Người dùng"
          actionRight={
            <div className="flex gap-2">
              <Button
                prefix={<PlusOutlined />}
                className="bg-blue-500"
                onClick={() => navigate(USER_ACCOUNT_URL.create.path)}
              >
                Thêm mới
              </Button>
            </div>
          }
          middleContent={
            <Tabs
              onChange={handleChangeTab}
              items={tabItems}
              activeKey={queryParams?.status || 'all'}
              className="mb-0"
            />
          }
          actionLeftRender={<Search onSearch={handleSearch} />}
          filterCount={{
            filter: queryParams,
            params: ['date', 'vehicle_from', 'vehicle_to'],
          }}
        >
          <Table
            rowKey={'id'}
            data={dataList?.data || []}
            loading={isLoadingDataList}
            pagination={{
              onChange: handleChangePage,
              total: dataList?.total || 0,
              current: dataList?.current_page,
              pageSize: dataList?.per_page || 10,
              to: dataList?.to,
              from: dataList?.from || 10,
            }}
          />
        </HeaderViewList>
      </div>
      {modalResetPassword && (
        <ModalChangePassword
          userId={1}
          open={modalResetPassword}
          onClose={() => setModalResetPassword(false)}
          onSuccess={() => navigate('/login')}
        />
      )}
    </>
  );
};

export default ListPage;
