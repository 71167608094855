import { IRouteProps } from '_common/interface/router';
import ListPage from '.';
import DetailPage from './pages/Detail';
import { AFFILIATES_AGENCY_URL } from './url';

export const AffiliatesAgencyRouter: IRouteProps[] = [
  {
    key: AFFILIATES_AGENCY_URL.list.key,
    path: AFFILIATES_AGENCY_URL.list.shortenUrl,
    component: <ListPage />,
  },
  {
    key: AFFILIATES_AGENCY_URL.detail.key,
    path: AFFILIATES_AGENCY_URL.detail.shortenUrl,
    component: <DetailPage />,
  },
];
