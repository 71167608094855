export const SERVICE_HOSTING_URL = {
  list: {
    key: '',
    path: '/service/hosting/list',
    shortenUrl: 'hosting/list',
  },
  create: {
    key: '',
    path: '/service/hosting/create',
    shortenUrl: 'hosting/create',
  },
  paymentInformation: {
    key: '',
    path: '/service/hosting/payment-information',
    shortenUrl: 'hosting/payment-information',
  },
  payment: {
    key: '',
    path: '/service/hosting/payment',
    shortenUrl: 'hosting/payment',
  },
  detail: {
    key: '',
    path: '/service/hosting/detail',
    shortenUrl: 'hosting/detail/:id',
  },
};
