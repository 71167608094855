import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const LocationAPIEndpoint = `${endpoint}/admin/location`;

const LocationAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${LocationAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${LocationAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${LocationAPIEndpoint}/create`, params)
      .then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${LocationAPIEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${LocationAPIEndpoint}/delete/${id}`)
      .then((res) => res.data),
};

export default LocationAPI;
