export const SERVICE_CLOUD_VPS_URL = {
  list: {
    key: '',
    path: '/service/cloud-vps/list',
    shortenUrl: 'cloud-vps/list',
  },
  create: {
    key: '',
    path: '/service/cloud-vps/create',
    shortenUrl: 'cloud-vps/create',
  },
  detail: {
    key: '',
    path: '/service/cloud-vps/detail',
    shortenUrl: 'cloud-vps/detail/:id',
  },
  update: {
    key: '',
    path: '/service/cloud-vps/update',
    shortenUrl: 'cloud-vps/update/:id',
  },
  upgrade: {
    key: '',
    path: '/service/cloud-vps/upgrade',
    shortenUrl: 'cloud-vps/upgrade/:id',
  },
  upgradePayment: {
    key: '',
    path: '/service/cloud-vps/upgrade-payment',
    shortenUrl: 'cloud-vps/upgrade-payment/:id',
  },
  reinstallOS: {
    key: '',
    path: '/service/cloud-vps/reinstall-os',
    shortenUrl: 'cloud-vps/reinstall-os/:id',
  },
};
