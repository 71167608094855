import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Tabs, getQueryParams, updateQueryParams } from 'tera-dls';
import HistoryDetail from './HistoryDetail';
import Inform from './Inform';
import Tip from './Tip';

const History = (): JSX.Element => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data: any) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleChangeTab = (key: string) => {
    handleUpdateQueryParams({ subTab: key });
  };

  const tabItems = [
    {
      key: 'tip',
      label: 'Hoa hồng của tôi',
    },
    {
      key: 'history',
      label: 'Lịch sử email đã gửi',
    },
    {
      key: 'inform',
      label: 'Thông báo',
    },
  ];

  const tabData = {
    ['tip']: <Tip />,
    ['history']: <HistoryDetail />,
    ['inform']: <Inform />,
  };

  return (
    <Col className="flex flex-col gap-4">
      <Tabs
        onChange={handleChangeTab}
        items={tabItems}
        activeKey={queryParams?.subTab || tabItems[0].key}
        className="mb-0 pt-0 bg-white rounded-t-md"
      />
      {tabData[queryParams?.subTab || tabItems[0].key]}
    </Col>
  );
};

export default History;
