export const renderDescription = (data, width = 150) =>
  data?.map((item, index) => (
    <div className="flex gap-5" key={item?.key ?? index}>
      <h6
        className={` !text-gray-500 !font-normal detail-key w-full`}
        style={{
          minWidth: width,
          maxWidth: width,
        }}
      >
        {item?.title}
      </h6>
      <span className=" w-full detail-value !text-gray-700 !font-normal">
        {item?.value}
      </span>
    </div>
  ));

export const mergeAlternate = (obj1, obj2) => {
  const merged = {};
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  for (let i = 0; i < keys1.length; i++) {
    merged[keys1[i]] = obj1[keys1[i]];
    merged[keys2[i]] = obj2[keys2[i]];
  }

  return merged;
};
