import menu from '../component/Layout/Menu/menu';

const useSubMenu = (active?) => {
  const getSubMenu = (targetParentKey) =>
    menu.subMenu.filter((item) => item?.parentKey === targetParentKey);

  const menus = menu.parentMenu
    ?.filter((item) => item?.parentKey === (active || 'admin'))
    ?.map((parent) => {
      const defaultChildren = getSubMenu(parent.key);
      return { ...parent, children: defaultChildren ?? [] };
    });

  return menus;
};

export default useSubMenu;
