import TeraSearch from '_common/dof/Control/Search';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { MagnifyingGlassOutlined, getQueryParams } from 'tera-dls';

interface ISearchProps {
  onSearch: (values: any) => void;
}

const Search = ({ onSearch }: ISearchProps) => {
  const form = useForm({ mode: 'onChange', defaultValues: { keyword: '' } });
  const { isDirty } = form.formState;
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  useEffect(() => {
    if (queryParams?.keyword)
      form.reset({
        keyword: queryParams?.keyword,
      });
  }, [queryParams?.keyword]);

  const handleSubmitForm = (values: any) => {
    if (isDirty) {
      onSearch(values);
      form.reset({ ...values }, { keepValues: true });
    }
  };

  return (
    <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
      <FormTeraItem name="keyword" className="!mb-0">
        <TeraSearch
          placeholder="Tìm kiếm theo mã hóa đơn"
          icon={<MagnifyingGlassOutlined />}
          className="!py-[6px]"
        />
      </FormTeraItem>
    </FormTera>
  );
};

export default Search;
