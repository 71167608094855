import { useMutation, useQuery } from '@tanstack/react-query';
import CardDetail from '_common/component/CardDetail';
import FormSummary from '_common/component/Form/Summary';
import { SERVICE } from '_common/constants/serviceManagement';
import FormTera from '_common/dof/FormTera';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ServiceAPI from 'states/api/ServiceAPI';
import ServiceSubtypeAPI from 'states/api/ServiceSubtypeAPI';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Col,
  Row,
  Spin,
  notification,
} from 'tera-dls';
import { SERVICE_EMAIL_SECURITY_URL } from '../../url';
import FormAddBusiness from './components/FormAddBusiness';
import FormAddRelay from './components/FormAddRelay';

const CreateUpdatePage = (): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      planSubtype: '',
      product: '',
      period: 'monthly',
      domain_settings: {
        name: 'tenmien',
        top_level: 1,
        duration: 1,
        epp_code: '',
        bought_domain: {
          option: 1,
          name: 'tenmien',
          cart: 1,
        },
        hostname: '',
      },
      registrant: '',
      promo_code: '',
    },
  });
  const { id } = useParams();
  const navigate = useNavigate();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Email và Bảo mật
        </span>
      ),
      onClick: () => navigate(SERVICE_EMAIL_SECURITY_URL.list.path),
    },
    {
      title: 'Thêm cấu hình Email và Bảo mật',
    },
  ];

  const {
    data: dataSubtypeList,
    isLoading: isLoadingDataSubtypeList,
    refetch: refetchDataSubtypeList,
  } = useQuery(
    ['get-service-subtype-list'],
    () => {
      const params = {
        type: SERVICE.EMAIL,
      };
      return ServiceSubtypeAPI.getSubtypeList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    refetchDataSubtypeList();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(dataSubtypeList))
      form.reset({
        ...form.getValues(),
        planSubtype: dataSubtypeList[0].code,
      });
  }, [dataSubtypeList]);

  const { mutate: mutateCreateUpdate, isLoading: isLoadingCreateUpdate } =
    useMutation(
      (params: any) =>
        id ? ServiceAPI.update({ id, params }) : ServiceAPI.create({ params }),
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            notification.success({
              message: res?.msg,
            });
            navigate(SERVICE_EMAIL_SECURITY_URL.list.path);
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleSubmitForm = (values: any) => {
    if (isLoadingCreateUpdate) return;
    mutateCreateUpdate({
      type: SERVICE.EMAIL,
      datacenterId: values.datacenter.datacenterId,
      planId: values.product.planId,
      locationId: values.locationId,
      osId: values.osId,
      period: values.period,
      domain: values.domain,
      isAutoRenew: values.isAutoRenew,
      userName: values.userName,
      registrant: values.registrant,
      promo_code: values.promo_code,
      note: values.note,
    });
  };

  return (
    <div className="tera-page-form min-h-[calc(100vh_-_45px)]">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
      </div>
      <Col className="px-2">
        <CardDetail className="p-4 mb-4 flex flex-col gap-4">
          <Spin spinning={isLoadingDataSubtypeList}>
            <span>
              Giải pháp hosting cho doanh nghiệp cao cấp và mạnh mẽ nhất, sử
              dụng toàn bộ ổ cứng NVME U.2 RAID 10 cùng vi xử lý AMD EPYC/Intel
              Xeon Scalable Gen 2 cao cấp nhất chuyên dụng dành cho máy chủ, hệ
              thống mạng riêng biệt 10Gbps tốc độ cao giúp tăng sự chịu tải và
              tốc độ cho website.
            </span>
            <Row className="flex justify-center gap-6">
              {dataSubtypeList?.map((subtype: any) => (
                <div
                  key={subtype.code}
                  onClick={() => form.setValue('planSubtype', subtype.code)}
                  className={`w-[300px] bg-white p-6 py-8 rounded-[10px] drop-shadow-xl flex flex-col items-center gap-4 cursor-pointer ${
                    form.watch('planSubtype') === subtype.code &&
                    'text-blue-800 !bg-[#EBF5FF]'
                  }`}
                >
                  <div className="w-full h-[150px] bg-[#D9D9D9] rounded-[10px]" />
                  <span className="w-full text-blue-800 font-[500]">
                    {subtype.name}
                  </span>
                </div>
              ))}
            </Row>
          </Spin>
        </CardDetail>
        <FormTera form={form} className="flex-1 flex flex-col">
          {form.watch('planSubtype') === dataSubtypeList?.[0]?.code && (
            <FormAddBusiness form={form} />
          )}
          {form.watch('planSubtype') === dataSubtypeList?.[1]?.code && (
            <FormAddRelay form={form} />
          )}
          {form.watch('product') && (
            <FormSummary
              form={form}
              handleSubmitForm={form.handleSubmit(handleSubmitForm)}
            />
          )}
        </FormTera>
      </Col>
    </div>
  );
};

export default CreateUpdatePage;
