import { useMutation, useQueryClient } from '@tanstack/react-query';
import ModalChangePassword from '_common/component/ModalChangePassword';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { IOpenModalState } from '_common/interface';
import _ from 'lodash';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnsType, DropdownItem, Tag, notification } from 'tera-dls';
import CountryAPI from '../apis';
import { TABLE_STATUS_COLOR, TABLE_STATUS_TEXT } from '../contants';
import { USER_ACCOUNT_URL } from '../url';

const Table = (props: ITeraTableProps): JSX.Element => {
  const [isOpenModalChangePassword, setIsOpenModalChangePassword] =
    useState<IOpenModalState>({ isOpen: false, id: null });
  const confirm = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (id: any) => CountryAPI.delete({ id }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-user-account-list']);
          queryClient.invalidateQueries(['get-summary-user-account-list']);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleDelete = (record: any) =>
    confirm.warning({
      title: 'Xác nhận xóa người dùng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa người dùng</p>
          <p>
            <b>{record?.user?.full_name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateDelete(record?.id),
    });

  const itemActions = (record: any): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () => navigate(`${USER_ACCOUNT_URL.detail.path}/${record.id}`),
      },
      {
        key: 2,
        label: 'Đổi mật khẩu',
        onClick: () =>
          setIsOpenModalChangePassword({ isOpen: true, id: record.user.id }),
      },
      {
        key: '3',
        label: 'Sửa',
        onClick: () => navigate(`${USER_ACCOUNT_URL.update.path}/${record.id}`),
      },
      {
        key: '4',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      },
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      width: 150,
      render: (_, record) => record.user?.username,
    },
    {
      title: 'Họ và tên',
      dataIndex: 'full_name',
      width: 150,
      render: (_, record) => record.user?.full_name,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      width: 150,
    },
    {
      title: 'Email liên hệ',
      dataIndex: 'email',
      width: 200,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) => (
        <Tag color={TABLE_STATUS_COLOR[status]}>
          {TABLE_STATUS_TEXT[status]}
        </Tag>
      ),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record: any) => {
        return (
          <ActionDropdown dropdownItems={itemActions(record)} trigger="click" />
        );
      },
    },
  ];

  return (
    <Fragment>
      <TableTera
        rowKey="id"
        columns={columns}
        loading={props?.loading || isLoadingDelete}
        className="center-table"
        {...props}
      />
      {isOpenModalChangePassword.isOpen && (
        <ModalChangePassword
          open={isOpenModalChangePassword.isOpen}
          onClose={() => setIsOpenModalChangePassword({ isOpen: false })}
          userId={Number(isOpenModalChangePassword.id)}
        />
      )}
    </Fragment>
  );
};

export default Table;
