import { IRouteProps } from '_common/interface/router';
import Overview from '.';
import { OVERVIEW_URL } from './url';

export const OverviewRouter: IRouteProps[] = [
  {
    key: OVERVIEW_URL.list.key,
    path: OVERVIEW_URL.list.shortenUrl,
    component: <Overview />,
  },
];
