import { Button, Modal } from 'tera-dls';
import Content, { SaleOfCustomerTypeChartType } from './Content';

interface IProps {
  open: boolean;
  params: any;
  onClose?: () => void;
  type?: SaleOfCustomerTypeChartType;
  value?: any;
  loading?: boolean;
}
const ModalChart = (props: IProps) => {
  const { onClose, open, type, params, value, loading } = props;
  return (
    <Modal
      title={''}
      destroyOnClose
      closeIcon={false}
      closable
      onCancel={onClose}
      className="modal-fullscreen"
      open={open}
      centered={true}
      footer={<Button onClick={onClose}>Đóng</Button>}
    >
      <Content
        params={params}
        type={type}
        onClose={onClose}
        chartOptions={{ maintainAspectRatio: false }}
        value={value}
        loading={loading}
      />
    </Modal>
  );
};

export default ModalChart;
