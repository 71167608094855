import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const ExpenseVoucherEndpoint = `${endpoint}/admin/finance/accounting-voucher/payment`;
const ExpenseVoucherApi = {
  getList: async ({ params }) =>
    await api
      .get(`${ExpenseVoucherEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${ExpenseVoucherEndpoint}/create`, params)
      .then((result) => result?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${ExpenseVoucherEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${ExpenseVoucherEndpoint}/detail/${id}`)
      .then((data) => data?.data?.data),
  getCodeSuggestion: async () =>
    await api
      .get(`${ExpenseVoucherEndpoint}/suggest-code`)
      .then((data) => data?.data?.data),
  getStatistic: async () =>
    await api
      .get(`${ExpenseVoucherEndpoint}/revenue`)
      .then((data) => data?.data?.data),
  delete: async ({ id, params }) =>
    await api
      .delete(`${ExpenseVoucherEndpoint}/delete/${id}`, params)
      .then((result) => result?.data),
  getExplainList: async ({ id, params }) =>
    await api
      .get(`${ExpenseVoucherEndpoint}/explain/list/${id}`, params)
      .then((data) => data?.data?.data),
  saveExplain: async ({ params }) =>
    await api
      .put(`${ExpenseVoucherEndpoint}/save-explain`, params)
      .then((result) => result?.data),
  createExplain: async ({ params }) =>
    await api
      .post(`${ExpenseVoucherEndpoint}/explain/create`, params)
      .then((result) => result?.data),
  updateExplain: async ({ id, params }) =>
    await api
      .put(`${ExpenseVoucherEndpoint}/explain/update/${id}`, params)
      .then((result) => result?.data),
  deleteExplain: async ({ id }) =>
    await api
      .delete(`${ExpenseVoucherEndpoint}/explain/delete/${id}`)
      .then((result) => result?.data),
};
export default ExpenseVoucherApi;
