export enum OS_STATUS {
  ACTIVE = 'Active',
  DEACTIVATE = 'Suspended',
}
export const TABS_STATUS_ITEM = ['all', 'Active', 'Deactivate'];
export const TABS_STATUS_TEXT = {
  all: 'Tất cả',
  Active: 'Đang hoạt động',
  Deactivate: 'Đã hủy',
};
export const TABLE_STATUS_COLOR = {
  Active: 'green03',
  Deactivate: 'red03',
};
export const TABLE_STATUS_TEXT = {
  Active: 'Đang hoạt động',
  Deactivate: 'Đã hủy',
};
