import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import { ImportExportType } from '_common/component/ImportExport/constants';
import ModalImport from '_common/component/ModalImport';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  PaginationProps,
  filterField,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import CashReceiptApi from './api';
import CashReceiptFilter from './containers/Filter';
import Searching from './containers/Searching';
import Statistic from './containers/Statistic';
import CashReceiptTable from './containers/Table';
import { CASH_RECEIPT_URL } from './url';

const CashReceipt = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [openImport, setOpenImport] = useState<boolean>(false);
  const [errorImport, setErrorImport] = useState<any | undefined>();

  const {
    data: response,
    isFetching,
    refetch,
  } = useQuery(
    ['get-cash-receipt-list', queryParams],
    () =>
      CashReceiptApi.getList({
        params: filterField({
          ...queryParams,
          page: queryParams?.page ?? 1,
          limit: queryParams?.limit ?? 10,
        }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const list = response?.data;

  useEffect(() => {
    refetch();
  }, []);

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  // const { mutate: mutateImport } = useMutation(
  //   (variables: any) => ImportExportApi.import(variables),
  //   {
  //     onSuccess: (res) => {
  //       if (res?.code === 200) {
  //         queryClient.invalidateQueries(['get-cash-receipt-list']);
  //         queryClient.invalidateQueries(['get-cash-receipt-statistic']);
  //         setOpenImport(false);
  //         notification.success({
  //           message: res?.msg,
  //         });
  //       }
  //     },
  //     onError(error: any) {
  //       if (error?.data?.code === 501) {
  //         ErrorToast({ errorProp: error?.data });
  //         return;
  //       }
  //       setErrorImport(error?.data?.errors);
  //     },
  //   },
  // );

  // const { mutate: mutateExport } = useMutation(
  //   (variables: any) => CommonImportExportApi.export(variables),
  //   {
  //     onSuccess: (res) => {
  //       if (res?.code === 200) {
  //         notification.success({
  //           message: res?.msg,
  //         });
  //         setSelectedRowKeys([]);
  //         download(res?.data?.src, ImportExportType.receiptItem);
  //       }
  //     },
  //     onError(error: any) {
  //       ErrorToast({ errorProp: error?.data });
  //     },
  //   },
  // );

  // const dropdownItems = [
  //   // {
  //   //   key: 1,
  //   //   label: <a>Cấu hình cột</a>,
  //   //   onClick: () => openModal(),
  //   // },
  //   // {
  //   //   key: 2,
  //   //   label: <a>Import file excel</a>,
  //   //   onClick: () => {
  //   //     setOpenImport(true);
  //   //   },
  //   // },
  //   {
  //     key: 3,
  //     label: <a>Export file excel</a>,
  //     onClick: () => console.log(),

  //     // mutateExport({
  //     //   params: {
  //     //     item_code: ImportExportType.receiptItem,
  //     //     selected_ids: selectedRowKeys ?? [],
  //     //   },
  //     // }),
  //   },
  // ];

  const handleImport = () => {
    // mutateImport({
    //   params: {
    //     file: value?.file,
    //     item_code: ImportExportType.receiptItem,
    //     overwrite_all: Number(value?.overwrite),
    //   },
    // });
  };

  const handleSearch = ({ keyword }) => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: any): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    handleUpdateQueryParams({ page: page, limit: pageSize });
  };

  return (
    <>
      <div className="tera-page">
        <HeaderViewList
          title="PHIẾU THU TIỀN"
          onClickFilter={() => setOpenFilterModal(true)}
          onClickButtonAdd={() => navigate(CASH_RECEIPT_URL.create.path)}
          actionLeftRender={<Searching onSearch={handleSearch} />}
          outsideContent={<Statistic />}
          filterCount={{
            filter: queryParams,
            params: [
              'transaction_type',
              'methods',
              'date',
              'accounting_date',
              'transaction_date',
              'amount_from',
              'amount_to',
            ],
          }}
        >
          <CashReceiptTable
            loading={isFetching}
            data={list ?? []}
            // rowSelection={{
            //   selectedRowKeys,
            //   onChange: setSelectedRowKeys,
            // }}
            pagination={{
              onChange: handleChangePage,
              pageSize: response?.per_page,
              current: response?.current_page,
              total: response?.total,
              from: response?.from,
              to: response?.to,
            }}
          />
        </HeaderViewList>
        {openFilterModal && (
          <CashReceiptFilter
            open={openFilterModal}
            onClose={() => setOpenFilterModal(false)}
            onFilter={handleFilter}
            initialValue={queryParams}
          />
        )}
      </div>
      {openImport && (
        <ModalImport
          open={openImport}
          onOk={handleImport}
          onCancel={() => {
            setOpenImport(false);
            setErrorImport(undefined);
          }}
          type={ImportExportType.receiptItem}
          errorValue={errorImport}
        />
      )}
    </>
  );
};

export default CashReceipt;
