import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const DomainAPIEndpoint = `${endpoint}/admin/domain`;
const DomainAPIPortalEndpoint = `${endpoint}/portal/domain`;

const DomainAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${DomainAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  searchDomain: async (params) =>
    await api
      .post(`${DomainAPIPortalEndpoint}/search`, params)
      .then((res) => res.data),
  checkDomainAvailable: async (params) =>
    await api
      .post(`${DomainAPIPortalEndpoint}/lookup`, params)
      .then((res) => res.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${DomainAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${DomainAPIEndpoint}/create`, params)
      .then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${DomainAPIEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  active: async ({ id }) =>
    await api.put(`${DomainAPIEndpoint}/active/${id}`).then((res) => res.data),
  deactivate: async ({ id }) =>
    await api
      .put(`${DomainAPIEndpoint}/deactivate/${id}`)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${DomainAPIEndpoint}/delete/${id}`)
      .then((res) => res.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${DomainAPIEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
};

export default DomainAPI;
