import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import { SERVICE } from '_common/constants/serviceManagement';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ServiceAPI from 'states/api/ServiceAPI';
import {
  Badge,
  Button,
  PaginationProps,
  PlusOutlined,
  TabItemType,
  Tabs,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import Filter from './components/Filter';
import Search from './components/Search';
import Table from './components/Table';
import { TABS_STATUS_ITEM, TABS_STATUS_TEXT } from './constants';
import { SERVICE_SSL_URL } from './url';

const ListPage = (): JSX.Element => {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateFilter = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${SERVICE_SSL_URL.list.path}${paramString}`);
  };

  const handleChangeTab = (key: string) => {
    handleUpdateFilter({ status: key, page: 1 });
  };

  const handleSearch = (values: any) => {
    handleUpdateFilter({ keyword: values?.keyword, page: 1 });
  };

  const handleFilter = (values: any) => {
    handleUpdateFilter({ ...values, page: 1 });
  };

  const {
    data: dataList,
    refetch: refetchDataList,
    isLoading: isLoadingDataList,
  } = useQuery(
    ['get-service-ssl-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        type: SERVICE.SSL,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return ServiceAPI.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateFilter({ page: data?.last_page });
        }
      },
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(dataList?.limit || 10);

    handleUpdateFilter({ page: isDiffPageSize ? 1 : page, limit: pageSize });
  };

  const { data: summaryList, refetch: refetchSummary } = useQuery(
    ['get-summary-ssl-list', queryParams],
    () => {
      delete queryParams.status;

      return ServiceAPI.getSummary({
        params: {
          ...queryParams,
          page: queryParams?.page || 1,
          limit: queryParams?.limit || 10,
          type: SERVICE.SSL,
        },
      });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const tabItems: Array<TabItemType> = useMemo(
    () =>
      TABS_STATUS_ITEM.map((item) => {
        const count =
          summaryList?.find((i) => i?.status == item)?.total_count || 0;

        return {
          key: item,
          label: (
            <div className="flex items-center gap-x-2">
              {TABS_STATUS_TEXT[item]} <Badge count={count} showZero />
            </div>
          ),
        };
      }),
    [summaryList],
  );

  useEffect(() => {
    refetchDataList();
    refetchSummary();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách SSL"
        actionRight={
          <Button
            prefix={<PlusOutlined />}
            className="bg-blue-500"
            onClick={() => navigate(SERVICE_SSL_URL.create.path)}
          >
            Thêm mới
          </Button>
        }
        onClickFilter={() => setIsOpenFilter(true)}
        middleContent={
          <Tabs
            onChange={handleChangeTab}
            items={tabItems}
            activeKey={queryParams?.status || 'all'}
            className="mb-0"
          />
        }
        actionLeftRender={<Search onSearch={handleSearch} />}
        filterCount={{
          filter: queryParams,
          params: ['date', 'vehicle_from', 'vehicle_to'],
        }}
      >
        <Table
          rowKey={'id'}
          data={dataList?.data || []}
          loading={isLoadingDataList}
          pagination={{
            onChange: handleChangePage,
            total: dataList?.total || 0,
            current: dataList?.current_page,
            pageSize: dataList?.per_page || 10,
            to: dataList?.to,
            from: dataList?.from || 10,
          }}
        />
      </HeaderViewList>
      {isOpenFilter && (
        <Filter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
};

export default ListPage;
