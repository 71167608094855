import { DATE_FORMAT } from '_common/constants/common';
import RangePicker from '_common/dof/Control/RangePicker';
import Select from '_common/dof/Control/Select';
import { FormTeraItem } from '_common/dof/FormTera';
import { FILTER_TIME } from './constants';
import { convertDateToTime, convertTimeToDate } from './utils';

interface IProps {
  form: any;
  timeKey?: string;
  dateKey?: string;
  timeFormProps?: any;
  timeInputProps?: any;
  dateFormProps?: any;
  dateInputProps?: any;
  shouldDirty?: boolean;
}
const DailyTime = (props: IProps) => {
  const {
    form,
    timeKey = 'time',
    dateKey = 'date',
    timeFormProps = {},
    timeInputProps = {},
    dateFormProps = {},
    dateInputProps = {},
  } = props;

  const handleChangeTime = (values: any) => {
    if (!values) return;
    const data = convertDateToTime(values);
    form?.setValue(dateKey, data);
    if (
      dateFormProps?.rules &&
      dateFormProps?.rules?.length > 0 &&
      values !== 'custom'
    ) {
      form.trigger(dateKey);
    }
  };

  const handleChangeDate = (values: any) => {
    if (!values) {
      form?.setValue(timeKey, 'custom');
      return;
    }
    const data = convertTimeToDate(values);
    form?.setValue(timeKey, data);
  };

  const optionsTime = Object.entries(FILTER_TIME).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <>
      <FormTeraItem label="Thời gian" {...timeFormProps} name={timeKey}>
        <Select
          allowClear
          placeholder="Vui lòng chọn"
          showSearch
          className="bg-white rounded-[3px]"
          {...timeInputProps}
          options={[{ value: 'custom', label: 'Tùy chỉnh' }, ...optionsTime]}
          onClear={() => form?.setValue(dateKey, null)}
          onSelect={handleChangeTime}
        />
      </FormTeraItem>
      <FormTeraItem
        label="Từ ngày - đến ngày"
        {...dateFormProps}
        name={dateKey}
      >
        <RangePicker
          format={DATE_FORMAT}
          placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
          className="bg-white rounded-[3px]"
          {...dateInputProps}
          onCustomChange={handleChangeDate}
        />
      </FormTeraItem>
    </>
  );
};

export default DailyTime;
