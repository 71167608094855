import { useMutation, useQueryClient } from '@tanstack/react-query';
import { REGEX } from '_common/constants/common';
import { PAYMENT_METHOD_TYPE } from '_common/constants/invoiceManagement';
import { messageValidate } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectBank from '_common/dof/Select/SelectBank';
import useConfirm from '_common/hooks/useConfirm';
import PaymentMethodAPI from 'pages/Finance/PaymentManagement/PaymentMethod/apis';
import { useForm } from 'react-hook-form';
import { Modal, notification } from 'tera-dls';

interface IModalModalCreateUpdateProps {
  open: boolean;
  close: () => void;
  dataDetail?: any;
  id?: string | number;
}

const ModalCreateUpdate = ({
  open,
  close,
  dataDetail,
  id,
}: IModalModalCreateUpdateProps) => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      method_type: PAYMENT_METHOD_TYPE.BANK,
      type: null,
      type_id: '',
      account_number: '',
      account_name: '',
    },
  });
  const { isDirty } = form.formState;
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const handleCancel = () =>
    isDirty
      ? confirm.warning({
          title: 'Thoát bản ghi',
          content: (
            <div className="break-word">
              <p>Bạn có chắc muốn thoát bản ghi này không?</p>
              <p>Sau khi thoát dữ liệu sẽ không được lưu cấu hình.</p>
            </div>
          ),
          onOk: close,
        })
      : close();

  const { mutate: mutateCreateUpdate, isLoading: isLoadingCreateUpdate } =
    useMutation(
      (params: any) =>
        id
          ? PaymentMethodAPI.update({ id, params })
          : PaymentMethodAPI.create({ params }),
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            notification.success({
              message: res?.msg,
            });
            queryClient.invalidateQueries([
              'get-user-account-payment-method-list',
            ]);
            close();
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleSubmitForm = (values: any) => {
    if (isLoadingCreateUpdate) return;

    delete values.type;

    mutateCreateUpdate({
      ...values,
      account_name: values.account_name.toUpperCase(),
      account_id: dataDetail?.id,
    });
  };

  return (
    <Modal
      title={id ? 'Sửa thông tin thẻ' : 'Thêm thông tin thẻ'}
      open={open}
      centered={true}
      destroyOnClose
      okText="Lưu"
      cancelText="Hủy"
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={handleCancel}
      contentClassName="md:w-[500px] h-full"
      bodyClassName="!py-4"
    >
      <FormTera form={form} isLoading={false} className="flex flex-col gap-2">
        <FormTeraItem
          name="type_id"
          label="Ngân hàng"
          className="mb-0"
          rules={[{ required: messageValidate.emptySelect }]}
        >
          <SelectBank
            paramsApi={{
              type: form.watch('method_type'),
              include_id: form.watch('type_id'),
            }}
            onChangeCustom={(value) => form.setValue('type', value)}
          />
        </FormTeraItem>
        <FormTeraItem
          name="account_number"
          label="Số tài khoản"
          className="mb-0"
          rules={[
            {
              required: messageValidate.emptyText,
              pattern: {
                value: new RegExp(REGEX.CARD_NUMBER),
                message: messageValidate.format,
              },
            },
          ]}
        >
          <Input />
        </FormTeraItem>
        <FormTeraItem
          name="account_name"
          label="Người thụ hưởng"
          className="mb-0"
          rules={[{ required: messageValidate.emptyText }]}
        >
          <Input />
        </FormTeraItem>
      </FormTera>
    </Modal>
  );
};

export default ModalCreateUpdate;
