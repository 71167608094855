import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Tabs, getQueryParams, updateQueryParams } from 'tera-dls';
import AccountInformation from '../_common/components/AccountInformation';
import ChangePassword from '../_common/components/ChangePassword';
import Overview from '../_common/components/Overview';
import SetupSecurity from '../_common/components/SetupSecurity';

const MyInformation = () => {
  const {
    authStore: { user },
  } = useStores();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data: any) => {
    delete queryParams.subTab;

    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleChangeTab = (key: string) => {
    handleUpdateQueryParams({ tab: key });
  };

  const tabItems = [
    {
      key: 'overview',
      label: 'Tổng quan',
    },
    {
      key: 'information',
      label: 'Thông tin tài khoản',
    },
    {
      key: 'change-password',
      label: 'Thay đổi mật khẩu',
    },
    {
      key: 'setting-security',
      label: 'Thiết lập bảo mật',
    },
  ];

  const tabData = {
    ['overview']: <Overview dataDetail={user?.account} isAdmin />,
    ['information']: <AccountInformation dataDetail={user?.account} />,
    ['change-password']: <ChangePassword dataDetail={user?.account} />,
    ['setting-security']: <SetupSecurity dataDetail={user?.account} isAdmin />,
  };

  return (
    <Col className="tera-page">
      <HeaderViewListV2 title="THÔNG TIN CỦA TÔI">
        <Col className="page-content-v2 p-4 flex flex-col gap-4">
          <Tabs
            onChange={handleChangeTab}
            items={tabItems}
            activeKey={queryParams?.tab || tabItems[0].key}
            className="mb-0 pt-0 bg-white rounded-t-md"
          />
          {tabData[queryParams?.tab || tabItems[0].key]}
        </Col>
      </HeaderViewListV2>
    </Col>
  );
};

export default observer(MyInformation);
